import React, { useState, useContext } from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { windowSize } from "../../App";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import "./style.css";

export const HubButton = ({

  selection,
  button,
  setSelection,
  lastItemFlag,

}) => {

  const deviceType = useContext(windowSize)

  const placeholder_text = "Loading...";

  const is_this_button_selected = (button.type === selection) ? true : false;

  const [active, setActive] = useState(false);

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
  };

  return (
    <>
      {!is_this_button_selected &&
        <>
          {/* <div className="hub-sections-button-container"> */}
          <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            className={`hub-sections-button-frame hub-sections-button-frame-selection-false ${active ? "box-shadow-hover" : "box-shadow-standard"}`}
            onClick={() => setSelection(button.type)}
          >
            <span className={"span-s hub-sections-button-text"}>
              {button ? button.button : placeholder_text}
            </span>
            {/* <i class="arrow right"></i> */}
            {/* </div> */}
            {/* <div className="hub-sections-button-location-ident-container hub-sections-button-location-ident-hide">
              <ArrowUpwardIcon />
              <span>You are Here</span>
            </div> */}
          </div>
        </>
      }

      {is_this_button_selected &&
        // <div className="hub-sections-button-container">
        <>
          <div className="hub-button-selected-container">

            <div className={"hub-sections-button-frame box-shadow-standard hub-sections-button-frame-selection-true"}>
              <span className={"span-s hub-sections-button-text"}>
                {button ? button.button : placeholder_text}
              </span>
            </div>
            {deviceType === "desktop" &&
              <div className={`page-content-horizontal-divider-full-tall`}></div>
            }
          </div>
        </>
        // </div>
      }
      {/* {(!lastItemFlag && deviceType === "desktop") && <ArrowCircleRightIcon />} */}
      {(!lastItemFlag && deviceType !== "desktop") && <ArrowCircleDownIcon />}
    </>
  );
};
