import React, { useContext } from "react";
import { ProjectStatusBar } from "../ProjectStatusBar/ProjectStatusBar";
import { ProjectsListFrame80 } from "../ProjectsListFrame80/ProjectsListFrame80";
import { CostList } from "../CostList/CostList";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import "./style.css";

export const HubProjectBar = ({
  key,
  project,
}) => {

  // Grab the device type
  const deviceType = useContext(windowSize);
  console.log(deviceType);

  // Grab the user navigation from context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)



  const risks_list = project.associated_climate_risks_list;
  const project_image_file = project.image

  const project_image_url = CreateCloudfrontUrl(project_image_file)

  return (
    <div className={`project-bar-view-frame ${deviceType === "mobile" ? "project-bar-view-frame-mobile" : ""}`} onClick={() => {
      setCardSelection(project.id)
      setCardType("project")
      setCardToggleStatus(true)
      console.log('inside')
    }}
    >

      <ProjectStatusBar />

      {/* <div className={"project-bar-view-image-container"}>

        <img className={"project-bar-view-image"} src={project_image_url} />

      </div> */}

      {deviceType === "mobile" &&
        <>
          <div className={"project-bar-data-frame-mobile"}>

            <div className={"project-bar-view-image-container-mobile"} style={{
              backgroundImage: `url(${project_image_url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              height: '15vh',
            }}>
              <div className={"project-bar-view-image-overlay"}>

                <h3 className={"project-bar-view-header-title project-bar-view-header-title-mobile"}>{project.name.length > 0 ? project.name : "Loading..."}</h3>
              </div>

              {/* <img className={"project-bar-view-image"} src={project_image_url} /> */}

            </div>
            <div className={"project-bar-view-header-risks-container"}>

              {risks_list.map(risk => {
                return (<ProjectsListFrame80 risk={risk} />)
              })}

            </div>
            <CostList
              project={project}
            />
            {/* <div className={"project-bar-view-mobile-info-container"}>
              <div className={"project-bar-view-header-container"}>



              </div>

            </div> */}
          </div>
        </>
      }
      {deviceType !== "mobile" &&
        <>
          <div className={"project-bar-view-image-container"}>

            <img className={"project-bar-view-image"} src={project_image_url} />

          </div>
          <div className={"project-bar-view-header-container"}>

            <p className={"project-bar-view-header-title"}>{project.name.length > 0 ? project.name : "Loading..."}</p>

            <div className={"project-bar-view-header-risks-container"}>

              {risks_list.map(risk => {
                return (<ProjectsListFrame80 risk={risk} />)
              })}

            </div>
          </div>
          <CostList
            project={project}
          />
        </>
      }
    </div>
  );
};
