import React, { useState } from "react";
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import { PlayWithDataButton } from "../PlayWithDataButton";
import { SendToStakeholder } from "../../forms/SendToStakeholder";
import { StartButton } from "../StartButton/StartButton";
import "./style.css";

export const ChecklistItem = ({ deviceType, toggledItemData, cta, headline, text, selection, category, handleClick }) => {

  // Get the data for the toggled item (only used on tablet / mobile)
  const toggled_item_body_header = toggledItemData.body_header || null;
  const toggled_item_body_text = toggledItemData.text || null;

  const [taskCompletionStatus, setTaskCompletionStatus] = useState(false);

  const handleToggle = () => {
    setTaskCompletionStatus(!taskCompletionStatus)
  }

  const handle_category_choice = () => {
    handleClick(category)
  }

  return (
    <>
      {deviceType === "desktop" &&
        <div onClick={(e) => handle_category_choice()} className={`checklist-item-frame ${selection === category && "checklist-item-frame-selected"}`}>
          <div onClick={handleToggle} className={`checklist-item-icon-container-${taskCompletionStatus}`}>
            {taskCompletionStatus && <CheckBoxSharpIcon />}
            {!taskCompletionStatus && <CheckBoxOutlineBlankSharpIcon />}
          </div>
          <div className="checklist-item-text-container">
            <span className={`span-m checklist-item-headline ${taskCompletionStatus && "text-strikethrough"}`}>{headline}</span>
          </div>
        </div>
      }
      {deviceType !== "desktop" &&
        // <div onClick={(e) => handle_category_choice()} className={`checklist-item-frame-mobile ${selection === category && "checklist-item-frame-selected"}`}>
        <div onClick={(e) => handle_category_choice()} className={`checklist-item-frame-mobile ${selection === category && "checklist-item-frame-mobile-selected"}`}>
          <div className={`checklist-item-header-frame-mobile  ${selection === category && "checklist-item-header-frame-mobile-selected"}`}>

            <div onClick={handleToggle} className={`checklist-item-icon-container-${taskCompletionStatus}`}>
              {taskCompletionStatus && <CheckBoxSharpIcon />}
              {!taskCompletionStatus && <CheckBoxOutlineBlankSharpIcon />}
            </div>
            <div className="checklist-item-text-container">
              <span className={`span-m checklist-item-headline ${taskCompletionStatus && "text-strikethrough"}`}>{headline}</span>
            </div>
          </div>
          {selection === category &&
            <>
              <div className="page-content-horizontal-divider-full-orange" />
              <div className={"now-what-guidance-cta-container-mobile"}>
                <span className={"span-xl text-align-center text-wrap-balance now-what-guidance-checklist-item-header"}>{toggled_item_body_header}</span>
                <p className={"text-align-center text-wrap-balance now-what-guidance-checklist-item-text"} >{toggled_item_body_text}</p>
                {cta}
              </div>
            </>
          }
        </div>
      }
    </>
  );
};
