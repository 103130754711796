import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { AnalysisDetails, UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { HubSavedProjectsCollection } from "../HubSavedProjectsCollection";
import { UserStatus } from "../../services/UserMgt";
import { StaticText } from "../../services/StaticText";
import { StartButton } from "../../components/StartButton/StartButton";


export const HubSavedProjects = () => {

  // Set up navigate for if user is not signed in and tries to save project
  const navigate = useNavigate();

   // Grab the navigation context
   const { setCardToggleStatus, setCardType, setUserAuthReportFlow, setUserAuthReturnUser } = useContext(UserExperienceNavigator)

  // Signin button navigation if user is not designed
  const signInRedirect = "/sign-in";

  // Get the device type
  const deviceType = useContext(windowSize);

  // Collect the analysis and house ID
  const analysis = useContext(AnalysisDetails);
  const address_id = analysis ? analysis.address_id : null;

  // Collect the projects from the analsysis
  const projects = analysis ? analysis.projects : null;

  // Get the user id
  const { userId, userSavedHomes, setUserSavedHomes } = useContext(UserStatus);

  // Create the saved state for this saved home
  const [thisHomeSavedProjects, setThisHomeSavedProjects] = useState([]);

  useEffect(() => {

    if (userSavedHomes) {
      const data_for_this_home = userSavedHomes.filter(home => home.address_id === address_id)
      const this_home_saved_projects = data_for_this_home.length > 0 ? data_for_this_home[0].saved_projects : [];
      const user_saved_projects_for_this_home = this_home_saved_projects.length > 0 ? projects.filter(project => this_home_saved_projects.includes(project.id)) : [];
      setThisHomeSavedProjects(user_saved_projects_for_this_home)
    } else {
      setThisHomeSavedProjects([])
    }

  }, [userSavedHomes]);

  // OLD WAY, WITHOUT STATE FOR GETTING THE SAVED HOME
  // const this_home_saved = userSavedHomes ? userSavedHomes.filter(home => home.address_id === address_id) : [];

  // If the user has saved this home then filter it out from the saved homes
  // const this_home_saved_projects = this_home_saved.length > 0 ? this_home_saved[0].saved_projects : [];
  // const this_home_saved_projects = thisHomeSaved.length > 0 ? thisHomeSaved[0].saved_projects : [];

  // If the user has saved this home and if there are saved projects then pull those out of the master projects array
  // const user_saved_projects_for_this_home = this_home_saved_projects.length > 0 ? projects.filter(project => this_home_saved_projects.includes(project.id)) : [];

  // Get the static text 
  const static_text = StaticText()
  const saved_projects_text = static_text.hub.saved_projects

  // Get the specific text for the various sections
  const title = saved_projects_text.title
  const description = saved_projects_text.description
  const not_signed_in_cta = saved_projects_text.not_signed_in_cta
  const signed_in_no_saved_projects_cta = saved_projects_text.signed_in_no_saved_projects_cta
  const saved_homes_comparison_preview_image = saved_projects_text.saved_homes_comparison_preview_image

  // Create the cloudfront url for the image
  const saved_homes_comparison_preview_image_url = CreateCloudfrontUrl(saved_homes_comparison_preview_image)

  const handleSignInButtonClick = () => {
    // navigate(signInRedirect);
    setCardType("sign_in")
    setUserAuthReportFlow(true)
    setUserAuthReturnUser(false)
    setCardToggleStatus(true)
  }

  return (
    <>
      <div className="hub-selection-frame">

        <h2 className={"hub-selection-title-text"}>
          {title}
        </h2>
        {(userId) &&
          <p className={"text-wrap-balance text-align-center"}>{description}</p>
        }

        {(thisHomeSavedProjects.length > 0 && userId) &&
          <HubSavedProjectsCollection projects={thisHomeSavedProjects} />
        }

        {(!userId) &&
          <>
            <p className={"text-align-center"}>{not_signed_in_cta}</p>
            <img className={"box-shadow-standard"} src={saved_homes_comparison_preview_image_url} />
            <div className="clickable" onClick={handleSignInButtonClick}>
              <StartButton text="Sign In" shadow_flag={true} />
            </div>
          </>
        }

        {(userId && thisHomeSavedProjects.length === 0) &&
          <p className={"text-align-center"}>{signed_in_no_saved_projects_cta}</p>
        }

      </div>
    </>
  );

};
