import axios from "axios";

export function fetchAddressDetails(request_param) {
  
  // return axios.post('https://1nl7eg7oui.execute-api.us-east-2.amazonaws.com/test/addresses', request_param)
  
  return axios.post('/api/addresses', request_param)
  // .then(response => response.data.address_report.output)
  // During testing
  // .then(response => analysis)
  
  // Production
  .then(response => response.data)
  .catch(error => {
        console.error(error);
        throw error; // throw the error again after catching and logging it
      });
  }
  