import React, { useContext } from "react";
import { Frame130 } from "../../components/Frame130";
import { IconClimate } from "../../components/IconClimate";
import { RiskLabel } from "../../components/RiskLabel";
import { HubProjectsCard } from "../../modules/HubProjectsCard";
import { CompileClimateByHouseArea } from "../../services/CompileClimateByHouseArea";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { ModalClimateHouseTitleSection } from "../../components/ModalClimateHouseTitleSection";
import { ProjectCardAccordian } from "../../modules/ProjectCardAccordian";
import { SkusResources } from "../../screens/TabletDesktop/TabletDesktop";
import formatDiscounts from "../../services/formatDiscounts";
import { windowSize } from "../../App";
import "./style.css";

export const ModalHouse = ({
  selection,

}) => {

  // Get the device
  const deviceType = useContext(windowSize)

  // Get the analysis
  const analysis = useContext(AnalysisDetails);

  // Get the sku resources from context
  const skus_resources = useContext(SkusResources);

  // Get the discounts, company discounts, and companies from the sku resources
  const companies = skus_resources.companies
  const discounts = skus_resources.discounts
  const company_discounts = skus_resources.company_discounts

  // Get the house part based on the selection
  const house_categories = analysis.house_category_risks
  const selected_house_category = house_categories.filter((category) => category.name === selection)[0];

  // Get the discounts from the selected house category
  const selected_house_category_discounts = selected_house_category.associated_discounts;

  // Filter the master discounts to just the ones for this project
  const project_discounts = discounts.filter(discount => selected_house_category_discounts.includes(discount.id))

  // Combine and format the discounts with company discounts and company info
  const processed_discounts = formatDiscounts(project_discounts, company_discounts, companies);

  // Pull the discounts and count from the formatted discounts

  const formatted_discounts = processed_discounts.discounts
  const discount_count = processed_discounts.count

  // console.log(formatted_discounts)

  // Get the climate risks
  const climate_risks = analysis.climate.climate_scores

  // Get the house part name, icon, image, associated climate risks and value
  const selected_house_category_name = selected_house_category.name
  const selected_house_category_description = selected_house_category.description
  const selected_house_category_image = CreateCloudfrontUrl(selected_house_category.image)
  const selected_house_category_icon = CreateCloudfrontUrl(selected_house_category.icon)
  const selected_house_category_value = selected_house_category.value
  const selected_house_category_climate_risks = CompileClimateByHouseArea(selected_house_category, climate_risks)

  // Get the projects and filter it by the house category being viewed
  const all_projects = analysis.projects
  const house_selected_projects = all_projects.filter(project => project.house_category === selection);
  // Get the associated resources for this part of the house
  const selected_house_category_resources_ids = selected_house_category.associated_resources

  // Pull out the resources
  const resources = skus_resources.resources
  const selected_house_category_resources = resources.filter(resource => selected_house_category_resources_ids.includes(resource.id))

  // Create the accordian headers
  const projects_accordian_header = { headline: "Recommended Projects", description: "Total", value: house_selected_projects.length }
  const resources_accordian_header = { headline: "DIY Guides and Other Resources", description: null, value: null }
  const related_climate_risks_accordian_header = { headline: `Climate Risks Affecting ${selected_house_category_name}`, description: null, value: null }
  const discounts_accordian_header = { headline: `${house_selected_projects.length > 0 ? "Other ": ""}Discounts and Offers We've Identified`, description: "Total Available", value: discount_count }

  return (

    <div className={"modal-content-frame"}>
      <ModalClimateHouseTitleSection
        name={selected_house_category_name}
        image={selected_house_category_image}
        icon={selected_house_category_icon}
        value={selected_house_category_value}
        type="Risk Analysis"
      />
      <p className={`modal-climate-blurb ${deviceType === "mobile" && "text-align-center"}`}>
        {selected_house_category_description}
      </p>

      <div className="modal-project-accordians-container">
        <ProjectCardAccordian
          accordian_header={related_climate_risks_accordian_header}
          related={selected_house_category_climate_risks}
          type="related_climate"
          related_type="climate"
          // related={selected_house_category_climate_risks}
          default_toggle_status={true}
        />
        {house_selected_projects.length > 0 &&
          <ProjectCardAccordian
            accordian_header={projects_accordian_header}
            project={house_selected_projects}
            type="projects"
            default_toggle_status={true}
          />
        }
        {discount_count > 0 &&
          <ProjectCardAccordian
            accordian_header={discounts_accordian_header}
            // table_header={materials_table_header}
            discounts={formatted_discounts}
            type="discounts"
            default_toggle_status={true}
          />
        }
        {selected_house_category_resources.length > 0 &&
          <ProjectCardAccordian
            accordian_header={resources_accordian_header}
            resources={selected_house_category_resources}
            type="resources"
            default_toggle_status={true}
          />
        }
      </div>
    </div>

  );

};