import React from "react";
import "./style.css";

export const SnackBarAlert = ({ message }) => {
  return (
    <div className="snack-bar-container">

      <span className={"span-s snack-bar-text"}>{message}</span>
      
    </div>
  );
};
