// export function GetFilteredResources(data, resourceTypeFilter, riskTypeFilter, houseCategoryFilter) {
//   let filteredArray = data;

//   if (resourceTypeFilter !== null) {
//     filteredArray = filteredArray.filter(item => item.category === resourceTypeFilter);
//   }

//   if (riskTypeFilter !== null) {
//     filteredArray = filteredArray.filter(item => item.associated_climate_risks.includes(riskTypeFilter));
//   }

//   if (houseCategoryFilter !== null) {
//     filteredArray = filteredArray.filter(item => item.associated_house_categories.includes(houseCategoryFilter));
//   }

//   const options = {
//     category: [...new Set(filteredArray.map(item => item.category))].sort(),
//     risk: [...new Set(filteredArray.flatMap(item => item.associated_climate_risks))].sort(),
//     house: [...new Set(filteredArray.flatMap(item => item.associated_house_categories))].sort(),
//   };

//   return { resources: filteredArray, options };
// }

export function GetFilteredResources(data, resourceTypeFilter, riskTypeFilter, houseCategoryFilter) {
  let filteredArray = data;

  if (resourceTypeFilter.length > 0) {
    filteredArray = filteredArray.filter(item => resourceTypeFilter.includes(item.category));
  }

  if (riskTypeFilter.length > 0) {
    filteredArray = filteredArray.filter(item =>
      item.associated_climate_risks.some(risk => riskTypeFilter.includes(risk))
    );
  }

  if (houseCategoryFilter.length > 0) {
    filteredArray = filteredArray.filter(item =>
      item.associated_house_categories.some(category => houseCategoryFilter.includes(category))
    );
  }

  const options = {
    category: [...new Set(filteredArray.map(item => item.category))].sort(),
    risk: [...new Set(filteredArray.flatMap(item => item.associated_climate_risks))].sort(),
    house: [...new Set(filteredArray.flatMap(item => item.associated_house_categories))].sort(),
  };

  return { resources: filteredArray, options };
}
