import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const style = {
  // height: '160px',
  // overflow: 'hidden',
  // pointerEvents: 'none',
  // background: 'none',
  // borderRadius: '5px',
}

export const YoutubeEmbed = ({ embedId,status }) => (
  <div className={`video-responsive ${status}`} style={style}>
    <iframe
      height= '100%'
      width = '100%'
      border-radius= '5px'
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

// export default YoutubeEmbed;