import axios from 'axios';

// export function CheckGoogleMapsStreetviewAvailability(lat,long) {
export const CheckGoogleMapsStreetviewAvailability = async (lat, long) => {
    const url = "https://maps.googleapis.com/maps/api/streetview/metadata?size=640x480&location=" + lat + "," + long + "&fov=120&pitch=0&key=AIzaSyC8JrxYNCI1AY01RU4qqKUSjNor8Dj1s_E"

    try {
        const response = await axios.get(url);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;  // handle error appropriately
    }
    // console.log(url)
    // return url
};