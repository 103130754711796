import React, { useState, useContext } from "react";
import { Scrollama, Step } from 'react-scrollama';
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { windowSize } from "../../App";
import "./style.css";

export const Scrollytelling = ({ inputData }) => {

  const deviceType = useContext(windowSize)
  console.log(deviceType)

  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [currentStepPicture, setCurrentStepPicture] = useState(CreateCloudfrontUrl(inputData[0].img));

  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data.index);
    setCurrentStepPicture(CreateCloudfrontUrl(data.img));
  };

  console.log(currentStepPicture);

  return (

    <>
      {deviceType !== 'good enough for now' &&
        <div className="scrollytelling-container">
          <div className="scrollytelling-text-container">
            <Scrollama offset={0.7} onStepEnter={onStepEnter}>
              {inputData.map(tour_step => (
                <Step data={tour_step} key={tour_step.index}>
                  <div
                    style={{
                      margin: '0',
                      // border: '1px solid gray',  
                      opacity: currentStepIndex === tour_step.index ? 1 : 0.2,
                    }}
                  >
                    <p className={"scrollytelling-text"}>{tour_step.description}</p>
                  </div>
                </Step>
              ))}
            </Scrollama>
          </div>
          <div className={"scrolly-telling-image-container"}>
            <img className={'scrollytelling-image drop-shadow-standard'} src={currentStepPicture} />
          </div>
        </div>
      }
      {deviceType === 'for later' &&
        <div className="scrollytelling-container-mobile" style={{backgroundImage: `url(${currentStepPicture})`}}>
          {/* <img className={'scrollytelling-image-mobile box-shadow-standard'} src={currentStepPicture} /> */}
          {/* <div className={"scrolly-telling-image-container-mobile"}> */}
            <div className="scrollytelling-text-container-mobile">
              <p>Hi</p>
              <Scrollama offset={0.9} onStepEnter={onStepEnter}>
                {inputData.map(tour_step => (
                  <Step data={tour_step} key={tour_step.index}>
                    <div
                      style={{
                        margin: '0',
                        // border: '1px solid gray',  
                        opacity: currentStepIndex === tour_step.index ? 1 : 0.2,
                      }}
                    >
                      <p className={"scrollytelling-text-mobile"}>{tour_step.description}</p>
                    </div>
                  </Step>
                ))}
              </Scrollama>
            </div>
          {/* </div> */}

        </div>
      }
    </>

  );
};
