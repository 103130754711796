import React, { useState } from "react";
import { HubAffordability } from "../HubAffordability";
import { HubValue } from "../HubValue";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const FinancialImpact = ({

    // financialToggle,
    // setFinancialToggle,

}) => {

    const [financialToggle, setFinancialToggle] = useState("affordability");

    // Get static text
    const static_text = StaticText()
    const financial_impact_text = static_text.hub.financial_impact
    const section_title = financial_impact_text.title
    const affordability_toggle_header = financial_impact_text.affordability_toggle_header
    const affordability_toggle_header_context = financial_impact_text.affordability_toggle_header_context
    const ltv_toggle_header = financial_impact_text.ltv_toggle_header
    const ltv_toggle_header_context = financial_impact_text.ltv_toggle_header_context
    
    return (

        <div className={"hub-selection-frame"}>

            <div className={"hub-selection-title-container"}>
                <h2 className={"hub-selection-title-text"}>
                    {section_title}
                </h2>
            </div>

            <div className={'financial-impact-header-frame'}>
                <div
                    className={`financial-impact-header-general box-shadow-standard ${financialToggle === "affordability" ? "financial-impact-header-active" : ""}`}
                    onClick={() => { setFinancialToggle("affordability") }}
                >
                    <span className="span-l financial-impact-toggle-header">{affordability_toggle_header}</span>
                    <span className={'span-m financial-impact-header-headline'}>{affordability_toggle_header_context}</span>
                </div>
                <div
                    className={`financial-impact-header-general box-shadow-standard ${financialToggle === "value" ? "financial-impact-header-active" : ""}`}
                    onClick={() => { setFinancialToggle("value") }}
                >
                    <span className="span-l financial-impact-toggle-header">{ltv_toggle_header}</span>
                    <span className={'span-m financial-impact-header-headline'}>{ltv_toggle_header_context}</span>

                </div>

            </div>

            {financialToggle === "affordability" ? <HubAffordability /> : <HubValue />}

        </div>

    );

};
