export function CacheResources(input) {
    // Get the current time
    const currentTime = new Date();

    // Add 30 minutes (30 minutes * 60 seconds * 1000 milliseconds)
    const expirationTime = new Date(currentTime.getTime() + 30 * 60 * 1000);

    const object_for_cache = { value: input, timestamp: expirationTime.getTime() }; // Store timestamp as a number

    localStorage.setItem("resources", JSON.stringify(object_for_cache));

    return null;
}

export function GetResourcesFromCache() {
    // Get the current time
    const currentTime = new Date();

    // Retrieve the cached data and its expiration timestamp
    const cachedData = JSON.parse(localStorage.getItem("resources")) || null;
    console.log(cachedData);
    const cachedExpirationTime = cachedData ? new Date(cachedData.timestamp) : null;

    if (cachedData) {
        if (currentTime > cachedExpirationTime) {
            localStorage.removeItem("resources");
            console.log("Removed from cache!")
            return null;
        } else {
            return cachedData.value
        }
    } else {
        return null;
    }

    // // Compare the current time with the cached expiration time
    // if (cachedExpirationTime && currentTime > cachedExpirationTime) {
    //     // Data has expired (more than 30 minutes have passed)
    //     // Perform necessary actions, such as fetching new data or updating the cache
    //     localStorage.removeItem("resources");
    //     console.log("Removed from cache!")
    //     return null;
    // } else {
    //     // Data is still valid
    //     // You can use the cached data
    //     console.log("Found in cache!")
    //     console.log("Current time: ", currentTime)
    //     console.log("Expired time: ", cachedExpirationTime)
    //     console.log(currentTime - cachedExpirationTime)
    //     return cachedData.value;
    // }

    // return cachedData ? cachedData.value : null;
}
