import React from "react";
import "./style.css";

export const MenuButton = ({

  text

}) => {
  return (

    <div className={"header-menu-button-container"}>

      <span className={"span-l menu-button-menu-item"}>

        {text}

      </span>

    </div>

  );
};
