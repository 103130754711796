import React, { useEffect, useRef, useContext, useState } from "react";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { NowWhatGuidanceIcons } from "../NowWhatGuidanceIcons/NowWhatGuidanceIcons";
import { NowWhatGuidanceBuyer } from "../NowWhatGuidanceBuyer";
import { StartButton } from "../../components/StartButton/StartButton";
import { GetGuidanceText } from "../../services/GetGuidanceText";
import { UserStatus } from "../../services/UserMgt";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const NowWhatGuidance = () => {

  // Figure out if the user is signed in or not signed in 
  const { userId } = useContext(UserStatus);
  const user_status = userId ? "Signed In User" : "Not Signed In User"

  // Set the guidance path
  const [guidancePath, setGuidancePath] = useState(null);

  // Get the static text
  const static_text = StaticText()

  // Log the change in guidance path
  useEffect(() => {
    if (guidancePath) {
      const guidance_path_capitalized = guidancePath.charAt(0).toUpperCase() + guidancePath.slice(1)
      RecordEvent("What Next Guidance", `${guidance_path_capitalized} Guidance Opened`, user_status)
    }
  }, [guidancePath]);

  // Get the section title
  const section_title = static_text.hub.next_steps.title

  // Get the guidance text / data
  const buyer_inputs = static_text.hub.next_steps.buyer
  const owner_inputs = static_text.hub.next_steps.owner

  const guidance_text_1 = static_text.hub.next_steps.intro_paragraph
  const guidance_text_2 = "Please choose which guidance you’d like to go through."

  // Text and checklist for the buyer
  const buying_this_home_img = buyer_inputs.icon_image
  const buying_this_home_title = buyer_inputs.title
  const buying_this_home_text = buyer_inputs.icon_text
  const buying_this_home_type = buyer_inputs.guidance_type
  // const buyer_persona = "buyer"
  const buyer_solving_for_description = buyer_inputs.guidance_description
  const buyer_checklist = buyer_inputs.checklist

  // Text and checklist for the owner
  const own_this_home_img = owner_inputs.icon_image
  const own_this_home_title = owner_inputs.title
  const own_this_home_text = owner_inputs.icon_text
  const own_this_home_type = owner_inputs.guidance_type
  // const owner_persona = "owner"
  const owner_solving_for_description = owner_inputs.guidance_description
  const owner_checklist = owner_inputs.checklist


  // Old but don't want to delete in case
  const buyer_signed_up_quoll_assistance = buyer_inputs.buyer_signed_up_quoll_assistance
  const buyer_not_signed_up_quoll_assistance = buyer_inputs.buyer_not_signed_up_quoll_assistance
  const owner_signed_up_quoll_assistance = owner_inputs.owner_signed_up_quoll_assistance
  const owner_not_signed_up_quoll_assistance = owner_inputs.owner_not_signed_up_quoll_assistance

  const handleGuidanceClick = (type) => {
    if (type) {

      if (type === guidancePath) {
        setGuidancePath(null)
      } else {
        setGuidancePath(type)
      }
    } else {
      setGuidancePath(null)
    }
  }


  return (
    <>
      <div className="hub-selection-frame">

        <h2 className={"hub-selection-title-text"}>
          {section_title}
        </h2>
        <div className="what-now-header-frame">

          {!guidancePath &&
            <>
              {/* <h3 className={"hub-financial-subsection-header"}>Ready to Start Taking Action?</h3> */}
              <p className={"text-wrap-balance text-align-center"}>{guidance_text_1}</p>
            </>
          }
          {guidancePath &&
            <>
              {/* <div className="what-now-guidance-path-button">

              </div> */}
              <h3 className={"hub-financial-subsection-header"}>{guidancePath === 'buyer' ? buying_this_home_title : own_this_home_title}</h3>
              <StartButton
                mini_flag={true}
                handle_what_to_do={handleGuidanceClick}
                shadow_flag={true}
                text={guidancePath === 'buyer' ? "Change to Owner View" : "Change to Buyer View"}
                what_to_do_flag={true}
              />
            </>
          }
        </div>
        {!guidancePath &&
          <>
            <div className={"modal-selector-boxes-grid"}>
              <NowWhatGuidanceIcons
                type={buying_this_home_type}
                text={buying_this_home_text}
                image={buying_this_home_img}
                guidancePath={guidancePath}
                handleClick={handleGuidanceClick}
              />
              <NowWhatGuidanceIcons
                type={own_this_home_type}
                text={own_this_home_text}
                image={own_this_home_img}
                guidancePath={guidancePath}
                handleClick={handleGuidanceClick}
              />
            </div>
            <span className={"span-l modal-user-form-selection-reminder-text"}>{guidance_text_2}</span>
          </>
        }
        {guidancePath === 'buyer' &&
          <NowWhatGuidanceBuyer
            first_category={"update_data"}
            solving_for={buyer_solving_for_description}
            checklist={buyer_checklist}
            persona={guidancePath}
            signed_up_text={buyer_signed_up_quoll_assistance}
            not_signed_up_text={buyer_not_signed_up_quoll_assistance}
          />
        }
        {guidancePath === 'owner' &&
          <NowWhatGuidanceBuyer
            first_category={"update_data"}
            solving_for={owner_solving_for_description}
            checklist={owner_checklist}
            persona={guidancePath}
            signed_up_text={owner_signed_up_quoll_assistance}
            not_signed_up_text={owner_not_signed_up_quoll_assistance}
          />
        }
      </div>
    </>
  );
};
