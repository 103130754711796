import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, EmailShareButton, EmailIcon } from 'react-share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
// import { useSnackbar } from "@mui/base/useSnackbar";
import Snackbar from '@mui/material/Snackbar';
import { SnackBarAlert } from "../../components/SnackBarAlert";
import "./style.css";

export const SocialShare = ({ description, handleClickaway }) => {

  const url = window.location.href;
  const title = "Check Out the Climate Risk Analysis On This Home"
  const summary = "Search an address and instantly see each home's climate risk profile, what that means for bills and long-term value, and recommended projects to protect yourself. "
  const hashtags = ["ClimateRisk", "Adaptation", "HomeResiliency", "HomeHardening", "ProtectYourWallet"]

  const icon_style = {
    fill: "var(--page_background)"
  }

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // const { getRootProps } = useSnackbar({
  //   onClose: handleClose,
  //   open,
  //   autoHideDuration: 2000
  // });

  const handleOpen = () => {
    setOpen(true);
  };

  const saveToClipboard = () => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText("Text which you want to copy");
    } else {
      document.execCommand("copy", true, "Text which you want to copy");
    }
    navigator.clipboard.writeText(url);
    handleOpen();
  }

  return (
    <ClickAwayListener onClickAway={handleClickaway}>

      <div className={"social-share-frame"}>
        <ContentCopyIcon className={"social-share-icon-formatting"} onClick={saveToClipboard} />
        {open &&
          <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
            <div className={"snack-bar-container-universal-placement"}>
              <SnackBarAlert
                message={"URL Saved to Clipboard"}
              />
            </div>
          </Snackbar>
        }
        <EmailShareButton url={url} quote={url} subject={title} style={icon_style}>
          <EmailIcon className={"social-share-icon-formatting"} bgStyle={icon_style} size={40} round />
        </EmailShareButton>
        <TwitterShareButton
          url={url}
          title={title}
          description={title}
          hashtags={hashtags}
        >
          <TwitterIcon className={"social-share-icon-formatting"} bgStyle={icon_style} size={40} round />
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          title={title}
          summary={summary}
        >
          <LinkedinIcon bgStyle={icon_style} size={40} round />
        </LinkedinShareButton>
      </div>
    </ClickAwayListener>

  );
};
