import React from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";


export const IconClimate = ({ 
  style={
    backgroundColor: "unset",
    height: "80px",
    left: "unset",
    top: "unset",
    width: "80px",
  }, 
  iconsClimateStyle={
    height: "66px",
    minWidth: "66px",
  }, 
  icon,
}) => {

  url = CreateCloudfrontUrl(icon)

  return (
    <div
      className={`icon-climate-property-high-risk`}
      style={style}
    >
      <img
        className={"icon-climate-icons-climate"}
        style={iconsClimateStyle}
        src={url}
      />
    </div>
  );
};
