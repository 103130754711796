import React, { useEffect, useRef, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { UserStatus } from "../../services/UserMgt";
import { windowSize } from "../../App";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { ChecklistItem } from "../../components/ChecklistItem";
import { StartButton } from "../../components/StartButton/StartButton";
import "./style.css";

export const NowWhatGuidanceQuollAssistance = ({ persona, signed_up_text, not_signed_up_text }) => {

  // Set up navigate for if user is not signed in and tries to save project
  const navigate = useNavigate();

  // Redirect navigation if user is not designed
  const signInRedirect = "/sign-in";
  const signUpRedirect = "/sign-up";
  const myDashboard = "/my-dashboard";

  // Get the userId if any
  const { userId, sessionId } = useContext(UserStatus)

  // Determine if this user is signed in / up
  const user_status = userId ? true : false

  // Grab the analysis
  const analysis = useContext(AnalysisDetails)

  // Get the address
  const address = analysis.address_details.full_address.split(",")[0]

  const sub_section_1_header = "Want Quoll To Design a Personalized Action Plan For You?"

  const handleButtonClick = (navigate_to) => {
    navigate(navigate_to)
  };

  return (
    <div className="what-now-quoll-guidance-frame">
      <span className={"span-l text-align-center text-wrap-balance now-what-guidance-subsection-header"}>{sub_section_1_header}</span>
      {/* {persona == 'buyer' &&
        <span className={"span-m text-align-center"}>{user_status ? description_buyer_signed_up : description_buyer_not_signed_up}</span>
      }
      {persona == 'owner' &&
        <span className={"span-m text-align-center"}>{user_status ? description_owner_signed_up : description_owner_not_signed_up}</span>
      } */}
      <span className={"span-m text-align-center"}>{user_status ? signed_up_text : not_signed_up_text}</span>
      <div className="what-now-account-buttons-frame">
        {!user_status &&
          <div onClick={() => handleButtonClick(signInRedirect)} className={"what-no-account-button-container"}>
            <StartButton shadow_flag={true} text={"Create Account"} />
          </div>
        }
        {user_status &&
          <div onClick={() => handleButtonClick(myDashboard)} className={"what-no-account-button-container"}>
            <StartButton shadow_flag={true} text={"Go to My Dashboard"} />
          </div>
        }
      </div>
    </div>
  );
};
