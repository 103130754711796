// import { Frame130 } from "../../components/Frame130";
// import { IconClimate } from "../../components/IconClimate";
// import { RiskLabel } from "../../components/RiskLabel";
// import { RiskStat } from "../../components/RiskStat";
// import { ProjectCardResources } from "../../modules/ProjectCardResources/ProjectCardResources";
import React, { useContext } from "react";
import { CountHouseAreas } from "../../services/CountHouseAreas"
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { SkusResources } from "../../screens/TabletDesktop/TabletDesktop";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { ProjectCardAccordian } from "../../modules/ProjectCardAccordian";
import { ModalClimateHouseTitleSection } from "../../components/ModalClimateHouseTitleSection";
import { windowSize } from "../../App";
import "./style.css";

export const ModalClimate = ({

  selection,

}) => {

  // Get the device
  const deviceType = useContext(windowSize)

  // Get analysis from context
  const analysis = useContext(AnalysisDetails);

  // Get the climate risks from the analysis
  const climate_risks_list = analysis.climate.climate_scores;

  // Get the icon
  const selected_climate_risk = climate_risks_list.filter(risk => risk.name == selection)[0]
  const selected_climate_risk_icon_filename = selected_climate_risk.icon_black
  const selected_climate_rick_icon_url = selected_climate_risk_icon_filename
  const selected_climate_risk_image = selected_climate_risk.image
  // console.log(selected_climate_risk);

  // Get the icon URL
  const selected_risk_icon_cloudfront_url = CreateCloudfrontUrl(selected_climate_rick_icon_url)

  // Get the image URL
  const selected_climate_risk_image_url = CreateCloudfrontUrl(selected_climate_risk_image)

  // Get the projects from the analysis and figure out the house areas
  const projects_list = analysis.projects

  // Get the house areas
  const house_categories = analysis.house_category_risks

  // Get the house areas from the projects and the risk selection
  // const house_categories_for_this_risk = CountHouseAreas(projects_list, selected_climate_risk.name);
  const house_categories_for_this_risk = CountHouseAreas(selected_climate_risk, house_categories, projects_list);
  // console.log(house_categories_for_this_risk);

  // Get the resources from context
  const skus_resources = useContext(SkusResources);
  const resources = skus_resources.resources
  const selected_risk_resources = resources.filter(resource => resource.associated_climate_risks.includes(selection) && resource.category === 'Being Prepared')

  // Create the accordian headers
  const facts_accordian_header = { headline: "Key Datapoints for This Home", description: null, value: null }
  const resources_accordian_header = { headline: "DIY Guides and Other Resources", description: null, value: null }
  const related_house_parts_accordian_header = { headline: `Areas of Home Affected By ${selected_climate_risk.name}`, description: null, value: null }

  return (

    <div className={"modal-content-frame"}>
      <ModalClimateHouseTitleSection
        name={selected_climate_risk.name}
        image={selected_climate_risk_image_url}
        icon={selected_risk_icon_cloudfront_url}
        value={selected_climate_risk.value}
        type="Risk Analysis"
      />

      <p className={`modal-climate-blurb ${deviceType === "mobile" && "text-align-center"}`}>
        {selected_climate_risk.blurb}
      </p>
      <div className="modal-project-accordians-container">

        <ProjectCardAccordian
          accordian_header={facts_accordian_header}
          facts={selected_climate_risk.stats}
          type="facts"
          default_toggle_status={true}
        />
        <ProjectCardAccordian
          accordian_header={related_house_parts_accordian_header}
          related={house_categories_for_this_risk}
          type="related_house"
          related_type="house"
          default_toggle_status={true}
        />
        {selected_risk_resources.length > 0 &&
          <ProjectCardAccordian
            accordian_header={resources_accordian_header}
            resources={selected_risk_resources}
            type="resources"
            default_toggle_status={true}
          />
        }
      </div>
    </div>
  );

};