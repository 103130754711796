import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { formatDollarAmount } from "../../services/FormatDollarAmounts";
import { windowSize } from "../../App";
// import '@mui/x-data-grid/dist/index.css';
import "./style.css";

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

export const ProjectCardAccordianTable = ({
  sku_list,
  data,
  table_header,
}) => {

  const deviceType = useContext(windowSize)
  // console.log(deviceType)

  if (deviceType === "mobile") {
    vendor_column_width = 70
    description_column_flex = .35
    cost_column_width = 70
    link_column_width = 70
  } else if (deviceType === "tablet") {
    vendor_column_width = 70
    description_column_flex = .6
    cost_column_width = 70
    link_column_width = 70
  } else {
    vendor_column_width = 170
    description_column_flex = 1
    cost_column_width = 125
    link_column_width = 70
  }

  const explainer_sentence = "Below is a list of the different products that would fit this project. We believe that only you can determine the right price point and styles for anything to do with your home. Our goal here is simply to show you all of the possibilities you are working with and help you get educated on what these options are."

  const columns = [
    { field: 'vendor_icon', headerName: 'Store', renderCell: renderVendorIcon, headerAlign: 'center', align: 'center', width: vendor_column_width, headerClassName: 'super-app-theme--header', disableColumnMenu: true },
    { field: 'description', headerName: 'Product', flex: description_column_flex, headerClassName: 'super-app-theme--header', disableColumnMenu: true },
    { field: 'cost', headerName: 'Cost', renderCell: renderCost, width: cost_column_width, headerClassName: 'super-app-theme--header', disableColumnMenu: true },
    { field: 'url', headerName: 'Link', renderCell: renderLink, width: link_column_width, headerClassName: 'super-app-theme--header', disableColumnMenu: true },
  ];

  const getRowHeight = (params) => {
    if (!params || !params.row || !params.row.description) {
      return 0; // Return 0 as a fallback value if description is missing
    }

    const lineHeight = 40; // Set the desired line height
    const lineCount = Math.ceil(params.row.description.length / 40); // Adjust the line length as needed
    return lineHeight * lineCount;
  };

  function renderVendorIcon(params) {
    const vendorIconUrl = CreateCloudfrontUrl(params.value);
    return <img src={vendorIconUrl} alt="Vendor Icon" style={{ height: '60px', maxHeight: '60px', padding: '5px' }} />;
    // return <div><img src={vendorIconUrl} alt="Vendor Icon" style={{ width: '40px', height: '40px' }} /></div>;
  }

  function renderLink(params) {
    const url = params.value;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div style={{ backgroundColor: 'green', color: 'white', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>
          Go
        </div>
      </a>
    );
  }

  function renderCost(params) {
    const cost = formatDollarAmount(params.value);
    return (
      <span className={"data-table-cost"}>{cost}</span>
    );
  }

  return (
    <div className="project-materials-table-container">
      <p>{explainer_sentence}</p>
      <div style={{ maxHeight: 500, width: '100%' }}>

        <Box
          sx={{
            height: 500,
            width: '100%',
            '& .MuiDataGrid-iconButtonContainer': {
              visibility: 'hidden !important',
              width: '0px !important'
            },
            '& .super-app-theme--header': {
              backgroundColor: 'rgba(224, 224, 224, 0.55)',
              fontSize: '25 px',
            },
          }}
        >

          <DataGrid
            rows={sku_list}
            columns={columns}
            getRowHeight={() => 'auto'}
            slots={{ columnMenu: CustomColumnMenu }}
            hideFooterPagination
            hideFooterSelectedRowCount
            // pageSize={15}
            sx={{
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              backgroundColor: "white",
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
          />

        </Box>
      </div>
      <span className="span-s modal-user-form-description-text">Costs are our estimate of the total for completing the project housewide</span>
    </div>
  );
};
