import React, { useState, useContext, useEffect, useRef } from "react";
import { HubHeader } from "../HubHeader";
import { HubButtonBar } from "../HubButtonBar";
import { ClimateFactors } from "../ClimateFactors";
import { useGlobalState, setGlobalState } from "../../services/globalstate";
import { CardContainer } from "../../cards/CardContiner/CardContainer";
import { FinancialImpact } from "../FinancialImpact";
import { HubProjects } from "../HubProjects";
import { AnalysisDetails, UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { StaticText } from "../../services/StaticText";
import { HubSavedProjects } from "../HubSavedProjects";
import { NowWhatGuidance } from "../../nowwhat/NowWhatGuidance";
// import hubWatermark from "../../assets/hub-watermark.png";
import "./style.css";

export const HubNavigator = React.createContext()

export const OverviewHubV5 = () => {

  // Grab the ability to open the card and set it
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)

  // Create the selection that we use for navigating between the different hub sections
  const [selection, setSelection] = useState("overview");
  
  // Get the hub nav and buttons
  const hubNav = StaticText().hub.hub_navigation;
  const hubButtons = hubNav;

  // Card Toggling
  const [toggle, setToggle] = useState(false);


  // // Get analysis from context
  const analysis = useContext(AnalysisDetails);

  // Handle scroll button click
  const targetRef = useRef(null);

  const handleScrollClick = () => {
    const element = document.getElementById("scroll-target");
    if (element) {

      element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center", offset: "-10px" })
    }

    // targetRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center", offset: "-10px"  })
  };

  // Use Effect so that when the hub selection changes we scroll to top
  useEffect(() => {
    handleScrollClick()
  }, [selection]);


  const hubview = () => {

    if (selection === "overview") {
      return (
        <React.Fragment>
          <ClimateFactors
            analysis_data={analysis}
            toggle={toggle}
            setToggle={setToggle}
            cardType={cardType}
            setCardType={setCardType}
            cardSelection={cardSelection}
            setCardSelection={setCardSelection}
          />

        </React.Fragment>
      )
    } else if (selection === "financial") {
      return (
        <React.Fragment>
          <FinancialImpact />
        </React.Fragment>
      )
    } else if (selection === "projects") {
      return (
        <React.Fragment>
          <HubProjects />
        </React.Fragment>
      )
    } else if (selection === "saved_projects") {
      return (
        <React.Fragment>
          <HubSavedProjects />
        </React.Fragment>
      )
    } else if (selection === "take_action") {
      return (
        <React.Fragment>
          <NowWhatGuidance />
        </React.Fragment>
      )
    }
    else {
      return (
        <div>Ooops! Looks like something went wrong.</div>
      )
    }
  };

  return (
    <>
      <HubNavigator.Provider value={{ selection, setSelection }}>
        <div ref={targetRef} className={"hub-frame"}>
          <HubHeader
            hubNav={hubNav}
            selection={selection}
            setSelection={setSelection}
            message="top"
            toggle={toggle}
          />
          {cardToggleStatus ? <CardContainer /> : null}
          <img className={"hub-logo-quoll"} src={require("../../assets/hub-watermark-new.svg")} />
          <div className={"hub-content-frame"}>
            {hubview()}
            {selection !== "financial" &&
              <div className="page-content-horizontal-divider-full" />
            }
            <HubButtonBar selection={selection} setSelection={setSelection} navButtons={hubButtons} />
          </div>
        </div>
      </HubNavigator.Provider>
    </>
  );
};
