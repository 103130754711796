import { CreateCloudfrontUrl } from "./CreateCloudfrontUrl";

export function GetQuollLinks() {

    const twitter_logo = CreateCloudfrontUrl("twitter-logo.png")
    const linkedin_logo = CreateCloudfrontUrl("linkedin-logo.png")
    const facebook_logo = CreateCloudfrontUrl("facebook-logo.png")
    const youtube_logo = CreateCloudfrontUrl("youtube-logo.webp")
    
    
    const links = {
        links: [
            { text: "Your Challenge", url: "/your-challenge", location: "header" },
            { text: "What We Do", url: "/what-we-do", location: "header" },
            { text: "Resources", url: "/resources", location: "both" },
            { text: "FAQ", url: "/faq", location: "footer" },
            { text: "About Us", url: "/about-us", location: "footer" },
            { text: "Careers", url: "/careers", location: "footer" },
            // { text: "Steps to Protecting Your Home", url: "/", location: "footer" },
            { text: "Developers / API", url: "/developers", location: "footer" },
            { text: "Contact", url: "/contact", location: "footer" },
        ],
        button: { text: "My Dashboard", logged_in_url: "/my-dashboard", default_url: "/sign-in", location: "header" },
        social: [
            {img: twitter_logo, url: "https://twitter.com/Quoll_Intel" },
            {img: linkedin_logo, url: "https://www.linkedin.com/company/get-quoll" },
            {img: facebook_logo, url: "https://www.facebook.com/getquoll" },
            {img: youtube_logo, url: "https://www.youtube.com" },
        ],
        legal:[
            { text: "Privacy Policy", url: "/privacy/privacy-policy" },
            { text: "Terms and Conditions", url: "/privacy/terms-and-conditions" },
            { text: "CCPA Request", url: "/ccpa-request" },
          ],
        copyright: "2023 Copyright | Quoll Intelligence"
    }

    return links
}