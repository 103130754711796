import React, { useContext } from "react";
import { windowSize } from "../../App";
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import "./style.css";

export const ProjectCardAccordianHeader = ({
  isActive,
  setIsActive,
  headline,
  value,
  description,
}) => {

  const deviceType = useContext(windowSize)

  const style = {fontSize: '2rem'}
  // console.log(deviceType)


  return (
    <div className={`project-card-accordian-header project-card-accordian-header-${deviceType}`} onClick={() => setIsActive(!isActive)}>
          <h4 className={'project-card-accordian-headline'}>
            {headline}
          </h4>
          <span className={'span-m project-card-accordian-value-description'}>
            {description}{value && ": " + value}
          </span>
          {/* <div className={'project-card-accordian-value'}>
            {value}
          </div> */}
          <div className={'project-card-accordian-button-container'}>
              {isActive ? <RemoveCircleOutlineSharpIcon style={style}/> : <AddCircleOutlineSharpIcon style={style}/>}
            {/* <div className={'project-card-accordian-button'}>
              {isActive ? '-' : '+'}
            </div> */}
          </div>
    </div>
  );
};
