import React, { useState, useEffect, useContext, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { TabletDesktop } from "./screens/TabletDesktop";
import { HomePage } from "./screens/HomePage";
import { ResourceView } from "./screens/Resources";
import { Header } from "./modules/Header";
import { Footer } from "./modules/Footer";
import { Blog } from "./screens/Blog";
import { AboutUs } from "./screens/AboutUs";
import { Methodology } from "./screens/Methodology";
import { SearchContextProvider } from "./services/SearchContext";
import { UserStatusProvider } from "./services/UserMgt";
import { Sorry } from "./screens/Sorry/Sorry";
import { Error } from "./screens/Error/Error";
import { UserAuthentication } from "./screens/UserAuthentication";
import { TheProblem } from "./screens/TheProblem";
import { Dashboard } from "./screens/Dashboard";
import { Privacy } from "./screens/Privacy";
import { Careers } from "./screens/Careers";
import { Developers } from "./screens/Developers";
import { ContactUs } from "./screens/ContactUs";
import { FAQ } from "./screens/FAQ/FAQ";
// import { ScrollToTop } from "./services/ScrollToTop";
// import ErrorBoundary from "./services/ErrorBoundary";
import './index.css';


const Layout = ({ children }) => (
  <>
    <UserStatusProvider>

      <SearchContextProvider>
        <Header />
        {children}
        <Footer />
      </SearchContextProvider>

    </UserStatusProvider>
  </>
);

export const windowSize = React.createContext()

export const App = () => {

  // const [deviceType, setDeviceType] = useState('desktop');
  
  function getDeviceType() {
    const width = window.innerWidth;
    // console.log(width);
    if (width < 480) {
      return 'mobile';
    } else if (width >= 480 && width < 769) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }
  const [deviceType, setDeviceType] = useState(getDeviceType());
  console.log(deviceType);

  useEffect(() => {
    // setDeviceType(getDeviceType());
    const handleWindowResize = () => {
      setDeviceType(getDeviceType());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (

    <Router>
      {/* <ScrollToTop /> */}
      <windowSize.Provider value={deviceType}>
        <Layout>
          <Routes>
            <Route path="/*" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/developers" element={<Developers />} />
            <Route path="/error" element={<Error />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/house/:address/:house_id" element={<TabletDesktop />} />
            <Route path="/what-we-do" element={<Methodology />} />
            <Route path="/my-dashboard" element={<Dashboard />} />
            <Route path="/privacy/:slug" element={<Privacy />} />
            <Route path="/resources" element={<ResourceView />} />
            <Route path="/resources/:slug" element={<Blog />} />
            <Route path="/sign-in" element={<UserAuthentication />} />
            <Route path="/sorry" element={<Sorry />} />
            <Route path="/sorry/:error_type" element={<Sorry />} />
            <Route path="/your-challenge" element={<TheProblem />} />
          </Routes>
        </Layout>
      </windowSize.Provider>
    </Router>


  );
};

export default App;