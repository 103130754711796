// combineArrays.js
// export const combineArrays = (analysisDatapoints, masterDatapoints) => {
//     const result = analysisDatapoints.reduce((acc, item1) => {
//       const matchedItem = masterDatapoints.find(item2 => item2.datapoint === item1.quoll_datapoint);
  
//       if (matchedItem) {
//         const { datapoint, ...restOfMatchedItem } = matchedItem;
//         acc.push({ ...item1, ...restOfMatchedItem });
//       }
  
//       return acc;
//     }, []);
  
//     return result;
//   }
  
//   export default combineArrays;
export const combineArrays = (analysisDatapoints, masterDatapoints) => {
  const updatedMasterDatapoints = {
    experience: [],
    financial: [],
    house: [],
  };

  // Process experience and financial keys
  ['experience', 'financial'].forEach((key) => {
    updatedMasterDatapoints[key] = masterDatapoints[key].reduce((acc, item) => {
      const matchedItem = analysisDatapoints.find(
        (analysisItem) => analysisItem.quoll_datapoint === item.datapoint
      );

      if (matchedItem) {
        acc.push({
          ...item,
          stat: matchedItem.stat,
        });
      }

      return acc;
    }, []);
  });

  // Process house key
  updatedMasterDatapoints.house = masterDatapoints.house.map((category) => ({
    ...category,
    datapoints: category.datapoints.reduce((acc, datapoint) => {
      const matchedItem = analysisDatapoints.find(
        (analysisItem) => analysisItem.quoll_datapoint === datapoint.datapoint
      );

      if (matchedItem) {
        acc.push({
          ...datapoint,
          stat: matchedItem.stat,
        });
      }

      return acc;
    }, []),
  }));

  return updatedMasterDatapoints;
};
