export function FilterProjects(projects, riskFilter, homeAreaFilter) {
    let filteredProjects = projects;
  
    if (riskFilter && !homeAreaFilter) {
      filteredProjects = filteredProjects.filter(
        // project => project.risk === riskFilter
        // project => project.associated_climate_risks_list === riskFilter
        project => project.associated_climate_risks_list.includes(riskFilter)
      );
    } else if (homeAreaFilter && !riskFilter) {
      filteredProjects = filteredProjects.filter(
        // project => project.house_area === homeAreaFilter
        project => project.house_category === homeAreaFilter
      );
    } else if (homeAreaFilter && riskFilter) {
      filteredProjects = filteredProjects.filter(
        project =>
          // project.risk === riskFilter && project.house_area === homeAreaFilter
          // project.associated_climate_risks_list === riskFilter && project.house_category === homeAreaFilter
          project.associated_climate_risks_list.includes(riskFilter) && project.house_category === homeAreaFilter
      );
    }
  
    return filteredProjects;
  }
  