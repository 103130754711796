import React, { useContext } from "react";
import { SearchBar } from "../SearchBar";
import { windowSize } from "../../App";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const DeepBreath = () => {

  // Get the device type
  const deviceType = useContext(windowSize)

  // Get the text for the page
  const static_text = StaticText()
  const deep_breath_text = static_text.home.deep_breath
  const headline_1 = deep_breath_text.headline_1
  const headline_2 = deep_breath_text.headline_2
  const description = deep_breath_text.description


  return (
    <div className="deep-breath-frame home-section-container home-section-column-container">
      <h2 className="deep-breath-headline">
        <span className="italic-text">{headline_1}</span>
        {headline_2}
      </h2>
      <div className={"deep-breath-paragraph"}>

        <p className={"deep-breath-paragraph-text break-spaces"}>
          {description}
        </p>

      </div>
      <div className={`deep-breath-search-bar-container-${deviceType}`}>

        <SearchBar />
      </div>
    </div>
  );
};
