import React, { useState } from "react";
import { DiscountCard } from "../../modules/DiscountCard";
// import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import "./style.css";

export const ProjectCardDiscounts = ({ discounts }) => {

  console.log(discounts)

  return (
    <div className="project-card-discount-frame">
      {/* <p>Hi there</p> */}
      {discounts.map((discount, index) => (
        <>
          <div className="project-card-individual-discount-container">

            {index !== 0 &&
              <div className="page-content-horizontal-divider-full" />
            }
            <span className={"span-l project-card-individual-discount-name"}>{discount.discount_name}</span>
            <p>{discount.discount_description}</p>
            <div className="project-card-discounts-container">

              {discount.associated_entity_discounts.map(company_discount => (
                <DiscountCard discount={company_discount} />
              ))}
            </div>
          </div>
        </>
      ))
      }
    </div>

  );
};
