import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CreateGoogleMapsStreetviewUrl } from "../../services/CreateGoogleMapsStreetviewUrl";
import { FormatSearchAddress } from "../../services/FormatSearchAddress";
import { windowSize } from "../../App";
import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp';
import { UserStatus } from "../../services/UserMgt";
import { saveHouseProjectResource } from "../../services/SaveHouseProjectResource";

import "./style.css";

export const DashboardHomeCard = ({ home, handleBookmarkClick }) => {

  // Get the user saved details
  const { userId, setUserId, userSavedHomes, setUserSavedHomes } = useContext(UserStatus);


  const navigate = useNavigate();

  const deviceType = useContext(windowSize)

  const quoll_score = home.quoll_score

  let risk_level;

  switch (quoll_score) {
    case 'A':
      risk_level = 'low';
      break;
    case 'B':
    case 'C':
      risk_level = 'medium';
      break;
    case 'D':
    case 'F':
      risk_level = 'high';
      break;
    default:
      risk_level = 'medium'; // default color if quoll_score doesn't match any case
  }

  const image_url = CreateGoogleMapsStreetviewUrl(home.lat, home.long);

  const parseAddress = (address) => {
    const [street, ...rest] = address.split(', ');
    const area = rest.join(', ');
    // const formattedStreet = `${street.replace(' St', ' St.')},`;
    return { street: street, area };
  };

  const parsedAddress = parseAddress(home.address);
  console.log(home);

  const formatted_address = FormatSearchAddress(home.address);
  const formatted_address_id_for_url = home.address_id + "_qid"
  const formatted_redirect_url = "/house/" + formatted_address + "/" + formatted_address_id_for_url

  const projects_info = [
    {
      headline: "Saved Projects",
      stat: home.saved_projects.length
    },
    {
      headline: "Completed Projects",
      stat: home.completed_projects.length
    },
    {
      headline: "Identified Discounts",
      stat: "0"
    }
  ];

  const handleClick = () => {
    navigate(formatted_redirect_url);
  };

  const handleCardBookmarkClick = (e) => {
    e.stopPropagation();
    const api_params = {
      type: "house",
      address_id: home.address_id,
      user_id: userId
    }

    saveHouseProjectResource(api_params)
      .then(output => output)
      .catch(error => {
        console.log(error);
      });

    const updatedSavedHomes = userSavedHomes.filter((house) => house.address_id !== home.address_id);
    setUserSavedHomes(updatedSavedHomes);
    console.log("Set everything!")
  };

  return (
    <div
      key={home.address}
      className={`dashboard-home-card-frame dashboard-home-card-frame-${deviceType} box-shadow-standard`}
      onClick={handleClick}
    >
      <div
        className="dashboard-home-card-image-container"
        style={{
          backgroundImage: `url(${image_url})`,
        }}
      >
        <div className="dashboard-home-card-score-bookmark-container">

          <span className={`box-shadow-standard dashboard-home-card-quoll-score-container background-color-${risk_level}-risk`}>{quoll_score}</span>
          <div className="dashboard-home-card-bookmark-container box-shadow-standard-left"
            onClick={handleCardBookmarkClick}
          >

            <BookmarkAddedSharpIcon />
          </div>
        </div>
      </div>
      <div className="dashboard-home-card-address-bar">
        <h4 className={"dashboard-home-card-address-text"}>{parsedAddress.street}<br />{parsedAddress.area}</h4>
      </div>
      <div className="dashboard-home-card-projects-bar">
        {projects_info.map(project => {

          return (

            <div className="dashboard-home-card-projects-bar-item">
              <span className={"dashboard-home-card-projects-bar-item-headline"}>{project.headline}</span>
              <span>{project.stat}</span>
            </div>
          )
        }
        )}
      </div>
    </div>
  );
};
