import PropTypes from "prop-types";
import React from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";

export const ValueItem = ({
  icon,
  text,
}) => {

  const icon_url = CreateCloudfrontUrl(icon);

  return (
    <div className={"value-prop-item-container"}>

      <div className="value-prop-item-image-container">

        <img className={'value-prop-item-image'} src={icon_url} />

      </div>

      <p className="value-prop-item-text">{text}</p>

    </div>
  );
};

ValueItem.propTypes = {
  text: PropTypes.string,
};
