import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { StartButton } from "../StartButton/StartButton";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const AboutUs = () => {

  // Get text for the page
  const static_text = StaticText()
  const about_us_text = static_text.home.about_us

  const headline_1 = about_us_text.headline_1
  const headline_2 = about_us_text.headline_2
  const description = about_us_text.description
  const cta = about_us_text.cta
  const image = about_us_text.image

  // Create the cloudfront url for the image
  const team_image_url = CreateCloudfrontUrl(image);

  return (
    <div className="about-us-frame home-section-container home-section-column-container">
      <h2 className="about-us-header">{headline_1}<span className={'about-us-header italic-text'}>{headline_2}</span></h2>

      <div className="about-us-container">
        <div className="about-us-image-container">
          <img className={'about-us-image'} src={team_image_url} />
        </div>
        <div className="about-us-text-container">
          <p className={"about-us-text"}>{description}</p>
        </div>

      </div>

      <Link key={'about-us-homepage'} to={'/about-us'}>
        <StartButton text={cta} />
      </Link>
    </div>
  );
};
