import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Button = ({

  isSelected,
  eventHandler,
  defaultIcon,
  selectedIcon,

}) => {
  
  return (
    <div onClick={eventHandler} className={`${isSelected ? "title-button-container-selected" : "title-button-container"}`}>
      <span className={"span-m title-button-text"}>
        {isSelected && selectedIcon}
        {!isSelected && defaultIcon}
      </span>
    </div>
  );
};