import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import MenuItem from '@mui/material/MenuItem';
import jwt_decode from 'jwt-decode';
import Checkbox from '@mui/material/Checkbox';
import { triggerSignup } from "../../services/TriggerSignup";
import { UserStatus } from "../../services/UserMgt";
import { StartButton } from "../../components/StartButton/StartButton";
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import "./style.css";


export const SignUp = ({ toggleView, deviceType, specialHandlingStatus, specialHandlingMethod }) => {

  // Get navigation function for where we send user upon successful login
  const navigate = useNavigate();

  // Create a ref for the Select component
  const selectMenuRef = useRef(null);

  // Get the ability to set user id
  const { setPageTitle, sessionId, userId, setUserId, setUserIdStatus } = useContext(UserStatus)

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Sign-up";
    setPageTitle("Sign-up");
  }, []);


  // Create the reasons to join Quoll
  const reasons_to_join = [
    "Schedule Consultations With Quoll Experts",
    "Save and Compare Homes and Projects",
    "Get Maintenance Notifications",
    "Access Additional Buyer and Owner Tools",
    "Access Additional Discounts",
    "100% Free to Join and Use!",
  ]

  // Create the step tracker
  const [signUpStep, setSignUpStep] = useState(1)

  // Create user signup methodology
  const [userSignUpMethod, setUserSignUpMethod] = useState(null)

  // Create user signup methodology
  const [ssoProvider, setSsoProvider] = useState(null)

  // Create the email value and validation checks
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailErrorMessageStatus, setEmailErrorMessageStatus] = useState(false);
  const emailErrorMessage = "Missing or invalid email"

  // Create the password value and validation checks

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordErrorMessageStatus, setPasswordErrorMessageStatus] = useState(false);
  const passwordErrorMessage = "Password must be at least 8 characters long and contain at least 1 number and 1 special character. Special characters can include symbols like @, #, $, %, etc."

  // Create the first name and last name values (since we can get from Google)

  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)

  // Create the form question values from part 2 of signup

  const [currentOwner, setCurrentOwner] = useState(null)
  const [currentOwnerStatus, setCurrentOwnerStatus] = useState(false)

  const [currentlyLooking, setCurrentlyLooking] = useState(null)
  const [currentlyLookingStatus, setCurrentlyLookingStatus] = useState(false)

  const [projectsPreference, setProjectsPreference] = useState(null)
  const [projectsPreferenceStatus, setProjectsPreferenceStatus] = useState(false)

  const [whatMattersMost, setWhatMattersMost] = useState(null)
  const [whatMattersMostStatus, setWhatMattersMostStatus] = useState(false)

  const [termsAcceptance, setTermsAcceptance] = useState(true);
  const [termsAcceptanceStatus, setTermsAcceptanceStatus] = useState(true);

  console.log("currentOwnerStatus", currentOwnerStatus)
  console.log("currentlyLookingStatus", currentlyLookingStatus)
  console.log("projectsPreferenceStatus", projectsPreferenceStatus)
  console.log("whatMattersMostStatus", whatMattersMostStatus)
  console.log("currentOwner", currentOwner)
  console.log("currentlyLooking", currentlyLooking)
  console.log("projectsPreference", projectsPreference)
  console.log("whatMattersMost", whatMattersMost)


  // STEP 1 OF THE PROCESS - CAPTURING REGISTRATION INFORMATION

  // Create Google callback response function 
  function handleCallbackResponse(response) {
    // console.log("Encoded JWT ID token: " + response.credential);
    const userObject = jwt_decode(response.credential);
    setFirstName(userObject.given_name)
    setLastName(userObject.family_name)
    setEmail(userObject.email)
    setUserSignUpMethod("sso")
    setSsoProvider("google")
    setSignUpStep(2)

    // console.log(userObject);
  }

  // Get the Google SSO
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: "570738405425-1r7m435n7ak8vhfqnb25v2n3u13e3kv2.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large", width: "35vw", alignSelf: "center" }
    )
  }, []);

  // Monitor email, validate, and update whether it is valid

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (email && !validateEmail(email)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  }, [email]);

  // Monitor password, validate, and update whether it is valid

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  useEffect(() => {
    if (password && !validatePassword(password)) {
      setIsPasswordValid(false);
      setPasswordErrorMessageStatus(true);
    } else {
      setPasswordErrorMessageStatus(false);
      setIsPasswordValid(true);
    }
  }, [password]);

  // Check on the email and password when a user clicks on submit
  const handleNextStepClick = () => {
    // Perform email validation checks
    if (isEmailValid && email !== '') {
      setEmailErrorMessageStatus(false);
    } else {
      setEmailErrorMessageStatus(true);
    }

    // Perform password validation checks
    if (password && password !== '') {
      setPasswordErrorMessageStatus(false);
    } else {
      setPasswordErrorMessageStatus(true);
    }

    // Additional logic for handling the form submission if both email and password are valid
    if (isEmailValid && isPasswordValid && email !== '' && password !== '') {

      setUserSignUpMethod("form")
      setSignUpStep(2)
    }
  };

  // STEP 2 OF THE PROCESS - CAPTURING FORM / QUESTIONS DATA

  // Create error message and set status
  const formErrorMessage = "Looks like the form isn't quite complete!"
  const [formErrorMessageStatus, setFormErrorMessageStatus] = useState(false);

  // Handle changes to the form
  const handleCurrentOwnerChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(event)
    setCurrentOwner(event.target.value);
    setCurrentOwnerStatus(true);
  };

  const handleCurrentlyLookingChange = (event) => {
    setCurrentlyLooking(event.target.value);
    setCurrentlyLookingStatus(true);
  };

  const handleProjectPreferenceChange = (event) => {
    setProjectsPreference(event.target.value);
    setProjectsPreferenceStatus(true);
  }

  const handleWhatMattersMostChange = (event) => {
    setWhatMattersMost(event.target.value);
    setWhatMattersMostStatus(true);
  }

  const handleTermAcceptanceChange = (event) => {
    setTermsAcceptance(event.target.value);
    // setWhatMattersMostStatus(true);
  }

  // Handle clicking submit

  const signupTriggerParam = {
    email: email,
    password: password,
    signup_method: userSignUpMethod,
    sso_provider: ssoProvider,
    current_owner_status: currentOwner,
    in_market_status: currentlyLooking,
    project_preferences: projectsPreference,
    what_matters: whatMattersMost,
  }

  const handleCreateAccountClick = () => {
    // Perform email validation checks
    if (currentOwnerStatus && currentlyLookingStatus && projectsPreferenceStatus && whatMattersMostStatus) {

      // console.log("Form complete!");
      triggerSignup(signupTriggerParam)
        .then(output => {
          if (output.status === "pass") {
            // console.log("Status: " + output.status)
            // console.log("User Id: " + output.user_id)
            setUserId(output.user_id)
            setUserIdStatus(true)
            if (specialHandlingStatus) {
              specialHandlingMethod()
            } else {
              navigate(-1)
            }
          } else {
            console.log("Error")
          }
        })
        .catch(error => {
          console.log(error);
        });

    } else {

      setFormErrorMessageStatus(true);

    }

  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  // const form_input_width = "auto"

  let checkmark_size
  let form_input_width
  let signin_orientation

  switch (deviceType) {
    case 'desktop':
      checkmark_size = '3.5rem';
      form_input_width = '25vw';
      signin_orientation = 'row';
      break;
    case 'tablet':
      checkmark_size = '2.5rem';
      form_input_width = '35vw';
      signin_orientation = 'row';
      break;
    case 'mobile':
      checkmark_size = '1.5rem';
      form_input_width = '55vw';
      signin_orientation = 'column';
      break;
    default:
      checkmark_size = '3.5rem';
      form_input_width = '25vw';
      signin_orientation = 'row';
  }


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    disablePortal: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <div className="user-auth-choice-box">

        <h1 className={"auth-user-header-text"}>Join Quoll</h1>
        {signUpStep === 1 &&
          <>
            <div className="auth-user-reasons-to-join-container">
              {reasons_to_join.map(reason =>
                <div className="auth-user-reason-to-join-box">

                  <CheckCircleOutlineSharpIcon style={{ fontSize: checkmark_size }} className={"auth-user-reason-to-join-check"} />
                  <span className={"span-s auth-user-reason-to-join-text"}>{reason}</span>
                </div>
              )}
            </div>
            {signin_orientation === 'column' &&
              <div className="page-content-horizontal-divider"></div>
            }
            <div className={`sign-in-container sign-in-container-${signin_orientation}`}>

              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <div className={"user-auth-signup-form-container"}>
                  <TextField
                    style={{ width: form_input_width, fontFamily: "inherit" }}
                    className={"form-text-frame"}
                    required
                    // variant="filled"
                    id="email-input"
                    label="Email"
                    error={!isEmailValid}
                    value={email}
                    onChange={handleEmailChange}
                  />

                  <TextField
                    style={{ width: form_input_width, fontFamily: "inherit" }}
                    className={"form-text-frame"}
                    required
                    id="password-input"
                    label="Password"
                    error={!isPasswordValid}
                    value={password}
                    onChange={handlePasswordChange}
                  />

                </div>
              </Box>
              {signin_orientation === 'row' &&
                <div className="vertical-divider"></div>
              }
              {signin_orientation === 'column' &&
                <span className={"span-l signin-up-or-text"}>OR</span>
              }
              <div id="signInDiv" className="sso-container">
              </div>
            </div>
            {(emailErrorMessageStatus || passwordErrorMessageStatus) &&
              <div>
                {emailErrorMessageStatus &&
                  <p className={"user-auth-form-error-message"}>{emailErrorMessage}</p>
                }
                {passwordErrorMessageStatus &&
                  <p className={"user-auth-form-error-message"}>{passwordErrorMessage}</p>
                }
              </div>
            }
            <div className="user-auth-choice-button box-shadow-light" onClick={handleNextStepClick}>
              {/* <h4 className={"button-text"}>Next</h4> */}
              <StartButton text="Next" auth_process_flag={true} />
            </div>
          </>
        }
        {signUpStep === 2 &&
          <>
            <p>Just a few questions to help us tailor your experience</p>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div className={"user-auth-signup-form-container-row"}>
                <FormControl sx={{ m: 1, width: form_input_width }}>
                  <InputLabel className={currentOwnerStatus && "input-label-top-view-container"} ref={selectMenuRef} id="demo-multiple-checkbox-label">Do you currently own a home?</InputLabel>
                  <Select
                    required
                    id="do-you-own-home"
                    select
                    defaultValue=" "
                    value={currentOwner}
                    input={<OutlinedInput label="Do you already own a home?" />}
                    className={"select-options-box"}
                    variant="filled"
                    onChange={handleCurrentOwnerChange}
                    labelId="demo-multiple-checkbox-label"
                    MenuProps={MenuProps}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}

                  >
                    <MenuItem key={"own-yes"} value={"YES"}>
                      {"Yes"}
                    </MenuItem>
                    <MenuItem key={"no"} value={"NO"}>
                      {"No"}
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* <TextField
                  className={"form-text-frame"}
                  required
                  id="are-you-looking-for-new-home"
                  select
                  label="Are you looking for a new home?"
                  defaultValue=" "
                  onChange={handleCurrentlyLookingChange}
                > */}
                <FormControl sx={{ m: 1, width: form_input_width }}>
                  <InputLabel className={currentlyLookingStatus && "input-label-top-view-container"} ref={selectMenuRef} id="demo-multiple-checkbox-label">Are you looking for a new home?</InputLabel>
                  <Select
                    required
                    id="are-you-looking-for-new-home"
                    // select
                    // defaultValue=" "
                    value={currentlyLooking}
                    input={<OutlinedInput label="Are you looking for a new home?" />}
                    className={"select-options-box"}
                    variant="filled"
                    onChange={handleCurrentlyLookingChange}
                    labelId="demo-multiple-checkbox-label"
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={"looking-yes"} value={"YES"}>
                      {"Yes"}

                    </MenuItem>

                    <MenuItem key={"no"} value={"NO"}>
                      {"No"}
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* </TextField> */}

                {/* <TextField
                  className={"form-text-frame"}
                  required
                  id="home-improvements"
                  select
                  autoWidth
                  label="Do you like doing home projects?"
                  defaultValue=" "
                  onChange={handleProjectPreferenceChange}
                > */}
                <FormControl sx={{ m: 1, width: form_input_width }}>
                  <InputLabel className={projectsPreferenceStatus && "input-label-top-view-container"} ref={selectMenuRef} id="demo-multiple-checkbox-label">Do you like doing home projects?</InputLabel>
                  <Select
                    required
                    id="home-improvements"
                    // select
                    // defaultValue=" "
                    value={projectsPreference}
                    input={<OutlinedInput label="Do you like doing home projects?" />}
                    className={"select-options-box"}
                    variant="filled"
                    onChange={handleProjectPreferenceChange}
                    labelId="demo-multiple-checkbox-label"
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={"diy"} value={"DIY Preference"}>
                      {"I prefer to do improvements myself"}
                    </MenuItem>

                    <MenuItem key={"diy-maybe"} value={"DIY Aspiring"}>
                      {"I'd love to do more DIY but am not confident I know how"}
                    </MenuItem>

                    <MenuItem key={"professional"} value={"Professional Help Preference"}>
                      {"I prefer to hire professionals wherever possible"}
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* </TextField> */}
                {/* <TextField
                className={"form-text-frame"}
                required
                id="home-improvements"
                select
                label="What matters most to you?"
                defaultValue=" "
                onChange={handleWhatMattersMostChange}
              > */}
                <FormControl sx={{ m: 1, width: form_input_width }}>
                  <InputLabel className={whatMattersMostStatus && "input-label-top-view-container"} ref={selectMenuRef} id="demo-multiple-checkbox-label">Do you like doing home projects?</InputLabel>
                  <Select
                    required
                    id="home-improvements"
                    // select
                    // defaultValue=" "
                    value={whatMattersMost}
                    input={<OutlinedInput label="Do you like doing home projects?" />}
                    className={"select-options-box"}
                    variant="filled"
                    onChange={handleWhatMattersMostChange}
                    labelId="demo-multiple-checkbox-label"
                    MenuProps={MenuProps}
                  >

                    <MenuItem key={"cost"} value={"Cost"}>
                      {"Cost of improvements"}
                    </MenuItem>

                    <MenuItem key={"cost"} value={"Cost"}>
                      {"Quality and durability"}
                    </MenuItem>

                    <MenuItem key={"savings"} value={"Saving"}>
                      {"Saving money from improvements"}
                    </MenuItem>

                    <MenuItem key={"ease"} value={"Ease"}>
                      {"How easy the improvement is / distruption to your life"}
                    </MenuItem>

                    <MenuItem key={"value"} value={"Value"}>
                      {"How an improvement impacts your home's value"}
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* </TextField> */}
              </div>
            </Box>
            {formErrorMessageStatus &&
              <div>
                <p className={"user-auth-form-error-message"}>{formErrorMessage}</p>
              </div>
            }
            <div>
              <Checkbox
                disabled
                checked
              />
              <span>By creating an account I accept Quoll's <Link key={'signup-terms-and-conditions-link'} to={'/terms-and-conditions'} className={'terms-and-conditions-link'}>Terms and Conditions</Link> and have read the <Link key={'signup-privacy-policy-link'} to={'/privacy-policy'} className={'terms-and-conditions-link'}>Privacy Policy</Link></span>
            </div>
            <div className="user-auth-choice-button box-shadow-light" onClick={handleCreateAccountClick}>
              {/* <h4 className={"button-text"}>Create Your Free Account</h4> */}
              <StartButton text="Create Your Free Account" />
              {/* <h4 className={"button-text"}>Create Your Free Account</h4> */}
            </div>
          </>
        }
      </div >
      <h3 className={"user-auth-view-toggle-text"} onClick={toggleView}>Already have an account? Sign in <span className="user-auth-view-toggle-text underline-text">here</span>!</h3>
    </>
  );
};

// OLD FORM


// <div className={"user-auth-signup-form-container-row"}>
//                   <TextField
//                     // className={"form-text-frame"}
//                     required
//                     id="do-you-own-home"
//                     select
//                     // label="Do you already own a home?"
//                     defaultValue=" "
//                   >
//                     <MenuItem key={"own-yes"} value={"YES"}>
//                       {"Yes"}
//                     </MenuItem>
//                     <MenuItem key={"no"} value={"NO"}>
//                       {"No"}
//                     </MenuItem>
//                   </TextField>

//                 <TextField
//                   className={"form-text-frame"}
//                   required
//                   id="are-you-looking-for-new-home"
//                   select
//                   label="Are you looking for a new home?"
//                   defaultValue=" "
//                   onChange={handleCurrentlyLookingChange}
//                 >

//                   <MenuItem key={"looking-yes"} value={"YES"}>
//                     {"Yes"}

//                   </MenuItem>

//                   <MenuItem key={"no"} value={"NO"}>
//                     {"No"}
//                   </MenuItem>

//                 </TextField>

//                 <TextField
//                   className={"form-text-frame"}
//                   required
//                   id="home-improvements"
//                   select
//                   autoWidth
//                   label="Do you like doing home projects?"
//                   defaultValue=" "
//                   onChange={handleProjectPreferenceChange}
//                 >

//                   <MenuItem key={"diy"} value={"DIY Preference"}>
//                     {"I prefer to do improvements myself"}
//                   </MenuItem>

//                   <MenuItem key={"diy-maybe"} value={"DIY Aspiring"}>
//                     {"I'd love to do more DIY but am not confident I know how"}
//                   </MenuItem>

//                   <MenuItem key={"professional"} value={"Professional Help Preference"}>
//                     {"I prefer to hire professionals wherever possible"}
//                   </MenuItem>

//                 </TextField>

//                 <TextField
//                   className={"form-text-frame"}
//                   required
//                   id="home-improvements"
//                   select
//                   label="What matters most to you?"
//                   defaultValue=" "
//                   onChange={handleWhatMattersMostChange}
//                 >

//                   <MenuItem key={"cost"} value={"Cost"}>
//                     {"Cost of improvements"}
//                   </MenuItem>

//                   <MenuItem key={"cost"} value={"Cost"}>
//                     {"Quality and durability"}
//                   </MenuItem>

//                   <MenuItem key={"savings"} value={"Saving"}>
//                     {"Saving money from improvements"}
//                   </MenuItem>

//                   <MenuItem key={"ease"} value={"Ease"}>
//                     {"How easy the improvement is / distruption to your life"}
//                   </MenuItem>

//                   <MenuItem key={"value"} value={"Value"}>
//                     {"How an improvement impacts your home's value"}
//                   </MenuItem>

//                 </TextField>
//               </div>