import React from "react";
import "./style.css";

export const CostLabel = ({
  
  header = "ESTIMATED COST",
  elementStyle, 
  value = "$550.00"
}) => {
  return (
    <div className={"cost-label-cost-label"}>
      <span className={"span-xs cost-label-estimated-cost"}>{header}</span>
      <span className={"span-s cost-label-element"} style={elementStyle}>
       {value}
      </span>
    </div>
  );
};
