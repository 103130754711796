import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StartButton } from "../../components/StartButton/StartButton";
// import { RecordPageView } from "../../services/HandleGoogleAnalytics";
import { UserStatus } from "../../services/UserMgt";
import { StartButton } from "../../components/StartButton/StartButton";
import "./style.css";


export const Developers = () => {

  // Set up navigate for sending the user to the Contact Us page
  const navigate = useNavigate();

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Developers + API";
    setPageTitle("Developers + API");
  }, []);

  // Set up the handler for going to Contact Us 
  const handleContactUsClick = (event) => {
    navigate("/contact");
  };


  const developer_api_access_paragraph = "Quoll has built a series of proprietary models that enable us to produce a first-of-its-kind, address and house-specific risk analysis covering every single-family home in America. All of our models are cloud-based and API accessible. If you are interested in gaining access to these models, please fill out the Contact Us form and include a description of how you'd like to utilize our infrastructure."

  return (
    <div className={'page-frame-static'}>
      <div className={'page-container-static'}>
        <div className={"static-page-header-frame"}>
          <h1 className={'header-center-text'}>Interested in Accessing Our Models?</h1>
        </div>
        <div className="page-content-container-static">
          <p className={"resources-text-styles"}>{developer_api_access_paragraph}</p>
        </div>
        <div className={"clickable"} onClick={handleContactUsClick}>
          <StartButton text="Contact Us" shadow_flag={true} />
        </div>
      </div>

    </div>
  );
};
