import axios from "axios";

export function SendTransactionalEmail(form_data) {

  // Guide for aligning with backend
  const purpose_to_form_type = {
    data_collection: "data_collection",
    project_consultation_buyer: "project_help_buyer",
    project_consultation_owner: "project_help_owner",
    careers_submission: "careers_submission",
    contact_us: "contact_us",
  }

  // Capture the inputs available
  const formType = form_data.formType || null
  const senderName = form_data.senderName || null
  const email = form_data.email || null
  const recipientEmails = form_data.recipientEmails || null
  const selectedProjects = form_data.selectedProjects || null
  const personalNote = form_data.personalNote || null
  const url = form_data.url || null
  const address = form_data.address || null
  const climateRisks = form_data.climateRisks || null
  const whyQuoll = form_data.whyQuoll || null
  const resume = form_data.resume || null
  const contactUsTopicsOfInterest = form_data.contactUsTopicsOfInterest || null

  // Determine the right form type naming convention for the backend
  const backendFormType = purpose_to_form_type[formType] || null

  // Build the request params based on the form type
  const request_param = {
    form_type: backendFormType,
    data: {
      submitted_by_name: senderName,
      submitted_by_email: email,
      email_recipients: recipientEmails,
      address: address,
      url: url,
      personal_note: personalNote,
      projects_of_interest: selectedProjects,
      climate_risks: climateRisks,
      why_quoll: whyQuoll,
      resume: resume,
      contact_us_topics_of_interest: contactUsTopicsOfInterest,
    }
  }

  if (backendFormType) {

    // axios.post('https://1nl7eg7oui.execute-api.us-east-2.amazonaws.com/test/send-transactional-emails', request_param)
    axios.post('/api/send-transactional-emails', request_param)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.error(error);
        throw error; // throw the error again after catching and logging it
      });
  }
}
