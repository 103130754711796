import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import jwt_decode from 'jwt-decode';
import { UserStatus } from "../../services/UserMgt";
import { triggerSignin } from "../../services/TriggerSignin";
import { StartButton } from "../../components/StartButton/StartButton";
import "./style.css";


export const SignIn = ({ toggleView, deviceType, specialHandlingStatus = false, specialHandlingMethod }) => {

  // Get navigation function for where we send user upon successful login
  const navigate = useNavigate();

  // Get the ability to set user id
  const { setPageTitle, sessionId, userId, setUserId, setUserIdStatus } = useContext(UserStatus)

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Sign-in";
    setPageTitle("Sign-in");
  }, []);

  // Create the email value and validation checks
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailErrorMessageStatus, setEmailErrorMessageStatus] = useState(false);
  const emailErrorMessage = "Missing or invalid email"

  // Create the password value and validation checks
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordErrorMessageStatus, setPasswordErrorMessageStatus] = useState(false);
  const passwordErrorMessage = "Missing password"

  // Create the user signin method
  const [userSignInMethod, setUserSignInMethod] = useState(null);

  // Monitor email, validate, and update whether it is valid

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (email && !validateEmail(email)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  }, [email]);

  // Monitor password, validate, and update whether it is valid

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const validatePassword = (password) => {

    return (password && password !== '')
  };

  useEffect(() => {
    if (password && !validatePassword(password)) {
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  }, [password]);

  // Check on the email and password when a user clicks on submit
  const handleSignInClick = () => {
    // Perform email validation checks
    if (isEmailValid && email !== '') {
      setEmailErrorMessageStatus(false);
    } else {
      setEmailErrorMessageStatus(true);
    }

    // Perform password validation checks
    if (password && password !== '') {
      setPasswordErrorMessageStatus(false);
    } else {
      setPasswordErrorMessageStatus(true);
    }

    // Additional logic for handling the form submission if both email and password are valid
    if (isEmailValid && isPasswordValid && email !== '' && password !== '') {
      const signInParams = {
        email: email,
        password: password,
        signin_method: "form",
      };

      console.log(signInParams)
      triggerSignin(signInParams)
        .then(output => {
          console.log(output)
          if (output.status === "pass") {
            console.log("FORM LOG IN SUCCESSFUL!")
            console.log("Status: " + output.status)
            console.log("User Id: " + output.user_id)
            // alert('Sign In Successful!');
            setUserId(output.user_id)
            setUserIdStatus(true)
            if (specialHandlingStatus) {
              specialHandlingMethod()
            } else {
              navigate(-1)
            }
          } else {
            console.log("Error")
            setSsoErrorMessageStatus(true)
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  // Create SSO sign-in error message
  const [ssoErrorMessageStatus, setSsoErrorMessageStatus] = useState(false);
  const ssoErrorMessage = "Hmm, looks like our system is up to no good. We're working on a fix, check back later to log-in!"

  // Create Google callback response function 
  function handleCallbackResponse(response) {
    const userObject = jwt_decode(response.credential);

    const signInParams = {
      email: userObject.email,
      password: "NA",
      signin_method: "sso",
    };

    setEmail(userObject.email)
    setUserSignInMethod("sso")
    console.log(signInParams)
    triggerSignin(signInParams)
      .then(output => {
        console.log(output)
        if (output.status === "pass") {
          setUserId(output.user_id)
          setUserIdStatus(true)
          if (specialHandlingStatus) {
            specialHandlingMethod()
          } else {
            navigate(-1)
          }
        } else {
          console.log("Error")
          setSsoErrorMessageStatus(true)
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  // Get the Google SSO
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: "570738405425-1r7m435n7ak8vhfqnb25v2n3u13e3kv2.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    )
  }, []);

  let form_input_width
  let signin_orientation

  switch (deviceType) {
    case 'desktop':
      form_input_width = '25vw';
      signin_orientation = 'row';
      break;
    case 'tablet':
      form_input_width = '35vw';
      signin_orientation = 'row';
      break;
    case 'mobile':
      form_input_width = '55vw';
      signin_orientation = 'column';
      break;
    default:
      form_input_width = '25vw';
      signin_orientation = 'row';
  }


  return (
    <>
      <div className="user-auth-choice-box">

        <h1 className={"auth-user-header-text"}>Sign in to Quoll</h1>
        <div className={`sign-in-container sign-in-container-${signin_orientation}`}>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className={"user-auth-signup-form-container"}>
              <TextField
                className={"form-text-frame"}
                style={{ width: form_input_width }}
                required
                id="email-input"
                label="Email"
                error={!isEmailValid}
                value={email}
                onChange={handleEmailChange}
              />

              <TextField
                className={"form-text-frame"}
                style={{ width: form_input_width }}
                required
                id="password-input"
                label="Password"
                error={!isPasswordValid}
                value={password}
                onChange={handlePasswordChange}

              />

            </div>
          </Box>
          {signin_orientation === 'row' &&
            <div className="vertical-divider"></div>
          }
          {signin_orientation === 'column' &&
            <span className={"span-l signin-up-or-text"}>OR</span>
          }
          <div id="signInDiv" className="sso-container">
            {/* <img className={"sso-image"} src={"https://global.discourse-cdn.com/business5/uploads/webflow1/original/3X/2/4/24bc102eccbabdb30b5ec93447732ead235d5549.png"} /> */}

          </div>
        </div>
        {(emailErrorMessageStatus || passwordErrorMessageStatus) &&
          <div>
            {emailErrorMessageStatus &&
              <p className={"user-auth-form-error-message"}>{emailErrorMessage}</p>
            }
            {passwordErrorMessageStatus &&
              <p className={"user-auth-form-error-message"}>{passwordErrorMessage}</p>
            }
          </div>
        }
        <div className="user-auth-choice-button box-shadow-light" onClick={handleSignInClick}>
          {/* <h4 className={"button-text"}>Sign In</h4> */}
          <StartButton text="Sign In" auth_process_flag={true} />
        </div>
        <h3 className={"user-auth-view-toggle-text"} onClick={toggleView}>Don't have an account? Click <span className="user-auth-view-toggle-text underline-text">here</span> to create one!</h3>
      </div>
    </>
  );
};
