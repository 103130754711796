import React from "react";
import "./style.css";
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';


export const PlayWithDataButton = () => {
  
  const text = "Play with the Data & Assumptions"
  
  return (
    <div className={"play-with-data-button-container box-shadow-standard"}>
      <EditNoteSharpIcon/>
      <span className={'span-m play-with-data-button-text-wrapper'}>{text}</span>
    </div>
  );
};
