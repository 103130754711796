import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";


export const Sorry = () => {

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Sorry";
  }, []);


  const { error_type } = useParams();

  const text_options = {
    business: {
      text: "Quoll only covers single-family homes, and not businesses. While the climate risks may be the same, the makeup of a commercial space is dramatically different from that of a home and so we aren't yet able to properly recommend projects and other strategies for lowering your risk. However, it's on our roadmap! Want to be notified when we launch Quoll for business?",
      image: "store.png",
    },
    residence: {
      text: "Quoll only covers single-family homes, and not apartments. While the climate risks may be the same, apartment buildings add a ton of complexity, not only with regard to the various systems and structural needs, but also with things like ownership and how costs are handled. That said, apartments are on our roadmap! Would you like to be notified when we begin including them?",
      image: "apartments.png",
    },
    general: {
      text: "Looks like we ran into an error! Our team is working on it so be sure to check back soon.",
      image: "flat-tire.png",
    }
  }
  const [image, setImage] = useState(null);
  const [text, setText] = useState(null);

  useEffect(() => {
    if (error_type === "business") {
      setImage(text_options.business.image);
      setText(text_options.business.text);
    }
    else if (error_type === "residence-type") {
      setImage(text_options.residence.image);
      setText(text_options.residence.text);
    } else {
      setImage(text_options.general.image);
      setText(text_options.general.text);
    }
  }, []);

  console.log(image)


  return (
    <div className={'page-frame'}>
      <div className="page-container sorry-page-container">

        <div className={"sorry-image-container"}>
          <img className={"sorry-image"} src={CreateCloudfrontUrl(image)} />
        </div>
        <div className={"sorry-image-text-container"}>
          <h1>SORRY!</h1>
          <p className={"sorry-text"}>{text}</p>
        </div>
        {(error_type === "business" || error_type === "residence-type") &&
          <div className="misclassified-text-container">
            <h3 className={"sorry-text"}>Think we misclassified your home? Let us know! Drop us an email at the address below with the address and we will take a look.</h3>
            <span className={"sorry-text-email"}>ClassificationCheck@QuollIntelligence.com</span>
          </div>
        }
      </div>


    </div>
  );
};
