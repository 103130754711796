import React from "react";
import { useReducer } from "react";
import "./style.css";

export const ProjectStatusBar = ({
  property1 = "to-do",
  style = {
    alignSelf: "stretch",
    height: "unset",
    left: "unset",
    top: "unset",
  },
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "to-do",
  });

  return (
    <div
      className={"project-status-bar-property-to-do"}
      style={{
        ...{
          backgroundColor: state.property1 === "Complete" ? "#468347" : "#9590a7",
          left: state.property1 === "Variant3" ? "77px" : "20px",
          top: state.property1 === "Complete" ? "140px" : "20px",
        },
        ...style,
      }}
      onMouseLeave={() => {
        dispatch("mouse-leave");
      }}
      onClick={() => {
        dispatch("click");
      }}
      onMouseEnter={() => {
        dispatch("mouse-enter");
      }}
    >
      <div
        className={"project-status-bar-frame"}
        style={{
          backgroundColor: state.property1 === "to-do" ? "#ffffff" : "#dee4de",
          overflow: state.property1 === "Complete" || state.property1 === "Variant3" ? "hidden" : undefined,
        }}
      >
        <React.Fragment>
          {(state.property1 === "Complete" || state.property1 === "Variant3") && (
            <React.Fragment>
              <img className={"project-status-bar-icons-system"} src={"/img/icons-system-63.png"} />
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "to-do") {
    switch (action) {
      case "mouse-enter":
        return {
          property1: "Variant3",
        };
    }
  }

  if (state.property1 === "Variant3") {
    switch (action) {
      case "mouse-leave":
        return {
          property1: "to-do",
        };

      case "click":
        return {
          property1: "Complete",
        };
    }
  }

  if (state.property1 === "Complete") {
    switch (action) {
      case "click":
        return {
          property1: "to-do",
        };
    }
  }

  return state;
}
