import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { fetchContent } from "../../services/GetContent";
import { UserStatus } from "../../services/UserMgt";
import "./style.css";


export const Privacy = () => {

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Navigator if error
  const navigate = useNavigate();

  // Get the slug from the URL
  const slug = useParams().slug

  // const [title, setTitle] = useState("Quoll - Loading...")

  // useEffect(() => {
  //   // This will run when the page first loads and whenever the title changes
  //   document.title = title;
  // }, [title]);

  const [newTitle, setNewTitle] = useState(null)

  // Set the page title when the content loads and log with Google Analytics
  useEffect(() => {

    if (newTitle) {

      document.title = newTitle;
      setPageTitle(newTitle);
    }
  }, [newTitle]);

  // Set up the blog and blog status states
  const [blog, setBlog] = useState(null)
  const [blogStatus, setBlogStatus] = useState(false)

  // Call the Quoll API to get the content
  useEffect(() => {

    // LIVE API CALL
    const request_param = { slug: slug };

    fetchContent(request_param)
      .then(output => {
        console.log(output)
        if (output.status === 'success') {
          setBlog(output)
          setNewTitle(output.article.title)
          setBlogStatus(true)
        }
        else {
          navigate('/', { replace: true });
        }
        // setAnalysis(output);
        // setAnalysisStatus(true);
      })
      .catch(error => {
        console.log(error);
        navigate('/', { replace: true });
      });
  }, [slug]);


  const content = (blog !== null) ? blog.article : null

  // const blog_image = (content !== null) ? CreateCloudfrontUrl(content.image) : null;
  const blog_content = (content !== null) ? content.body : null;

  const ITALIC = ({ children }) => <p className={"italic"}>{children}</p>;

  const RICHTEXT_OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className={"blog-paragraph"}>{children}</p>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className={"blog-h2"}>{children}</h2>
      },
      // [BLOCKS.UL_LIST]: (node, children) => {
      //   return <li className={"blog-li"}>{children}</li>
      // },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className={"blog-ol"}>{children}</ul>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <img className={"blog-inline-image"} src={node.data} />
      },
    },
    renderMark: {

      [MARKS.ITALIC]: text => <i className={"italic"}>{text}</i>
    }
  }




  return (
    <div className={"page-frame-static privacy-page"}>
      <div className="legal-page-header">
        <div className="blog-header-overlay"></div>
        <div className={"blog-header-text"}>
          <h1 className={"blog-header-font blog-header-headline"}>{blog && content.title}</h1>
        </div>
      </div>
      <div className="page-container-static">
        <div className="blog-frame">
          {documentToReactComponents(blog_content, RICHTEXT_OPTIONS)}
        </div>
      </div>
    </div>
  );
};
