export function CompileClimateByHouseArea(houseArea, climateRisks) {
  const output = [];

  for (const riskName of houseArea.associated_climate_risks) {
      const matchingRisk = climateRisks.find(risk => risk.name === riskName);

      if (matchingRisk) {
          output.push({
              houseArea: matchingRisk.name,
              icon: matchingRisk.icon_black
          });
      }
  }

  return output;
}