import React, { useState, useContext, useEffect } from "react";
import { ProjectsListFrame80 } from "../../components/ProjectsListFrame80/ProjectsListFrame80";
import { CostList } from "../../components/CostList/CostList";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { UserStatus } from "../../services/UserMgt";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp';
import { ProjectStatusBar } from "../../components/ProjectStatusBar/ProjectStatusBar";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import "./style.css";

export const HubProjectsCard = ({
  project,

}) => {

  // Set the state of whether the project is currently saved
  const [isProjectSaved, setIsProjectSaved] = useState(false);

  // Get the user id
  const { userId, userSavedHomes, setUserSavedHomes } = useContext(UserStatus);

  // Grab the analysis details
  const analysis = useContext(AnalysisDetails);

  // Get the house id
  const address_id = analysis.address_id

  // Determine if this project is already saved
  useEffect(() => {
    const projectSaveStatusCheck = userSavedHomes.some((house) => (house.address_id === address_id && house.saved_projects.includes(project.id)));
    setIsProjectSaved(projectSaveStatusCheck);
  }, [userSavedHomes]);

  // console.log(project)

  // Grab navigation details from context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)

  const risks_list = project.associated_climate_risks_list;
  const project_image_file = project.image

  const project_image_url = CreateCloudfrontUrl(project_image_file)

  const [active, setActive] = useState(false);

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
  };

  return (

    <div
      key={project.id}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={`project-card-view-frame ${active ? "box-shadow-hover" : "box-shadow-standard"}`}
      onClick={() => {
        setCardSelection(project.id)
        setCardType("project")
        setCardToggleStatus(true)
        // console.log('inside')
      }}
    >
      {!isProjectSaved &&
        <div className="project-card-bookmark-container">
          <BookmarkBorderIcon className={"project-card-bookmark-default"} />
        </div>
      }
      {isProjectSaved &&
        <div className="project-card-bookmark-container">
          <BookmarkIcon className={"project-card-bookmark-saved"} />
        </div>
      }

      {/* <ProjectStatusBar /> */}
      <div className={"project-card-data-frame"}>

        <div className={"project-card-view-image-container"} style={{
          backgroundImage: `url(${project_image_url})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '15vh',
        }}>
          <div className={"project-card-view-image-overlay"}>
            {/* <LocalOfferSharpIcon className={"project-card-view-disconts-icon"}/> */}
            <span className={"span-l project-card-view-header-title"}>
              {project.name.length > 0 ? project.name : "Loading..."}
            </span>
          </div>

        </div>
        <div className={"project-card-view-header-risks-container"}>

          {risks_list.map(risk => {
            return (<ProjectsListFrame80 risk={risk} />)
          })}

        </div>
        <CostList
          project={project}
        />

        <div className="project-card-view-see-more-container">
          <span className={"span-s project-card-view-see-more-text"}>Learn More</span>
        </div>
      </div>

    </div>

  );

};
