export function FormatHouseForSave(analysis) {

    const output = {
        address_id: analysis.address_id,
        address: analysis.address_details.full_address,
        lat: analysis.address_details.lat,
        long: analysis.address_details.long,
        projects: analysis.projects,
        quoll_score: analysis.quoll_score,
        saved_projects: [],
        completed_projects: [],
    }

    return output

}