export function UpdateSavedProjects(addressId, projectId, arrayOfObjects) {
    return arrayOfObjects.map((obj) => {
      if (obj.address_id === addressId) {
        const savedProjects = obj.saved_projects.includes(projectId)
          ? obj.saved_projects.filter((id) => id !== projectId)
          : [...obj.saved_projects, projectId];
  
        return { ...obj, saved_projects: savedProjects };
      } else {
        return obj;
      }
    });
  };