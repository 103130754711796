import { AnalysisDetails } from "../screens/TabletDesktop/TabletDesktop";
import react, { useContext } from 'react';

// export function CountHouseAreas(projects, selection) {

//   const analysis = useContext(AnalysisDetails);
//   const house_areas = analysis && analysis.house_category_risks

//   if (selection) {
//     projects = projects.filter((project) => project.associated_climate_risks === selection);
//   }

//   const houseAreas = {};
//   projects.forEach((project) => {
//     if (houseAreas[project.house_category]) {
//       houseAreas[project.house_category]++;
//     } else {
//       houseAreas[project.house_category] = 1;
//     }
//   });

//   const result = [];
//   for (const houseArea in houseAreas) {
//     result.push({
//       houseArea,
//       total: houseAreas[houseArea],
//       icon: house_areas.filter(area => area.name == houseArea)[0].icon,
//       value: house_areas.filter(area => area.name == houseArea)[0].value,
//     });
//   }
//   result.sort((a, b) => b.total - a.total);

//   return result;
// }

export function CountHouseAreas(climateRisk, houseCategories, projectsArray) {
  const output = [];

  for (const category of houseCategories) {
    if (category.associated_climate_risks.includes(climateRisk.name)) {
      const projectsCount = projectsArray.filter(project => project.house_category === category.name).length;
      output.push({
        houseArea: category.name,
        icon: category.icon,
        total: projectsCount
      });
    }
  }

  return output;
}