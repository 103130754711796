import React, { useState, useContext, useRef, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { StartButton } from "../../components/StartButton/StartButton";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { SendTransactionalEmail } from "../../services/SendTransactionalEmail";
import { UserStatus } from "../../services/UserMgt";
import Snackbar from '@mui/material/Snackbar';
import { SnackBarAlert } from "../../components/SnackBarAlert";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import "./style.css";

export const SendToStakeholder = ({ climate_risks = null, address = null, purpose }) => {

  // Figure out if the user is signed in or not signed in 
  const { userId } = useContext(UserStatus);
  const user_status = userId ? "Signed In User" : "Not Signed In User"

  // Options for form type 
  const form_types = ["data_collection", "project_consultation", "careers_submission", "contact_us"]

  // Set up snackbar handling
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  // Get the current page url
  const page_url = window.location.href

  // Create a ref for the Select component
  const selectMenuRef = useRef(null);

  // Grab the analysis details
  const analysis = useContext(AnalysisDetails);

  // ----------------------------------------------------------------
  // Determine if the "Sent" text needs to be white or dark
  const sentTextColor = (purpose === "careers_submission" || purpose === "contact_us") ? "dark" : "light"

  // ----------------------------------------------------------------
  // Set up the state for sender name
  const [senderName, setSenderName] = useState('');
  const [isSenderNameValid, setIsSenderNameValid] = useState(true);

  // Set up the handler for changing of sender name
  const handleSenderNameChange = (event) => {
    setSenderName(event.target.value);
    validateName(senderName)
  };

  const validateName = (name) => {
    if (name.length === 0) {
      setIsSenderNameValid(false);
    } else {
      setIsSenderNameValid(true);
    }
  }

  // ----------------------------------------------------------------
  // Set up the state for email address of sender
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  // Set up the handler for changing of email address 
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    validateEmail(email);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!emailRegex.test(email)) {
      setIsEmailValid(false)
    } else {
      setIsEmailValid(true)
    }
    // return emailRegex.test(email);
  };

  // ----------------------------------------------------------------
  // Set up the state for the email addresses of recipients
  const [recipientEmails, setRecipientEmails] = useState([]);
  const [areRecipientEmailsValid, setAreRecipientEmailsValid] = useState(true);

  // Set handler for collecting the recipient emails 
  const handleRecipientEmailInput = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
    const {
      target: { value },
    } = event;
    setRecipientEmails(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',').map((email) => email.trim()) : value,
    );
    validateRecipientEmails(recipientEmails)
  };

  const validateRecipientEmails = (emailArray) => {
    // Define the regex pattern to match a single email address
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+$/;

    // Iterate through the emailArray
    for (let i = 0; i < emailArray.length; i++) {
      const email = emailArray[i].trim(); // Remove leading/trailing spaces
      console.log("Regex: " + email);

      // Check if the email matches the email pattern
      if (!emailPattern.test(email)) {
        setAreRecipientEmailsValid(false)
        // return `Invalid email address at index ${i + 1}: ${email}`;
      } else {
        setAreRecipientEmailsValid(true)

      }
    }
  };

  // ----------------------------------------------------------------
  // Get the sorted projects
  const sorted_projects = analysis ? analysis.projects.map(obj => obj.name).sort() : null;

  // Set up the state for the projects
  const [selectedProjects, setSelectedProjects] = useState([]);

  // Set handler for project changes
  const handleProjectSelection = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      target: { value },
    } = event;
    setSelectedProjects(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // ----------------------------------------------------------------
  // Set up the state for the personal note from the sender
  // ----------------------------------------------------------------
  const [personalNote, setPersonalNote] = useState('');

  // Set up the handler for changing of email address 
  const handlePersonalNoteChange = (event) => {
    setPersonalNote(event.target.value);
  };

  // ----------------------------------------------------------------
  // Set up the state for why Quoll note from the sender
  // ----------------------------------------------------------------
  const [whyQuoll, setWhyQuoll] = useState('');

  // Set up the handler for changing of email address 
  const handleWhyQuollChange = (event) => {
    setWhyQuoll(event.target.value);
  };

  // ----------------------------------------------------------------
  // Set up the state for the resume upload 
  // ----------------------------------------------------------------
  const [resume, setResume] = useState(null);

  // Set up the handler for changing of email address 
  const handleResumeUpload = (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile)
    if (selectedFile.type === 'application/pdf' || selectedFile.type === 'image/jpeg') {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        // console.log(base64String)
        // console.log(typeof base64String)
        setResume(base64String)
        // Now you have the file content as a base64 string.
      };

      reader.readAsDataURL(selectedFile);
    } else {
      // Invalid file type, show an error to the user.
      alert('Please select a valid PDF or JPG file.');
    }
    // Now you can work with the selected file.
  };

  // ----------------------------------------------------------------
  // Set up the state for why contacting Quoll
  // ----------------------------------------------------------------
  const formattedContactUsTopicsOfInterest = useRef('')

  const [contactUsTopicsOfInterest, setContactUsTopicsOfInterest] = useState({
    general_questions: false,
    partnerships: false,
    investment: false,
    model_access: false,
    careers: false,
    become_contributor: false,
    become_project_tester: false,
  });

  const { general_questions, model_access, partnerships, careers, investment, become_contributor, become_project_tester } = contactUsTopicsOfInterest;

  // Set up the handler for changing of the contact us topics
  const handleContactUsChange = (event) => {
    setContactUsTopicsOfInterest({
      ...contactUsTopicsOfInterest,
      [event.target.name]: event.target.checked,
    });
  };

  // Function for getting the list of keys for which the value is true (since that is what we give to the API)
  function getTrueKeys(inputObject) {
    const trueKeys = Object.keys(inputObject).filter(key => inputObject[key] === true);
    return trueKeys.join(', ');
  }

  useEffect(() => {
    formattedContactUsTopicsOfInterest.current = getTrueKeys(contactUsTopicsOfInterest)
  }, [contactUsTopicsOfInterest]);


  // ----------------------------------------------------------------
  // Set up the form check status
  // ----------------------------------------------------------------
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // ----------------------------------------------------------------
  // Handle form submit
  // ----------------------------------------------------------------
  const handleFormSubmit = () => {
    // Start with a const/ref for final_status that begins as true
    let final_status = true;

    // Check senderName
    if (senderName.length === 0) {
      final_status = false;
      setIsSenderNameValid(false)
    }

    // Check email and isEmailValid
    if (email.length === 0 || !isEmailValid) {
      final_status = false;
      setIsEmailValid(false)
    }

    // Check recipientEmails and areRecipientEmailsValid
    if ((purpose !== "careers_submission" && purpose !== "contact_us")) {
      if (recipientEmails.length === 0 || !areRecipientEmailsValid) {
        final_status = false;
        setAreRecipientEmailsValid(false)
      }
    }

    // Check that there is a resume if this is a careers submission
    if (purpose === "careers_submission") {
      if (!resume) {
        final_status = false;
        setAreRecipientEmailsValid(false)
      }
    }

    // If all conditions are true, log "Everything passes"
    if (final_status) {

      console.log("Everything for form is here!")
      // Turn the projects into a string
      const projects_string = selectedProjects.sort().join(", ")

      const input_for_api = {
        formType: purpose,
        senderName: senderName,
        email: email,
        recipientEmails: recipientEmails,
        selectedProjects: projects_string,
        personalNote: personalNote,
        url: page_url,
        address: address,
        climateRisks: climate_risks,
        whyQuoll: whyQuoll,
        resume: resume,
        contactUsTopicsOfInterest: formattedContactUsTopicsOfInterest.current
      }
      SendTransactionalEmail(input_for_api)
      RecordEvent("What Next Guidance", `Email Sent - ${purpose}`, user_status)
      setShowErrorMessage(false);
      handleOpen();
      setTimeout(() => {
        handleClose();
      }, 2000);
    } else {
      // If any condition is false, log "Fail"
      console.log("Fail");
      setShowErrorMessage(true);
      // You can add more logic here for handling the failure case
    }

    // You can return or perform additional actions based on final_status
  };

  // ----------------------------------------------------------------
  // Handle clearing the form if the user changes views (only clear the recipients and personal note)
  useEffect(() => {
    setRecipientEmails([]);
    setAreRecipientEmailsValid(true);
    setShowErrorMessage(false);
    setPersonalNote('');
  }, [purpose]);


  // ----------------------------------------------------------------

  const data_collection_sentence = "Complete the form below and we'll send data requests to folks who can help fill in the blanks. We recommend your real estate agent and even the home's owner."
  const project_sentence = "Complete the form below and we'll send an email to your contractor or other folks who help you with home improvement projects and alert them to the projects you are considering and why we recommended them."

  let intro_sentence

  switch (true) {
    case purpose === "data_collection":
      intro_sentence = data_collection_sentence;
      break;
    case purpose === "project_consultation":
      intro_sentence = project_sentence;
      break;
    default:
      chart_description = "We'll send details about this home, the data gaps we need to fill, and recommended projects to anybody you trust and are working with!"; // default color if quoll_score doesn't match any case
  }
  const form_input_width = '100%';

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    disablePortal: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      {/* <span className={"span-m text-align-center text-wrap-balance"}>{intro_sentence}</span> */}
      <div className={`form-frame`}>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, fullWidth: true },
          }}
          noValidate
          autoComplete="off"
        >

          <TextField
            style={{ width: form_input_width, fontFamily: "inherit" }}
            className={"form-text-frame"}
            required
            id="send-name"
            variant="filled"
            label="Your Name"
            error={!isSenderNameValid}
            value={senderName}
            onChange={handleSenderNameChange}
          />

          <TextField
            className={"form-text-frame"}
            style={{ width: form_input_width, fontFamily: "inherit" }}
            required
            variant="filled"
            id="sender-email"
            label="Your Email"
            error={!isEmailValid}
            value={email}
            onChange={handleEmailChange}
          />
          {purpose.includes("project_consultation") &&
            <>
              <FormControl sx={{ m: 1, width: form_input_width, zIndex: "999999" }}>
                <InputLabel ref={selectMenuRef} id="demo-multiple-checkbox-label">Select Projects of Interest</InputLabel>
                <Select
                  style={{ width: form_input_width, fontFamily: "inherit" }}
                  className={"select-options-box"}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  variant="filled"
                  value={selectedProjects}
                  onChange={handleProjectSelection}
                  input={<OutlinedInput label="Select Projects of Interest" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {sorted_projects.map((project) => (
                    <MenuItem key={project} value={project}>
                      <Checkbox checked={selectedProjects.indexOf(project) > -1} />
                      <ListItemText primary={project} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          }
          {(purpose !== "careers_submission" && purpose !== "contact_us") &&
            <>
              <TextField
                className={"form-text-frame"}
                style={{ width: form_input_width, fontFamily: "inherit" }}
                required
                variant="filled"
                id="recipient-emails"
                label="Recipient Email(s), Separated With a Comma"
                error={!areRecipientEmailsValid}
                value={recipientEmails}
                onChange={handleRecipientEmailInput}
              />
              <TextField
                className={"form-text-frame"}
                style={{ width: form_input_width }}
                multiline
                maxRows={4}
                variant="filled"
                id="sender-email"
                label="Personal Note / Any Details You'd Like to Add"
                // error={!isPasswordValid}
                value={personalNote}
                onChange={handlePersonalNoteChange}
              />
            </>
          }

          {purpose === "careers_submission" &&
            // <input type="file" id="resumeInput" accept=".pdf, .jpg, .jpeg" onChange={handleFileUpload} />
            <>
              <TextField
                className={"form-text-frame"}
                style={{ width: form_input_width }}
                multiline
                maxRows={4}
                variant="filled"
                id="why-quoll"
                label="Why Quoll? What makes you interested in working on this with us?"
                // error={!isPasswordValid}
                value={whyQuoll}
                onChange={handleWhyQuollChange}
              />
              <label for="resumeInput">Upload Resume*:  </label>
              <input type="file" id="resumeInput" accept=".pdf, .jpg, .jpeg" onChange={handleResumeUpload} />
            </>
          }
          {purpose == "contact_us" &&
            <>
              <FormControl className={"form-contact-us-topics-of-interest-frame"} sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Select Topics That Apply</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={general_questions} onChange={handleContactUsChange} name="general_questions" />
                    }
                    label="General Questions"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={partnerships} onChange={handleContactUsChange} name="partnerships" />
                    }
                    label="Partnerships"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={investment} onChange={handleContactUsChange} name="investment" />
                    }
                    label="Investment"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={model_access} onChange={handleContactUsChange} name="model_access" />
                    }
                    label="Access Our Models"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={careers} onChange={handleContactUsChange} name="careers" />
                    }
                    label="Job Opportunities at Quoll"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={become_contributor} onChange={handleContactUsChange} name="become_contributor" />
                    }
                    label="Become a Contributor / Publish on Quoll"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={become_project_tester} onChange={handleContactUsChange} name="become_project_tester" />
                    }
                    label="Become a Tester for New Quoll Tools and Features"
                  />
                </FormGroup>
              </FormControl>
              <TextField
                className={"form-text-frame"}
                style={{ width: form_input_width }}
                multiline
                maxRows={4}
                variant="filled"
                id="sender-email"
                label="Any Additional Details You'd Like to Add"
                // error={!isPasswordValid}
                value={personalNote}
                onChange={handlePersonalNoteChange}
              />
            </>
          }
        </Box>
        <div className={"clickable"} onClick={handleFormSubmit}>
          <StartButton text={"Send Email"} shadow_flag={true} />
        </div>
        {open &&
          <span className={`span-l text-align-center text-wrap-balance email-sent-message-${sentTextColor}`}>Sent!</span>
        }
        {showErrorMessage &&
          <span className={"span-xs error-message"}>Looks like you have a few errors to fix!</span>
        }
      </div>
    </>
  );
};
