import React, { useState, useEffect, useContext, useRef } from 'react';
import { ProjectCardResources } from '../../modules/ProjectCardResources/ProjectCardResources';
import { GetFilteredResources } from '../../services/GetFilteredResources';
import { GetFilteredResourcesBySearch } from '../../services/GetSearchFilteredResources';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuBookSharpIcon from '@mui/icons-material/MenuBookSharp';
import ThunderstormSharpIcon from '@mui/icons-material/ThunderstormSharp';
import HouseSharpIcon from '@mui/icons-material/HouseSharp';
import { GetAllResources } from '../../services/GetAllResources';
import { CacheResources, GetResourcesFromCache } from '../../services/CacheResources';
import { shuffleArray } from '../../services/ShuffleArray';
import { UserStatus } from '../../services/UserMgt';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import CancelIcon from '@mui/icons-material/Cancel';
import "./style.css";


export const ResourceView = () => {

  // --------------------------------------------------
  // Create refs for the Select components
  // --------------------------------------------------
  const selectResourceRef = useRef(null);
  const selectRiskRef = useRef(null);
  const selectHouseAreaRef = useRef(null);

  // --------------------------------------------------
  // Set the styling for the select components
  // --------------------------------------------------

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    disablePortal: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = createTheme({ palette: { primary: { main: "#D8A270", }, secondary: { main: '#D8A270', }, contrastThreshold: 4.5, }, });
  // --------------------------------------------------
  // Log the page view
  // --------------------------------------------------

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Resources";
    setPageTitle("Resources");
  }, []);

  // --------------------------------------------------
  // Create the resources master and the filtered resources master
  // --------------------------------------------------
  // Create the master resources + resources status
  const [resourcesMaster, setResourcesMaster] = useState(null);
  const [resourcesMasterStatus, setResourcesMasterStatus] = useState(false);

  // Create the filtered resources
  const [filteredResources, setFilteredResources] = useState(null);

  // --------------------------------------------------
  // Handling of Search
  // --------------------------------------------------
  // Create the search input state
  const [searchInput, setSearchInput] = useState('');

  // Function to handle search input changes
  const handleSearchInputChange = (event) => { setSearchInput(event.target.value); };

  // Function to handle search clear
  const handleSearchInputClear = (event) => { setSearchInput(''); };

  // Function to filter resources based on search term
  useEffect(() => {

    const search_filtered_resources = GetFilteredResourcesBySearch(resourcesMaster, searchInput);
    setFilteredResources(search_filtered_resources)

  }, [searchInput, resourcesMaster]);


  // --------------------------------------------------
  // Handling of Resource Type
  // --------------------------------------------------
  // Create the new resources type filter
  const [newResourceTypeFilterOptions, setNewResourceTypeFilterOptions] = useState(null);
  const [newResourceTypeFilter, setNewResourceTypeFilter] = useState([]);

  // Create the handler function that can take in the array of selections and set the new reources filter
  const handleNewResourceTypeFilter = (e) => { setNewResourceTypeFilter(e.target.value); };

  // Create the handler function for clearing the resource filter
  const handleNewResourceTypeFilterClear = (e) => { setNewResourceTypeFilter([]); };

  // --------------------------------------------------
  // Handling of Climate Risk Type
  // --------------------------------------------------
  // Create the new resources filter
  const [newRiskFilterOptions, setNewRiskFilterOptions] = useState(null);
  const [newRiskFilter, setNewRiskFilter] = useState([]);

  // Create the handler function that can take in the array of selections and set the new reources filter
  const handleNewRiskFilter = (e) => { setNewRiskFilter(e.target.value); };

  // Create the handler function for clearing the resource filter
  const handleNewRiskFilterClear = (e) => { setNewRiskFilter([]); };

  // --------------------------------------------------
  // Handling of House Area Type
  // --------------------------------------------------
  // Create the new resources filter
  const [newHouseAreaFilterOptions, setNewHouseAreaFilterOptions] = useState(null);
  const [newHouseAreaFilter, setNewHouseAreaFilter] = useState([]);

  // Create the handler function that can take in the array of selections and set the new reources filter
  const handleNewHouseAreaFilter = (e) => { setNewHouseAreaFilter(e.target.value); };

  // Create the handler function for clearing the resource filter
  const handleNewHouseAreaFilterClear = (e) => { setNewHouseAreaFilter([]); };

  // --------------------------------------------------
  // Handling Pagination
  // --------------------------------------------------
  // Set up the pagination details
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);
  const [numberPages, setNumberPages] = useState(1);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;

  // Change handler for when a user clicks a different page
  const handleChange = (event, value) => { setCurrentPage(value); };

  // --------------------------------------------------
  // Handling the changing states
  // --------------------------------------------------
  // Set up the paginated resources that will get displayed
  const [currentResources, setCurrentResources] = useState([]);

  // Get the resources
  useEffect(() => {

    const resources_cache = GetResourcesFromCache();

    if (resources_cache) {
      setResourcesMasterStatus(true);
      setResourcesMaster(resources_cache);
    } else {
      GetAllResources()
        .then(output => {
          const shuffledResources = shuffleArray(output);
          setResourcesMasterStatus(true);
          setResourcesMaster(shuffledResources);
          CacheResources(shuffledResources);
        })
    }

  }, []);

  useEffect(() => {
    if (resourcesMaster) {

      const filtered_resources_and_options = GetFilteredResources(resourcesMaster, newResourceTypeFilter, newRiskFilter, newHouseAreaFilter)
      setFilteredResources(filtered_resources_and_options.resources)

      setNewResourceTypeFilterOptions(filtered_resources_and_options.options.category)
      setNewRiskFilterOptions(filtered_resources_and_options.options.risk)
      setNewHouseAreaFilterOptions(filtered_resources_and_options.options.house)

      setCurrentPage(1)
    }

  }, [resourcesMaster, newResourceTypeFilter, newRiskFilter, newHouseAreaFilter]);
  // }, [resourcesMaster, resourceTypeFilter, riskTypeFilter, houseCategoryFilter]);

  // Set the total page numbers and based on that which resources to show

  useEffect(() => {
    if (filteredResources) {

      setNumberPages(Math.ceil(filteredResources.length / postPerPage))
      setCurrentResources(filteredResources.slice(firstPostIndex, lastPostIndex))
    }

  }, [filteredResources, currentPage]);

  // --------------------------------------------------
  // Creating and setting filter bar state
  // --------------------------------------------------
  const [showFilterBar, setShowFilterBar] = useState(false);
  console.log(showFilterBar)

  const handleFilterBarShowToggle = () => { setShowFilterBar((current) => !current); };


  // --------------------------------------------------
  // Static text
  // --------------------------------------------------
  // const resources_intro_text = "Homeownership comes with lots of challenges. That’s why we’ve put together how to guides, maintenance plans, and articles on a variety of topics that impact you as a home owner. Whether you’re new to homeownership, aspiring to own a home one day, or already a long-time owner, we’ve got something for you. Browse our entire collection or filter by the types of information you’re looking for, risk you’re facing, or a particular part of your home."
  const resources_intro_text = "Homeownership comes with lots of challenges. That’s why we’ve put together how to guides, maintenance plans, and articles on a variety of topics that impact you as a home owner. Whether you’re new to homeownership, aspiring to own a home one day, or already a long-time owner, we’ve got something for you."


  return (
    <div className={'page-frame-static'}>

      <div className={'page-container-static'}>
        <div className={"static-page-header-frame"}>
          <h1 >Helpful Resources</h1>
        </div>
        <p className={"resources-text-styles"}>{resources_intro_text}</p>
        <div className="resource-filter-section">
          <h2 className="resource-search-bar-headline">What Do You Want to Learn About?</h2>
          <div className="input-container">
            <div className="resource-filter-bar-container">
                <input
                  className="resource-search-bar box-shadow-standard"
                  type="text"
                  placeholder="Tell me more about..."
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
                {searchInput.length > 0 ? <CancelIcon className="cancel-icon" onClick={handleSearchInputClear} /> : null}
            </div>
          </div>
          {/* {searchInput.length > 0 ? <span onClick={handleSearchInputClear} className={"span-s resource-filter-item-clear box-shadow-standard"}>Clear Search</span> : null} */}

          <div className="resource-filter-container">
            <div className="resource-filter-bar-header" onClick={handleFilterBarShowToggle}>
              <span className={"span-xs resource-filter-bar-header-text"}>
                {showFilterBar ? "Hide Filter Options" : "See Filter Options"}
              </span>
              {showFilterBar ? <KeyboardDoubleArrowUpIcon className={""} /> : <KeyboardDoubleArrowDownIcon className={""} />}
            </div>
            {showFilterBar &&
              <div className="resource-filter-bar">

                <div className="resource-filter-item">
                  <MenuBookSharpIcon style={{ fontSize: "3rem" }} className={"resource-filter-item-icon"} />
                  <span className={"span-xs resource-filter-item-text"}>Type of Resource</span>
                  {newResourceTypeFilterOptions &&
                    <FormControl className={"resource-filter-selection-input"} sx={{ m: 1, width: "100%" }}>
                      <InputLabel ref={selectResourceRef} id="demo-multiple-checkbox-label">Select</InputLabel>
                      <Select
                        // style={{ width: form_input_width, fontFamily: "inherit" }}
                        style={{ fontFamily: "inherit" }}
                        className={"select-options-box"}
                        labelId="resource-type-multiple-checkbox-label"
                        id="resource-type-multiple-checkbox"
                        multiple
                        variant="filled"
                        value={newResourceTypeFilter}
                        onChange={handleNewResourceTypeFilter}
                        input={<OutlinedInput label="Select Resource Type" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {newResourceTypeFilterOptions.map((project) => (
                          <MenuItem key={project} value={project}>
                            <Checkbox checked={newResourceTypeFilter.indexOf(project) > -1} />
                            <ListItemText primary={project} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  {newResourceTypeFilter.length > 0 ? <span onClick={handleNewResourceTypeFilterClear} className={"resource-filter-item-clear"}>CLEAR</span> : null}
                </div>

                <div className="resource-filter-item">
                  <ThunderstormSharpIcon style={{ fontSize: "3rem" }} className={"resource-filter-item-icon"} />
                  <span className={"span-xs resource-filter-item-text"}>Specific Climate Risk</span>

                  {newRiskFilterOptions &&
                    <FormControl className={"resource-filter-selection-input"} sx={{ m: 1, width: "100%" }}>
                      <InputLabel ref={selectRiskRef} id="demo-multiple-checkbox-label">Select</InputLabel>
                      <Select
                        style={{ fontFamily: "inherit" }}
                        className={"select-options-box"}
                        labelId="risk-type-multiple-checkbox-label"
                        id="risk-type-multiple-checkbox"
                        multiple
                        variant="filled"
                        value={newRiskFilter}
                        onChange={handleNewRiskFilter}
                        input={<OutlinedInput label="Select Risk Type" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {newRiskFilterOptions.map((project) => (
                          <MenuItem key={project} value={project}>
                            <Checkbox checked={newRiskFilter.indexOf(project) > -1} />
                            <ListItemText primary={project} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  {newRiskFilter.length > 0 ? <span onClick={handleNewRiskFilterClear} className={"resource-filter-item-clear"}>CLEAR</span> : null}

                </div>

                <div className="resource-filter-item">

                  <HouseSharpIcon style={{ fontSize: "3rem" }} className={"resource-filter-item-icon"} />
                  <span className={"span-xs resource-filter-item-text"}>Specific Part of a Home</span>
                  {newHouseAreaFilterOptions &&
                    <FormControl className={"resource-filter-selection-input"} sx={{ m: 1, width: "100%" }}>
                      <InputLabel ref={selectHouseAreaRef} id="demo-multiple-checkbox-label">Select</InputLabel>
                      <Select
                        style={{ fontFamily: "inherit" }}
                        className={"select-options-box"}
                        labelId="HouseArea-type-multiple-checkbox-label"
                        id="HouseArea-type-multiple-checkbox"
                        multiple
                        variant="filled"
                        value={newHouseAreaFilter}
                        onChange={handleNewHouseAreaFilter}
                        input={<OutlinedInput label="Select HouseArea Type" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {newHouseAreaFilterOptions.map((project) => (
                          <MenuItem key={project} value={project}>
                            <Checkbox checked={newHouseAreaFilter.indexOf(project) > -1} />
                            <ListItemText primary={project} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  {newHouseAreaFilter.length > 0 ? <span onClick={handleNewHouseAreaFilterClear} className={"resource-filter-item-clear"}>CLEAR</span> : null}
                </div>
              </div>
            }
          </div>
        </div>

        {currentResources.length > 0 &&
          <>
            <ProjectCardResources resources={currentResources} style="dark" page="resources" />
            <div className="pagination-bar">
              <ThemeProvider theme={theme}>
                <Pagination color="primary" count={numberPages} page={currentPage} onChange={handleChange} />
              </ThemeProvider>
            </div>
          </>
        }

        {(currentResources.length === 0 && searchInput.length > 0) &&


          <div className="pagination-bar">
            <p>Hmm, looks like no resources match that search</p>
          </div>
        }
      </div>

    </div>
  );
}
