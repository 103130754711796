import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { StartButton } from "../StartButton/StartButton";
import "./style.css";


export const ContentFooter = () => {

  // Set up navigate for sending the user to the Contact Us page
  const navigate = useNavigate();

  // Set up the handler for going to Contact Us 
  const handleContactUsClick = (event) => {
    navigate("/contact");
  };

  // Text for the footer
  const got_questions = "Got questions? Ask Quoll"
  const disclaimer_header = "Disclaimer"
  const disclaimer_text = "Use at your own risk. The above text is intended for informational purposes only and does not constitute professional advice. The steps and instructions provided are based on general guidelines and may not apply to every situation or condition. The user is solely responsible for ensuring the safety and suitability of the materials, tools, and methods used in the project. The user should always check and follow the manufacturer's specific directions for the products they purchase."
  const terms_header = "Terms of Use"
  const terms_text = "Your use of the Services in any way means that you agree to all of these Terms of Use, and these Terms will remain in effect while you use the Services. Quoll Intelligence does not assume any responsibility or liability for any damages or losses that may result from the use of this text, the website or the information contained therein. Read the Terms of Use for more information."

  return (
    <>
      <div className="page-content-horizontal-divider-full"></div>
      <div className="content-footer-container">
        <span className="span-l got-questions-button">Got Questions?
        </span>
        <div className={"clickable"} onClick={handleContactUsClick}>
          <StartButton shadow_flag={true} text="Contact Us" />
        </div>
        <span className="span-s">{disclaimer_header}</span>
        <span className="span-xs">{disclaimer_text}</span>
        <span className="span-s">{terms_header}</span>
        <span className="span-xs">{terms_text}</span>
      </div>
    </>
  );
};
