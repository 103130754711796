import React from "react";
import { formatDollarAmount } from "../../services/FormatDollarAmounts";
import {
  AreaChart,
  Area,
  Legend,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer
} from "recharts";
import "./style.css";

export const AffordabilityChart = ({
  chart_data,
  tipping_point,
  income_15_pct
}) => {

  const lowest_value = chart_data[0].Insurance + chart_data[0].Utilities + chart_data[0].Maintenace
  const chart_lower_bound = Math.floor(lowest_value / 5000) * 5000

  const highest_value = chart_data[chart_data.length - 1].Insurance + chart_data[chart_data.length - 1].Utilities + chart_data[chart_data.length - 1].Maintenace
  const chart_upper_bound = Math.ceil(highest_value / 5000) * 5000

  // Custom Tooltip for the chart
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      const total_costs = payload.reduce((n, { value }) => n + value, 0);
      let chart_description;

      switch (true) {
        case label === 0:
          chart_description = "Estimate of the cost you are currently paying:";
          break;
        case label === 1:
          chart_description = `Estimate of the costs in ${label} year:`;
          break;
        case label > 1:
          chart_description = `Estimate of the costs in ${label} years:`;
          break;
        default:
          chart_description = "Estimate of Your Future Home Ownership Costs"; // default color if quoll_score doesn't match any case
      }

      return (
        <div className="tooltip-frame">

          <span className="span-m tooltip-description">{chart_description}</span>
          <div className="tooltip-frame-datapoints-container">

            {payload.map(item => {

              formatted_value = formatDollarAmount(item.value)

              return (<span id={item.name} className="span-s label">{`${item.name} : ${formatted_value}`}</span>)

            })}
          </div>

          <div className="tooltip-frame-datapoints-container">
            <div className="page-content-horizontal-divider-full-left-align"/>
            <span className="span-s tooltip-total-amount-text label">{`Total : ${formatDollarAmount(total_costs)}`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  const DataFormater = (number) => {
    if (number >= 1000000) {
      return '$' + (number / 1000000).toString() + 'M';
    } else if (number >= 1000) {
      return '$' + (number / 1000).toString() + 'K';
    } else {
      return '$' + number.toString();
    }
  }

  return (
    <ResponsiveContainer width="100%" height={300} wrapperStyle={{
      paddingLeft: "0px",
      align: "left",
    }}>

      <AreaChart
        width={"100%"}
        height={400}
        data={chart_data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 10,
          flex: 0,
        }}
      >
        <Legend
          verticalAlign="top"
          strokeWidth="5"
          iconType="circle"
          position="relative"
          margin={{ bottom: "120px" }}
          wrapperStyle={{
            paddingBottom: "10px"
          }}
        />
        <CartesianGrid
          stroke="0"
          strokeDasharray="1 1 1" />
        <XAxis dataKey="Year" >
          <Label
            wrapperStyle={{ position: 'relative' }}
            margin={{ top: 10, right: 0, left: 0, bottom: 10 }}
            value="Years in the Future"
            offset={-10}
            position="insideBottom" />
        </XAxis>
        <YAxis
          tickCount={7}
          domain={[chart_lower_bound, chart_upper_bound]}
          wrapperStyle={{ position: 'relative' }}
          type="number" tickFormatter={DataFormater}
          style={{
            fontSize: '.8rem',
          }}
        >
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <ReferenceLine x="7" stroke="red" />
        {tipping_point <= 10 &&
          <ReferenceLine
            isFront={true}
            y={income_15_pct}
            label={{
              value: '15% of Income',
              position: 'insideBottomRight',
              fontSize: "1.2rem",
              fontWeight: 'bolder'
            }}
            stroke="red"
            strokeDasharray="0"
            strokeWidth={3}
          />
        }
        <Area
          type="monotone"
          dataKey="Insurance"
          stackId="1"
          stroke="#368347"
          fill="#368347"
        />
        <Area
          type="monotone"
          dataKey="Utilities"
          stackId="1"
          stroke="#9590a7"
          fill="#9590a7"
        />
        <Area
          type="monotone"
          dataKey="Maintenace"
          stackId="1"
          stroke="#5f5ba3"
          fill="#5f5ba3"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
