import React, { useEffect, useRef, useContext, useState } from "react";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";

export const NowWhatGuidanceIcons = ({ image, guidancePath, handleClick, type, text }) => {

  const image_url = CreateCloudfrontUrl(image)
  
  const [active, setActive] = useState(false);

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
  };

  const handleIconClick = () => {
    handleClick(type)
  };

  return (
    <div className={`what-now-icon-frame ${active ? "box-shadow-hover" : "box-shadow-light"}`}
      style={{ backgroundImage: `url(${image_url})` }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleIconClick}
    >
      <div className={`what-now-icon-frame-image-overlay ${((guidancePath && guidancePath !== type) ? "what-now-icon-frame-image-overlay-not-selected" : null)}`}>
        <span className={"span-xl frame-130-image-overlay-text"}>{text}</span>
      </div>
    </div>
  );
};
