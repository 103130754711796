import React, { useContext, useState, useEffect } from "react";
import { HubProjectsCard } from "../HubProjectsCard";
import { HubProjectBar } from "../../components/HubProjectBar/HubProjectBar";
import { HubSavedProjectsCard } from "../HubSavedProjectsCard";
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { FormatSavedHomesForTable } from "../../services/FormatSavedHomesForTable";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { formatDollarAmount } from "../../services/FormatDollarAmounts";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { ProjectCardMeter } from "../../components/ProjectCardMeter/ProjectCardMeter";
import { SkusResources } from "../../screens/TabletDesktop/TabletDesktop";
import formatDiscounts from "../../services/formatDiscounts";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { HubNavigator } from "../OverviewHubV5/OverviewHubV5";
import "./style.css";

export const HubSavedProjectsCollection = ({
  projects,
  projectView,

}) => {

  console.log(projects)
  
  // Grab the hub naviation details from context
  const { setSelection } = useContext(HubNavigator);
  
  // Grab navigation details from context
  const { setCardToggleStatus, setCardType, setCardSelection } = useContext(UserExperienceNavigator)

  // Collect the analysis details from the context
  const analysis = useContext(AnalysisDetails);

  // Get the house parts from the analysis
  const house_categories = analysis ? analysis.house_category_risks : [];

  // Collect the SKUs and resources from the saved sku resources
  const skus_resources = useContext(SkusResources);

  // Get the discounts, company discounts, and companies from the sku resources
  const companies = skus_resources ? skus_resources.companies : [];
  const discounts = skus_resources ? skus_resources.discounts : [];
  const company_discounts = skus_resources ? skus_resources.company_discounts : [];

  const text_for_why_this_project = {
    payoff: "Large Financial Benefit",
    diy: "DIY Friendly",
    inexpensive: "Inexpensive to Do",
    disruption: "Low Disruption to Daily Life",
    sustainable: "Be More Sustainable",
    health: "Protects Health",
    roi: "High Payoff to Cost Ratio"
  }
  // --------------------------------------------------------
  // How to Render the Different Cells
  // --------------------------------------------------------
  // Project Description
  const [expandDescription, setExpandDescription] = useState(false);
  const handleExpandDescription = () => {
    setExpandDescription(true);
  }

  const renderProjectDescription = (params) => {
    return (
      <div className="hub-saved-project-description-container">
        <span className={`span-s ${!expandDescription && "hub-saved-project-description"}`}>{params.value}</span>
        {!expandDescription && <span className={`span-xs hub-saved-project-description-expand-text`} onClick={handleExpandDescription}>Expand</span>}
      </div>
    );
  };

  // Project project image, name, and open button

  const renderImage = (params) => {
    const [active, setActive] = useState(false);

    const handleMouseOver = () => {
      setActive(true);
    };

    const handleMouseOut = () => {
      setActive(false);
    };
    const project_image = CreateCloudfrontUrl(params.value.image);
    const project_name = params.value.name;
    const project_id = params.value.id;
    return (
      <div className="hub-saved-project-image-and-name-container">
        <div className="hub-saved-project-image-container">
          <img className={"hub-saved-project-image box-shadow-standard"} src={project_image} />
        </div>
        <span className={"span-m hub-saved-project-name-text"}>{project_name}</span>
        <div
          key={project_id}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          className={`hub-saved-project-open-container ${active ? "box-shadow-hover" : "box-shadow-standard"}`}
          onClick={() => {
            setCardSelection(project_id)
            setCardType("project")
            setSelection("projects")
            setCardToggleStatus(true)
          }}
        >
          <span className={"span-xs hub-saved-project-open-container"}>Open</span>
        </div>
      </div>
    );
  };

  // Project house category
  const renderHouseCategory = (params) => {
    const house_category_name = params.value;
    const house_category_details = house_categories.filter((category) => category.name === house_category_name);
    const house_category_image_url = house_category_details.length > 0 ? CreateCloudfrontUrl(house_category_details[0].icon) : null;

    return (
      <div className="hub-saved-project-house-category-container">
        {house_category_image_url &&
          <div className="hub-saved-project-house-category-image-container">
            <img className={"hub-saved-project-image"} src={house_category_image_url} />
          </div>
        }
        <span className={"span-s hub-saved-project-house-category-text"}>{house_category_name}</span>
      </div>
    );
  };

  // Project Discounts Icons
  const renderDiscountsIcons = (params) => {

    // Filter the master discounts to just the ones for this project
    const filtered_discounts = discounts.filter(discount => params.value.includes(discount.id))
    // Combine and format the discounts with company discounts and company info
    const processed_discounts = formatDiscounts(filtered_discounts, company_discounts, companies);
    // Step 1: Map through the array and extract icon values
    const extractedIcons = processed_discounts.discounts.map((obj) => obj.associated_entity_discounts.map((discount) => discount.icon));
    // Step 2: Flatten the array of arrays into a single array
    const flattenedIcons = [].concat(...extractedIcons);
    // Step 3: Remove duplicates by creating a Set and then converting it back to an array
    const uniqueIcons = [...new Set(flattenedIcons)];


    return (
      <>
        {
          processed_discounts.count > 0 ?
            <div className="hub-saved-project-image-and-name-container">
              {/* <span className={"span-m"}>{processed_discounts.count} Offers From:</span> */}
              <div className="hub-saved-project-discount-icons-container">
                {uniqueIcons.map(icon => {
                  icon_url = CreateCloudfrontUrl(icon);
                  return (
                    <div className="hub-saved-project-discount-icons-image-container">
                      <img className={"hub-saved-project-discount-icons-image"} src={icon_url} />
                    </div>
                  )
                })}
              </div>
            </div>
            :
            null
        }
      </>
    );
  };

  // Project value and cost dollar formatting
  const renderMoney = (params) => {
    const formatted_dollar_amount = formatDollarAmount(params.value);
    return (
      <span className={"span-m hub-saved-project-house-category-text"}>{formatted_dollar_amount}</span>
    );
  };

  // Project impact on bills and ltv checkmarks
  const renderProjectImpactCheckmarks = (params) => {

    const text_options = {
      affordability: { insurance: "Insurance Premiums", utility: "Utility Costs", maintenance: "General Maintenace", sudden_disaster: "Sudden Disaster Costs" },
      ltv: { curb: "Curb Appeal", operating: "Lower Operating Costs", insurability: "Insurability", resilience: "Resiliency Level" },
    }

    processed_text = []

    const formatted_text = params.value.map((x) => {
      if (x.includes('utility')) { processed_text.push(text_options.affordability.utility); }
      if (x.includes('insurance')) { processed_text.push(text_options.affordability.insurance); }
      if (x.includes('general')) { processed_text.push(text_options.affordability.maintenance); }
      if (x.includes('disaster')) { processed_text.push(text_options.affordability.sudden_disaster); }
      if (x.includes('curb')) { processed_text.push(text_options.ltv.curb); }
      if (x.includes('insurability')) { processed_text.push(text_options.ltv.insurability); }
      if (x.includes('resilience')) { processed_text.push(text_options.ltv.resilience); }
      if (x.includes('operating')) { processed_text.push(text_options.ltv.operating); }
    }
    );
    return (
      <div className="project-impact-checkmarks-container">
        {processed_text.map(text => {
          return (
            <div className="project-impact-checkmark-item">
              <CheckCircleSharpIcon className={`hub-saved-projects-checkmark`} />
              <span className={`span-s hub-saved-projects-checkmark-text`}>{text}</span>
            </div>
          )
        })}
      </div>
    );
  };

  // Project meters
  const renderMeter = (params) => {
    return (
      <ProjectCardMeter value={params.value} projectComparisonFlag={true} />
    );
  };

  // Project meters
  const renderDIYFriendly = (params) => {
    return (
      <span className={"span-m hub-saved-project-house-category-text"}>{params.value ? "Yes!" : "No"}</span>
    );
  };

  // Project who you hire icon and text
  const renderWhoYouHire = (params) => {
    const who_you_hire_name = params.value.name;
    const who_you_hire_icon = params.value.icon;
    const who_you_hire_icon_url = who_you_hire_icon ? CreateCloudfrontUrl(who_you_hire_icon) : null;

    console.log("Who You Hire: " + params.value.name)

    return (
      <div className="hub-saved-project-house-category-container">
        {who_you_hire_icon_url &&
          <div className="hub-saved-project-house-category-image-container">
            <img className={"hub-saved-project-image"} src={who_you_hire_icon_url} />
          </div>
        }
        <span className={"span-s hub-saved-project-house-category-text"}>{who_you_hire_name}</span>
      </div>
    );
  };

  const renderCellFunctions = {
    image: renderImage,
    description: renderProjectDescription,
    money: renderMoney,
    checkMarks: renderProjectImpactCheckmarks,
    meter: renderMeter,
    discounts: renderDiscountsIcons,
    houseCategory: renderHouseCategory,
    diyFriendly: renderDIYFriendly,
    whoYouHire: renderWhoYouHire
    // You can add more renderCell functions here for other data points
  };

  // --------------------------------------------------------
  // Set the formatted homes state and useEffect
  // --------------------------------------------------------
  const [formattedSavedHomes, setFormattedSavedHomes] = useState([]);

  useEffect(() => {

    if (projects) {
      const formatted_saved_homes = FormatSavedHomesForTable(projects, renderCellFunctions)
      setFormattedSavedHomes(formatted_saved_homes)
    }

  }, [projects]);
  console.log(formattedSavedHomes)

  const formatted_saved_homes = FormatSavedHomesForTable(projects, renderCellFunctions)

  return (
    <>
      {formatted_saved_homes.length > 0 &&
        <TableContainer className={"hub-saved-projects-table box-shadow-standard"} component={Paper}>
          <Table className={"box-shadow-standard"} style={{ minWidth: 650 }} aria-label="house data">
            <TableBody className={"box-shadow-standard"}>
              {formatted_saved_homes[0].dataPoints.map((dataPoint) => (
                <TableRow key={dataPoint.id}>
                  <TableCell width={100} className={`span-m hub-saved-projects-table-row-label ${dataPoint.id.includes("blank") && "hub-saved-projects-table-row-label-blank"}`} component="th" scope="row">
                    {dataPoint.id !== "image" ? dataPoint.label : null}
                  </TableCell>
                  {formatted_saved_homes.map((house, index) => (
                    <TableCell
                      key={`${house.id}-${dataPoint.id}`}
                      component="td"
                      className={`hub-saved-projects-table-row-datapoint-cell ${dataPoint.id.includes("blank") && "hub-saved-projects-table-row-label-blank"} ${index !== formatted_saved_homes.length - 1
                        ? "with-border" // Add a border class if not the last column
                        : ""
                        }`}
                    >
                      {dataPoint.renderCell ? dataPoint.renderCell(house.dataPoints.find((dp) => dp.id === dataPoint.id)) : house.dataPoints.find((dp) => dp.id === dataPoint.id).value}
                    </TableCell>
                  ))}

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  );

};


{/* <div className={'hub-saved-projects-collection-frame'}>
      {
          projects.map(project => {
            return (
              <HubSavedProjectsCard
                key={project.id}
                project={project}
              />
            )
          })
        }
      </div > */}