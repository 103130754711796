import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { UserStatus } from "../../services/UserMgt";
import { StartButton } from "../../components/StartButton/StartButton";
import "./style.css";


export const AboutUs = () => {

  // Set up navigate for sending the user to the Contact Us page
  const navigate = useNavigate();

  // Set up the handler for going to Contact Us 
  const handleContactUsClick = (event) => {
    navigate("/contact");
  };

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "About Us";
    setPageTitle("About Us");
  }, []);

  const intro_paragraph = "It seems like every day the news reminds us that climate change's impact is getting more severe. Well, we decided that we could not just sit idle and instead needed to do something about it. Quoll is about so much more than structures. It's about families and communities. We built this platform to help because when you understand your risks and are empowered to take action, that's when families become protected."
  const intro_image = "climate-change.jpg"
  const intro_image_url = CreateCloudfrontUrl(intro_image)

  const section_two_title = "Our Vision"
  const section_two_text = "We are actively working to be the change we want to see in our world. We believe that by putting decision-making power and choices into the hands of homeowners and buyers, we can work together to actually lower risk to homes and finances in a life-changing and meaningful way."

  const section_three_title = "Our Mission"
  const section_three_text = "Our mission is simple: connect the dots and provide free access and tools to give you everything needed to understand your specific, evolving risks and make the best decisions for your family today and into the future. When we do that, we help make you (and your community!) safer and more financially secure."
  const mission_image = "hurricane-family.jpg"
  const mission_image_url = CreateCloudfrontUrl(mission_image)


  const got_questions_text = "Got Questions?"

  return (
    <div className={'page-frame-static'}>

      <div className={'page-container-static'}>
        <div className={"static-page-header-frame"}>
          <h1 >About Us</h1>
        </div>

        <div className="page-content-container-static">

          <div className="methodology-flexbox-secton">
            <p className={"methodology-layer-text"}>{intro_paragraph}</p>
          </div>
          <div className="methodology-flexbox-secton">
            <img className={"methodology-layer-image box-shadow-standard"} src={intro_image_url} />
            <div className="methodology-flexbox-text">
              <h3 className={"header-center-text"}>{section_two_title}</h3>
              <p className={"methodology-layer-text"}>{section_two_text}</p>
            </div>
          </div>
          <div className="methodology-flexbox-secton methodology-flexbox-secton-reverse">
            <div className="methodology-flexbox-text">
              <h3 className={"header-center-text"}>{section_three_title}</h3>
              <p className={"methodology-layer-text"}>{section_three_text}</p>
            </div>
            <img className={"methodology-layer-image box-shadow-standard"} src={mission_image_url} />
          </div>
          <div className="page-content-container-row-static">
            <h3 className={"about-us-got-questions-text"}>{got_questions_text}</h3>
          </div>
          <div className={"clickable"} onClick={handleContactUsClick}>
            <StartButton text="Contact Us" shadow_flag={true} />
          </div>
        </div>
      </div>

    </div>
  );
};
