import React, { useEffect, useContext, useState } from "react";
import { RatingBox } from '../../components/RatingBox';
import { windowSize } from "../../App";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { CreateGoogleMapsStreetviewUrl } from "../../services/CreateGoogleMapsStreetviewUrl";
import { CreateGoogleMapsSatelliteViewUrl } from "../../services/CreateGoogleMapsSatelliteViewUrl";
import { CheckGoogleMapsStreetviewAvailability } from "../../services/CheckGoogleMapsStreetviewAvailability";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";

export const MapsScore = () => {

  const analysis = useContext(AnalysisDetails);

  const device = useContext(windowSize)

  const address = (analysis != null) ? analysis.address_details : null;

  const testing_google_streetview_image = CreateCloudfrontUrl("google-street-view.png")
  const testing_google_map_image = CreateCloudfrontUrl("google-map.png")

  const [streetviewURL, setStreetviewURL] = useState(null)
  const [isStreetviewMissing, setIsStreetviewMissing] = useState(false)

  // Determine if the streetview image is available
  useEffect(() => {
    if (address) {
      CheckGoogleMapsStreetviewAvailability(address.lat, address.long)
        .then(data => {
          // console.log(data)
          if (data.status == 'OK') {
            setStreetviewURL(CreateGoogleMapsStreetviewUrl(address.lat, address.long))
          } else {
            setStreetviewURL(testing_google_streetview_image)
            setIsStreetviewMissing(true)
          }

        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [address]);



  return (

    <div id="scroll-target" className={"report-score-and-maps-frame"}>
      {address &&
        <div
          className={`report-score-streetview ${device === 'mobile' ? 'report-score-streetview-mobile' : null} box-shadow-standard`}
          style={{
            // backgroundImage: `url(${testing_google_streetview_image})`,
            // backgroundImage: `url(${CreateGoogleMapsStreetviewUrl(address.lat, address.long)})`,
            backgroundImage: `url(${streetviewURL})`,
          }}
        >
          {isStreetviewMissing &&
          <div className="missing-streetview-image-overlay">
            <RatingBox />
            <span className="span-m missing-streetview-image-text">No Image Found</span>
          </div>
          }
          {!isStreetviewMissing &&
            <RatingBox />
          }
        </div>
      }
      {(address && device !== 'mobile') &&
        <div
          className={"report-score-satellite-view box-shadow-standard"}
          style={{
            // backgroundImage: `url(${testing_google_map_image})`,
            backgroundImage: `url(${CreateGoogleMapsSatelliteViewUrl(address.lat, address.long)})`,
          }}
        />
      }
    </div>
  );
};
