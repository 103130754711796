import React, {useEffect} from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import ReactGA from 'react-ga4';


export function RecordEvent(category, action, label) {
    ReactGA.event({
        category: category,
        action: action,
        label: label, 
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
      });
    // console.log("Sent Event For: " + category + " | " + action + " | " + label)

    return null;

};