import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";

export const ProjectCardAccordianLabor = ({
  project,
  data,
  table_header,
}) => {

  const labor_icon = 'https://d2dhzsfgxco53w.cloudfront.net/' + project.who_does_the_work_icon
  const diy_sentence = "This project is DIY friendly! With that in mind, you don't necessarily need to hire anybody to do this work, though some folks prefer to. If you do go that route, you are looking for a...."
  const professional_sentence = "This project often requires hiring a professional to do the work. If you are still interested in doing it yourself we have plenty of resources below. If you do hire somebody, you're looking for a..."

  const service_networks = [
    { name: "Angi", url: "https://www.angi.com/", icon: "angi.png" },
    { name: "Thumbtack", url: "https://www.thumbtack.com/", icon: "thumbtack.png" },
    { name: "HomeAdvisor", url: "https://www.homeadvisor.com/", icon: "homeadvisor.png" },
    { name: "Houzz", url: "https://www.houzz.com/", icon: "houzz.png" },
  ]

  const statement = project.diy_friendly ? diy_sentence : professional_sentence;
  // console.log(project);

  return (
    <div className={'project-card-accordian-labor-container'}>
      {/* <div className={'project-card-accordian-labor-frame'}> */}
        <div className={'project-card-accordian-labor-container'}>
          <p className="center-text">{statement}</p>
        </div>
        <div className={'project-card-accordian-labor-type-container box-shadow-standard'}>
          <div className="project-card-accordian-labor-type-icon-container">
            <img className={"project-card-accordian-labor-icon"} src={labor_icon} />
          </div>
          <p className="center-text">{project.who_does_the_work}</p>
        </div>
      {/* </div> */}
      {/* <div className="project-card-accordian-labor-vendors-frame"> */}
        <p>Here are some places you can find professionals in your area:</p>
        <div className="project-card-accordian-labor-vendors-grid">
          {service_networks.map(service => {

            const service_network_url = CreateCloudfrontUrl(service.icon)

            return (
              <div className="project-card-accordian-labor-vendor-container box-shadow-standard">
                <a href={service.url} target="_blank">
                  <img className={"project-card-accordian-labor-vendor-icon"} src={service_network_url} />
                </a>
              </div>
            )

          })}
        </div>

      {/* </div> */}

    </div>
  );
};
