import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { GetQuollLinks } from "../../services/GetQuollLinks";
import { windowSize } from "../../App";
import "./style.css";

export const Footer = () => {

  const deviceType = useContext(windowSize)
  // console.log(deviceTypeContext)
  
  // const [deviceType, setDeviceType] = useState('desktop');

  // function getDeviceType() {
  //   const width = window.innerWidth;
  //   if (width < 480) {
  //     return 'mobile';
  //   } else if (width >= 480 && width < 769) {
  //     return 'tablet';
  //   } else {
  //     return 'desktop';
  //   }
  // };
  
  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     setDeviceType(getDeviceType());
  //   };

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  // Set up navigate for if user is not signed in and tries to save project
  const navigate = useNavigate();

  // Get the footer links and social information
  const footer_links_and_socials = GetQuollLinks();

  const footer_links = footer_links_and_socials.links.filter(link => link.location === "both" || link.location === "footer");
  const social_links = footer_links_and_socials.social;
  const legal_links = footer_links_and_socials.legal;
  const copyright_text = footer_links_and_socials.copyright

  // Ge the last legal link so we can avoid the extra dot being added
  const last_link = legal_links[legal_links.length - 1]

  // Get the current year for the footer
  const currentYear = new Date().getFullYear();

  const handleFooterLinkClick = (link) => {
    navigate(link);
  };

  return (
    <div className={"footer-frame"}>

      {deviceType === 'mobile' &&
        <div className={"footer-logo-company-description-container"}>
          <div className={'footer-links-grid-wrapper'}>
            {footer_links.map(link => {
              return (
                <Link key={link.text} to={link.url}>

                  <span key={link.text} className={"span-s footer-links"}>{link.text}</span>
                </Link>
              )
            }
            )}

          </div>
        </div>
      }
      <div className={"footer-logo-company-description-container"}>
        <Link key={"footer-home-link"} className={"footer-logo-img-container"} to="/">
          <img className={"footer-logo-image"} alt="Image" src={require("../../assets/Quoll-Wordmark.svg")} />
        </Link>
        {deviceType !== 'mobile' &&
          <div className={'footer-links-grid-wrapper'}>
            {footer_links.map(link => {
              return (
                <Link key={link.text} to={link.url}>

                  <span key={link.text} className={"span-s footer-links"}>{link.text}</span>
                </Link>
              )
            }
            )}

          </div>
        }
        <div className={"footer-social-icons-container"}>
          {social_links.map(link => {
            return (
              <a key={link.url} href={link.url} target="_blank">
                <div key={link.url} className={"footer-social-icon-box"}>
                  <img key={link.url} className={'footer-social-icon'} src={link.img} />
                </div>
              </a>
            )
          }
          )}
        </div>
      </div>
      <div className={"footer-bottom-bar-frame"} >
        <span className={"span-xs footer-element-copyright-quoll-intelligence"}>
          {/* {copyright_text} */}
          {currentYear} Copyright <span className={"span-xs footer-element-copyright-quoll-intelligence"}>&#169;</span> | Quoll Intelligence
        </span>
        <div className={"footer-legal-links"}>

          {legal_links.map(link => {
            return (
              // <a key={link.url} href={link.url}>
              <>
                <Link key={link.text} to={link.url}>

                  <span key={link.text} className={"span-xs footer-legal-links-span"}>{link.text}</span>
                </Link>
                {link !== last_link &&
                  <span key={link.text + "-period"} className={"span-xs footer-legal-links-seperator"}>.</span>
                }
              </>
              // </a>
            )
          })}
        </div>
      </div>
    </div>
  );
};
