import React, { useEffect, useRef, useContext, useState } from "react";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const NowWhatButton = ({ handleClick }) => {

  // Get the device type
  const deviceType = useContext(windowSize)

  // Create the show text state
  const [showText, setShowText] = useState(false)

  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)

  // Create Image URL
  // const image = "plumbing-installation.jpg"
  // const image_url = CreateCloudfrontUrl(image)

  // Get the static text
  const static_text = StaticText()
  const play_with_data_text = static_text.hub.play_with_data
  const button_text = play_with_data_text.button_text
  console.log(button_text)

  const ready_to_take_action_text = "Play with the Data"

  const handlePlayWithDataButtonClick = () => {
    setCardType("user_form");
    setCardToggleStatus(true);
  }

  return (
    <div className={`box-shadow-standard what-now-button-frame what-now-button-frame-${deviceType}`}
      // onClick={handleClick}
      onClick={handlePlayWithDataButtonClick}
    >
      {deviceType === "desktop" &&
        <div className={`what-now-button-image-frame-${deviceType}`}>
          <EditNoteSharpIcon className="what-now-button-icon" />
          {/* <img className={`what-now-button-image what-now-button-image-${deviceType}`} src={image_url} /> */}
        </div>
      }
      <div className={`now-what-icon-text-container now-what-icon-text-container-${deviceType}`}>
        <span className={`span-xs text-align-center text-wrap-balance now-what-icon-text now-what-icon-text-${deviceType}`}>{button_text}</span>

      </div>
    </div>
  );
};
