import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp';
import { UserStatus } from "../../services/UserMgt";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { saveHouseProjectResource } from "../../services/SaveHouseProjectResource";
import { FormatHouseForSave } from "../../services/FormatHouseForSave";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import {ClickAwayListener} from '@mui/base/ClickAwayListener';
import { SocialShare } from "../SocialShare";
import "./style.css";

export const SaveShareBar = () => {

  // Set up navigate for if user is not signed in and tries to save house
  const navigate = useNavigate();

  // Redirect navigation if user is not designed
  const signInRedirect = "/sign-in";

  // Get the user id
  const { userId, userSavedHomes, setUserSavedHomes } = useContext(UserStatus);

  // Get the address id
  const analysis = useContext(AnalysisDetails);
  const addressId = analysis ? analysis.address_id : null;

  // Share Bar Toggle
  const [shareBarVisible, setShareBarVisible] = useState(false);

  // Handle Share Bar Toggle 
  const handleShareBarToggle = () => {
    setShareBarVisible((current) => !current);
    console.log("Share Bar Status: " + shareBarVisible)
  };

  // Determine if this home is saved
  const [isHomeSaved, setIsHomeSaved] = useState(false);

  const action = isHomeSaved ? "Remove Saved Home" : "Add Saved Home";

  const handleSaveHouseClick = () => {
    if (userId) {

      RecordEvent("Botton", action, "Signed-In User")

      const api_params = {
        type: "house",
        address_id: addressId,
        user_id: userId
      }

      saveHouseProjectResource(api_params)
        .then(output => output)
        .catch(error => {
          console.log(error);
        });

      // See if this home is already in the saved homes state
      const homeSavedCheck = userSavedHomes.some((house) => house.address_id === addressId);

      // If it isn't then we need to format the house and add it to the array
      if (!homeSavedCheck) {
        const formattedHouseForSave = FormatHouseForSave(analysis);
        const new_user_saved_homes = userSavedHomes.concat(formattedHouseForSave)
        setUserSavedHomes(new_user_saved_homes);

        // If it is then we need to remove it from the array
      } else {
        const updatedSavedHomes = userSavedHomes.filter((house) => house.address_id !== addressId);
        setUserSavedHomes(updatedSavedHomes);
      }

    } else {

      RecordEvent("Botton", "Save Home", "Not Signed-In User")

      // NEED TO ADD THE NAVIGATE TO SIGN IN IF THERE IS NO USER ID!!
      navigate(signInRedirect)
    }

  };

  useEffect(() => {
    const homeSavedCheck = userSavedHomes.some((house) => house.address_id === addressId);
    setIsHomeSaved(homeSavedCheck);
  }, [userSavedHomes, addressId]);

  const button_style = {
    // fontSize: 'var(--span-m-font-size)',
    fontSize: 'var(--span-l-font-size)',
    display: 'inherit',
  }

  return (

    <>
      <Button
        // defaultIcon={<ShareSharpIcon />}
        defaultIcon={"Share"}
        selectedIcon={"Share"}
        isSelected={shareBarVisible}
        eventHandler={handleShareBarToggle}
      />
      {shareBarVisible &&
          <SocialShare handleClickaway={handleShareBarToggle} />
      }

      <Button
        isSelected={isHomeSaved}
        eventHandler={handleSaveHouseClick}
        defaultIcon={<BookmarkBorderSharpIcon style={button_style} />}
        selectedIcon={<BookmarkAddedSharpIcon style={button_style} />}
      />
    </>
  );
};
