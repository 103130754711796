export function CacheUserResources(input) {

    localStorage.setItem("saved_resources", JSON.stringify(input));

    return null
}


export function GetUserResourcesFromCache() {

    const saved_resources = JSON.parse(localStorage.getItem("saved_resources")) || null;

    return saved_resources
}
