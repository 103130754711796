import React, { useState, useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { ClimateIcon } from "../../components/ClimateIcon";
import { ClimateBlurb } from "../../components/ClimateBlurb";
import { HubHouse } from "../HubHouse";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { CombineHouseAreasAndProjects } from "../../services/CombineHouseAreasAndProjects";
import { FinancialDatapoint } from "../../components/FinancialDatapoint";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const ClimateFactors = ({

  analysis_data,
  toggle,
  setToggle,
  cardType,
  setCardType,

}) => {

  // Get the location so we can reset selection and selected risk if it changes
  const location = useLocation();

  // Get the text for the page
  const static_text = StaticText()
  const climate_factors_text = static_text.hub.climate
  
  const title = climate_factors_text.title
  const select_risk_text = climate_factors_text.select_risk_text
  const about_area_headline = climate_factors_text.about_area_headline
  
  // Get the analysis from context
  const analysisContext = useContext(AnalysisDetails);

  // Create the selection state
  const [selection, setSelection] = useState(null);

  // Create the hover functionality
  const [hover, setHover] = useState(null)

  const addHover = (item) => {
    setHover(item);
  };
  const removeHover = () => {
    setHover(null);
  };

  // Create the analysis context state
  const [analysisData, setAnalysisData] = useState(analysisContext)

  useEffect(() => {
    if (analysisContext) {
      setAnalysisData(analysisContext)
    } 
  }, [analysisContext]);
  
  // Get the data
  const climate_risks_data = analysisData ? analysisData.climate.climate_scores : null;
  const selected_climate_risk_data = climate_risks_data ? climate_risks_data.filter(risk => risk.name === selection)[0] : null;
  const projects_data = analysisData ? analysisData.projects : null;
  const affected_house_areas_data = analysisData ? analysisData.house_category_risks : null;
  const climate_general_stats_data = analysisData ? analysisData.climate.climate_general_stats : null;
  const combined_areas_with_projects_data = (affected_house_areas_data != null && projects_data != null) ? CombineHouseAreasAndProjects(affected_house_areas_data, projects_data) : null;
  const filtered_areas_with_projects_data = selected_climate_risk_data ? affected_house_areas_data.filter(area => area.associated_climate_risks.includes(selected_climate_risk_data.name)) : combined_areas_with_projects_data;
  
  

  useEffect(() => {
    setSelection(null);
  }, [location]);
  // }, [window.location.pathname]);



  return (
    <div className={"hub-selection-frame"}>
      <div className={"hub-selection-title-container"}>
        <h2 className={"hub-selection-title-text"}>
          {title}
        </h2>
      </div>

      <div className={"climate-factors-icons-container"}>
        {climate_risks_data && climate_risks_data.map((risk, i) => (
        <div key={risk.name} onClick={() => selection == risk.name ? setSelection(null) : setSelection(risk.name)}>
          <ClimateIcon key={risk.name} risk={risk} selection={selection} addHover={addHover}removeHover={removeHover}/>
        </div>
        ))}
      </div>

      <div className={"select-a-climate-factor-container"}>

        {hover ? (
          <span className={"span-l select-a-climate-factor-text"}>
            {hover}
          </span>
        ) : selection == null ? (
          <span className={"span-l select-a-climate-factor-text"}>
            {select_risk_text}
          </span>)
          : (
            <span className={"span-l select-a-climate-factor-text clear-selection-text"} onClick={() => setSelection(null)}>
              Clear Selection
            </span>)
        }

      </div>

      {(selection && selected_climate_risk_data) ?
        <ClimateBlurb
          cardType={cardType}
          setCardType={setCardType}
          toggle={toggle}
          setToggle={setToggle}
          selected_risk={selected_climate_risk_data}
        />
        :
        null
      }
      {/* <div className="div-line-seperator" /> */}
      <HubHouse
        sorted_projects={filtered_areas_with_projects_data}
      />
      <div className="div-line-seperator" />
      <h3 className={"hub-financial-subsection-header"}>{about_area_headline}</h3>
      <div className={"hub-value-category-stats-grid"}>

        {climate_general_stats_data && climate_general_stats_data.map(statistic => {
          return (
            <FinancialDatapoint key={statistic.headline} datapoint={statistic} type="climate" />
          )
        })}
      </div>

    </div>

  );
};


// ATTEMPTED FIX WHERE WE USED STATES TO MANAGE
// // Create the relevant state variables
  // const [climateRisks, setClimateRisks] = useState(null);
  // const [projects, setProjects] = useState(null);
  // const [affectedHouseAreas, setAffectedHouseAreas] = useState(null);
  // const [climateGeneralStats, setClimateGeneralStats] = useState(null);

  // // Set the states that rely on the above states
  // const [selectedRisk, setSelectedRisk] = useState(null);
  // const [combinedAreasWithProjects, setCombinedAreasWithProjects] = useState(null);
  // const [filteredHouseAreas, setFilteredHouseAreas] = useState(null);

  // // Use Effect to track when the analysis changes
  // useEffect(() => {
  //   if (analysisContext) {

  //     // Set the climate general stats
  //     setClimateGeneralStats(analysis_data.climate.climate_general_stats);

  //     //Set the climate risks 
  //     setClimateRisks(analysis_data.climate.climate_scores);

  //     // Grab the different parts of the analysis that we care about
  //     const projects = analysis_data.projects;
  //     const affected_house_areas = analysis_data.house_category_risks;
  //     setAffectedHouseAreas(affected_house_areas)

  //     // Set the combined house areas with projects
  //     const combined_house_areas_with_projects = CombineHouseAreasAndProjects(affected_house_areas, projects)
  //     setCombinedAreasWithProjects(combined_house_areas_with_projects);
      
  //     // Set the initial filtered house areas before a selection
  //     setFilteredHouseAreas(combined_house_areas_with_projects)
  //   } else {
      
  //   }
    
  // }, [analysisContext]);

  // // Use Effect to track when the selection change and update the selected risk
  // useEffect(() => {
  //   if (selection) {
  //     const user_selected_risk = climateRisks.filter(risk => risk.name === selection)[0]
  //     setSelectedRisk(user_selected_risk);
  //     const filtered_house_areas_by_risk = affectedHouseAreas.filter(area => area.associated_climate_risks.includes(user_selected_risk.name))
  //     // setSelectedRisk(climateRisks.filter(risk => risk.name === selection)[0]);
  //     // const filtered_house_areas_by_risk = affectedHouseAreas.filter(area => area.associated_climate_risks.includes(selectedRisk.name))
  //     setFilteredHouseAreas(filtered_house_areas_by_risk)
  //   } else {
      
  //     setFilteredHouseAreas(combinedAreasWithProjects)
  //     console.log(combinedAreasWithProjects)
  //   }
    
  // }, [selection]);
  
  // useEffect(() => {
  //   if (selectedRisk) {
  //     const filtered_house_areas_by_risk = affectedHouseAreas.filter(area => area.associated_climate_risks.includes(selectedRisk.name))
  //     setFilteredHouseAreas(filtered_house_areas_by_risk)
  //   } else {
  //     setFilteredHouseAreas(combinedAreasWithProjects)
  //   }

  // }, [selectedRisk]);

// OLD VERSION WHERE IT WOULD GLITCH AND DATA WOULD DISAPPEAR WHEN THE USER MOVED BETWEEN HUB SECTIONS
  // const [filteredHouseAreas, setFilteredHouseAreas] = useState(null);

  // // If the analysis is there get the climate risks and filter the selected risk (assuming there is one)
  // const climate_risks = (analysis != null) ? analysis.climate.climate_scores : null;
  // const selected_risk = (climate_risks != null) ? climate_risks.filter(risk => risk.name === selection)[0] : null;
  // // If the analysis is there get the projects and filter by the selected risk (assuming there is one)
  // const projects2 = (analysis != null) ? analysis.projects : null;
  // // Get the affected house areas 
  // const affected_house_areas = (analysis != null) ? analysis.house_category_risks : null;
  // // Get the climate stats
  // const climate_general_stats = (analysis != null) ? analysis.climate.climate_general_stats : null;
  // // Combine the house areas with the projects
  // const combined_areas_with_projects = (affected_house_areas != null && projects2 != null) ? CombineHouseAreasAndProjects(affected_house_areas, projects2) : null;
  // // Create the filtered house area state

  // useEffect(() => {
  //   if (selected_risk) {
  //     const filtered_house_areas_by_risk = affected_house_areas.filter(area => area.associated_climate_risks.includes(selected_risk.name))
  //     setFilteredHouseAreas(filtered_house_areas_by_risk)
  //   } else {
  //     setFilteredHouseAreas(combined_areas_with_projects)
  //   }

  // }, [selected_risk]);