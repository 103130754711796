/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState, useEffect, useContext } from "react";
// import { SearchBox } from "../SearchBox";
import { SearchBar } from "../SearchBar";
// import { HeroImg } from "../../assets/quoll-hero-image.png";
import { windowSize } from "../../App";
import { userWindowSize } from "../../screens/HomePage/HomePage";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const Hero = () => {

  // const deviceType = useContext(userWindowSize)
  const deviceType = useContext(windowSize)

  // Get the static text and image file name
  const static_text = StaticText()
  
  const hero_image_filename = static_text.home.hero.image
  const hero_image_url = CreateCloudfrontUrl(hero_image_filename)
  const hero_description_paragraph = static_text.home.hero.description
  const hero_title_regular = static_text.home.hero.title_regular
  const hero_title_italic = static_text.home.hero.title_italic
  // hero_image_filename = "quoll-hero-image.png"

  
  // const headline = "Take control of your home's future with "
  // const headline_italic = "one search"
  // const description_1 = "See your home's unique risk profile, which parts of your home are threatened, how all of this affects the bills you're paying and the long-term value of your home, and what you can do about it. Nobody has ever connected the dots for you...till now."
  // const description = "A rapidly changing world is threatening your home and wallet. We’ve compiled the data, connected the dots and answer the important questions."

  const headline = "Climate Change Is Complicated. Protecting Your Home Doesn't Have to Be."
  const headline_italic = "translated"
  // const description_1 = "Climate risks impact which parts of your home break and how often, the bills you're paying, and even the long-term value of your home. Till now, nobody has ever connected the dots for you. Search for your home, your wallet will thank you."
  const description_2 = "We translated climate risk to real life. Tailored for every single home in America. All with one search."

  return (
    <>
      {deviceType === 'mobile' &&
        <div className="home-section-container-hero-mobile home-section-row-container">
          <div className="hero-bar-text-container text-container-mobile">
            {/* <img className="hero-bar-image-mobile" alt="Image" src={require("../../assets/quoll-hero-image.png")} /> */}
            <img className="hero-bar-image-mobile" alt="Image" src={hero_image_url} />
            <h1 className="hero-bar-headline mobile-text">
              {/* {headline} */}
              {hero_title_regular} <span style={{fontStyle: 'italic'}}>{hero_title_italic}</span>.
            </h1>
            <p className="hero-bar-paragraph mobile-text">
              {hero_description_paragraph}
              {/* {description_1}<br/><br/>{description_2} */}
            </p>
            <div className={"hero-search-bar-container-mobile"}>

              <SearchBar />
            </div>
          </div>

        </div>
      }
      {deviceType != 'mobile' &&
        <div className="home-section-container home-section-row-container">
          <div className="hero-bar-text-container text-container-desktop">
            <h1 className="hero-bar-headline">
              {/* {headline} <span className={"italic-text"}>{headline_italic}</span> */}
              {/* {headline} */}
              {hero_title_regular} <span style={{fontStyle: 'italic'}}>{hero_title_italic}</span>.
            </h1>
            <p className="hero-bar-paragraph">
              {hero_description_paragraph}
              {/* {description_1}<br/><br/>{description_2} */}
            </p>
            <div className="hero-search-bar-container-desktop">

              <SearchBar />
            </div>
          </div>
          <div className={"hero-bar-image-container"}>
            {/* <img className="hero-bar-image-desktop" alt="Image" src={require("../../assets/quoll-hero-image.png")} /> */}
            <img className="hero-bar-image-desktop" alt="Image" src={hero_image_url} />
          </div>
        </div>
      }
    </>
  );
};