import React from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";

export const LoadingGif = () => {

  // Create the loading gif url
  const loading_gif_filename = "quoll_loading.gif";
  const loading_gif_url = CreateCloudfrontUrl(loading_gif_filename);

  return (
    <>
      <div className="page-overlay"></div>
      <div
        className="analysis-loading-image-container"
        style={{
          backgroundImage: `url(${loading_gif_url})`,
        }}
      >
        <img className={"analysis-loading-image"} src={loading_gif_url} />
      </div>
    </>
  );
};
