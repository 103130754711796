import React, { useState, useContext } from "react";
import { TourStep } from "../TourStep/TourStep";
import { Scrollama, Step } from 'react-scrollama';
import { userWindowSize } from "../../screens/HomePage/HomePage";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { windowSize } from "../../App";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const Tour = () => {


  // Get the device
  const deviceType = useContext(windowSize)

  // Get the tour language
  const static_text = StaticText()
  const tour_text = static_text.home.tour
  const tour_steps = tour_text.tour_steps
  const first_step_image = CreateCloudfrontUrl(tour_steps[0].img)
  const headline_1 = tour_text.headline_1
  const headline_2 = tour_text.headline_2

  // Set up the current step state and the current step picture state
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [currentStepPicture, setCurrentStepPicture] = useState(first_step_image);

  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data.index);
    const current_step_picture = CreateCloudfrontUrl(data.img)
    setCurrentStepPicture(current_step_picture);
  };

  return (
    <div className="tour-frame home-section-container home-section-column-container">
      <h2 className={"tour-header"}>
        {/* Enter your home's address and <span className={`${deviceType === "mobile" ? "" : "no-wrap"}`}>see a unique <span className={'italic-text'}>home dashboard</span></span> */}
        {headline_1} <span className={'italic-text'}>{headline_2}</span>
      </h2>
      {deviceType !== "mobile" &&
        <div className="tour-container-desktop">

          <div className={"tour-text-container"}>

            {/* <Scrollama offset={0.3} onStepEnter={onStepEnter} debug> */}
            <Scrollama offset={0.4} onStepEnter={onStepEnter}>

              {tour_steps.map(tour_step => (
                <Step data={tour_step} key={tour_step.index}>
                  <div
                    style={{
                      margin: '0',
                      // border: '1px solid gray',
                      opacity: currentStepIndex === tour_step.index ? 1 : 0.2,
                    }}
                  >
                    <TourStep
                      context={tour_step.context}
                      header={tour_step.header}
                      description={tour_step.description}
                      questions1={tour_step.questions1}
                      question2={tour_step.question2}
                      button={tour_step.button}
                      whatPartsOfMyClassName="v2-tour-step"
                      userDevice={deviceType}
                    />
                  </div>
                </Step>
              ))}
            </Scrollama>
          </div>
          <div className={"tour-image-container"}>
            <img className={'tour-image drop-shadow-standard'} src={currentStepPicture} />
          </div>
        </div>
      }
      {deviceType === "mobile" &&
        <div className="tourcontainer-mobile">
          {tour_steps.map(tour_step => (
            <TourStep
              image={tour_step.img}
              button={tour_step.button}
              context={tour_step.context}
              header={tour_step.header}
              description={tour_step.description}
              questions1={tour_step.questions1}
              question2={tour_step.question2}
              whatPartsOfMyClassName="v2-tour-step"
              userDevice={deviceType}
            />
          )
          )
          }
        </div>
      }
    </div>
  );
};


// THIS SCROLLYTELLING WORKS BELOW - IF YOU NEED TO GO BACK TO START

{/* <div className="tour-frame">

<div style={{ margin: '0'}}>
  <div>

    <Scrollama offset={0.2} onStepEnter={onStepEnter} debug>
      {[1, 2, 3, 4].map((_, stepIndex) => (
        <Step data={stepIndex} key={stepIndex}>
          <div
            style={{
              margin: '20vh 0',
              border: '1px solid gray',
              opacity: currentStepIndex === stepIndex ? 1 : 0.2,
            }}
          >
            I'm a Scrollama Step of index {stepIndex}
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </Step>
      ))}
    </Scrollama>
  </div>
</div>
  <div className={"tour-image-container"}>
    I'm sticky. The current triggered step index is: {currentStepIndex}  !!!!!!
  </div>
</div> */}