export function FormatSearchAddress(address) {
    // Remove ", USA" from the end
    address = address.replace(", USA", "");
  
    // Remove all remaining commas
    address = address.replace(/,/g, "");
  
    // Replace blank spaces with "-"
    address = address.replace(/\s+/g, "-");
  
    return address;
  }
  