import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

export default ScrollToTop;

// export default function ScrollToTop({ children }) {
//   const { pathname } = useLocation();
  
//   useEffect(() => {
//     const canControlScrollRestoration = 'scrollRestoration' in window.history
//     if (canControlScrollRestoration) {
//       window.history.scrollRestoration = 'manual';
//     }

//     window.scrollTo(0, 0);
//   }, [pathname]);
  
//   return children;
// }