import React, { useContext } from "react";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import iconRightArrow from "../../assets/icons-right-arrow.png";
import "./style.css";

export const HubHouseProjects = ({

  project,
  device,
  location,
  key

}) => {

  
  // Grab the navigation context
  const { setCardToggleStatus, setCardType, setCardSelection } = useContext(UserExperienceNavigator)

  const formatted_project_name = project.name.replace(/\s+/g, '-');

  // Get lowercase risk level for global formatting
  const lower_risk = project.value.toLowerCase()

  return (
    <>
      {(device !== "desktop" && location === "overlay") &&
        <div key={key} className={`hub-house-category-container-dot box-shadow-light background-color-${lower_risk}-risk ${project.name}`} onClick={() => {

          setCardSelection(project.name)
          setCardType("house")
          setCardToggleStatus(true)

        }}>

          {/* <div className={`hub-house-category-risk-level ${project.value}`}/> */}
          {/* <div className={`hub-house-category-risk-level background-color-${lower_risk}-risk`} /> */}
        </div>
      }
      {(device === "desktop" && location === "overlay") &&
        <div key={key} className={`hub-house-category-container box-shadow-light ${formatted_project_name}`} onClick={() => {

          setCardSelection(project.name)
          setCardType("house")
          setCardToggleStatus(true)

        }}>

          {/* <div className={`hub-house-category-risk-level ${project.value}`}/> */}
          <div className={`hub-house-category-risk-level background-color-${lower_risk}-risk`} />

          <div className={"hub-house-category-text-container"}>

            <span className={"span-s hub-house-category-text-name"}>

              {project.name}

            </span>

            {(project.totalProjects > 0 || project.total_category_discounts > 0) &&
              <div className={"hub-house-category-text-projects"}>
                {project.totalProjects > 0 &&
                  <span className="span-xs hub-house-category-discounts-projects-text-container">
                    {project.totalProjects} {project.totalProjects === 1 ? 'Project' : 'Projects'}
                  </span>
                }
                {project.total_category_discounts > 0 &&
                  <span className="span-xs hub-house-category-discounts-projects-text-container">
                    {project.total_category_discounts} {project.total_category_discounts === 1 ? 'Discount' : 'Discounts'}
                  </span>
                }

              </div>
            }

          </div>

          <img className={"hub-house-category-arrow"} src={iconRightArrow} />

        </div>
      }
      {location === "list" &&
        <div className={"hub-house-category-container-list"} onClick={() => {

          setCardSelection(project.name)
          setCardType("house")
          setCardToggleStatus(true)

        }}>

          {/* <div className={`hub-house-category-risk-level ${project.value}`}/> */}
          <div className={`hub-house-category-risk-level background-color-${lower_risk}-risk`} />

          <div className={"hub-house-category-text-container"}>

            <div className={"span-s hub-house-category-text-name"}>

              {project.name}

            </div>

            <div className={"hub-house-category-text-projects-list"}>
              {project.totalProjects > 0 &&
                <span>
                  {project.totalProjects} {project.totalProjects === 1 ? 'Project' : 'Projects'}
                </span>
              }
              {(project.totalProjects > 0 && project.total_category_discounts > 0) &&
                <span className={'hub-house-category-text-projects-list-delineator'}>
                  |
                </span>
              }
              {project.total_category_discounts > 0 &&
                <span>
                  {project.total_category_discounts} {project.total_category_discounts === 1 ? 'Discount' : 'Discounts'}
                </span>
              }

            </div>

          </div>

          <img className={"hub-house-category-arrow"} src={iconRightArrow} />

        </div>
      }
    </>
  );
};
