
export function formatDollarAmount(amount) {
    const includeDecimals = false;  // Change this to false if you don't want to include decimal places
    // Check if the input is a string, if so try to convert it to a float
    if (typeof amount === 'string') {
        amount = parseFloat(amount);
        // If the amount is not a number after trying to parse it, return an error message
        if (isNaN(amount)) {
            return "Error: Input should be a number or a string representation of a number.";
        }
    }

    let roundedAmount = includeDecimals ? amount.toFixed(2) : Math.round(amount);
    let parts = roundedAmount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$" + parts.join(".");
}

// console.log(formatDollarAmount("9076"));  // Outputs: $9,076.00 if includeDecimals is true, $9,076 if false
// console.log(formatDollarAmount("175.50"));  // Outputs: $175.50 if includeDecimals is true, $176 if false
