import React, { useState, useEffect } from "react";
import { HubButton } from "../../components/HubButton";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import "./style.css";

export const HubButtonBar = ({

  navButtons,
  selection,
  setSelection,

}) => {

  const last_index = navButtons.length - 1;

  return (
    <div className="hub-selection-frame">
      <h3 className={"hub-financial-subsection-header"}>Where You Are on the Journey</h3>
      {/* <Breadcrumbs separator="›" aria-label="breadcrumb"> */}
        <div className={"hub-sections-buttons-bar"}>
          {navButtons.map((button, index) => {
            const is_last_index = (index === last_index) ? true : false;
            return (
              <HubButton
                key={button.type}
                button={button}
                setSelection={setSelection}
                selection={selection}
                lastItemFlag={is_last_index}
              />
            );
          })}
        </div>
      {/* </Breadcrumbs> */}

    </div>
  );

};
