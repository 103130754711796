import React, { useEffect, useRef, useContext } from "react";
import { ModalClimate } from "../ModalClimate/ModalClimate";
import { ModalHouse } from "../ModalHouse/ModalHouse";
import { ModalUserForm } from "../ModalUserForm";
import { ModalProject } from "../ModalProject/ModalProject";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
// import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { windowSize } from "../../App";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { UserAuthentication } from "../../screens/UserAuthentication";
import "./style.css";

export const CardContainer = () => {

  // Grab the navigation context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection, previousCardType, previousCardSelection, userAuthReportFlow, setUserAuthReportFlow, userAuthReturnUser, setUserAuthReturnUser } = useContext(UserExperienceNavigator)

  console.log(userAuthReportFlow)
  console.log(userAuthReturnUser)
  // Grab the user device
  const deviceType = useContext(windowSize)

  // Set up listeners for if the user hits escape or clicks outside the box
  const popupRef = useRef(null);

  const handleClickAway = () => {
    console.log("Yup, this is a clickaway!!")
    setCardToggleStatus(false);
    setUserAuthReportFlow(false)
    setUserAuthReturnUser(false)
  };

  // Set the document title
  useEffect(() => {
    if (cardToggleStatus) {
      RecordEvent("Modal", "Card View", cardType)
    }
  }, [cardToggleStatus, cardType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setCardToggleStatus(false)
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setCardToggleStatus(false)
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  console.log(userAuthReportFlow)
  console.log(userAuthReturnUser)

  return (
    <>
      <div className="card-background-overlay" ></div>
      <ClickAwayListener onClickAway={handleClickAway}>

        <div className={`modal-frame modal-frame-${deviceType}`}>
          <div
            className={`modal-exit-icon-container`}
            onClick={() => { setCardToggleStatus(false) }}
          >
            <CancelSharpIcon style={{ position: "absolute", top: "-21px", left: "-28px", zIndex: 1500 }} className={"card-container-x-icon"} />
          </div>
          {cardType === "climate" ? <ModalClimate selection={cardSelection} /> : null}
          {cardType === "house" ? <ModalHouse selection={cardSelection} /> : null}
          {cardType === "project" ? <ModalProject selection={cardSelection} /> : null}
          {cardType === "user_form" ? <ModalUserForm /> : null}
          {cardType === "sign_in" ?
            <UserAuthentication
              setCardToggleStatus={setCardToggleStatus}
              setCardType={setCardType}
              previousCardType={previousCardType}
              setCardSelection={setCardSelection}
              previousCardSelection={previousCardSelection}
              report_flow={userAuthReportFlow}
              return_user={userAuthReturnUser}
              setUserAuthReportFlow={setUserAuthReportFlow}
              setUserAuthReturnUser={setUserAuthReturnUser}
            />
            :
            null
          }
        </div>
      </ClickAwayListener>
    </>
  );
};
