import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValueItem } from "../ValueItem";
import { userWindowSize } from "../../screens/HomePage/HomePage";
import { windowSize } from "../../App";
import InsightsSharpIcon from '@mui/icons-material/InsightsSharp';
import { StaticText } from "../../services/StaticText";

import "./style.css";

export const ValueProp = () => {

  // Set up navigate for if user is not signed in and tries to save project
  const navigate = useNavigate();

  // const deviceType = useContext(userWindowSize)
  const deviceType = useContext(windowSize)

  // Get the text for the page
  const static_text = StaticText()
  const value_prop_text = static_text.home.value_prop

  const value_prop_items = value_prop_text? value_prop_text.items : [];
  const value_prop_headline_1 = value_prop_text? value_prop_text.headline_1 : "";
  const value_prop_headline_2 = value_prop_text? value_prop_text.headline_2 : "";
  const value_prop_cta = value_prop_text? value_prop_text.cta : "";

  // const value_prop_items = [
  //   {
  //     key: "understand",
  //     text: "See your home’s specific climate threats and the areas of the home most at risk",
  //     img: "quoll-value-prop-house.png"
  //   },
  //   {
  //     key: "learn",
  //     text: "Learn how that risk and the risk in your community is impacting your bills and the home’s long-term value",
  //     img: "quoll-value-prop-data.png"
  //   },
  //   {
  //     key: "act",
  //     text: "Browse recommended projects and maintenance you can do to protect your home and financial future",
  //     img: "quoll-value-prop-tools.png"
  //   },
  // ]

  const headline_1 = "Homeownership is already overwhelming."
  // const headline_2 = "We connect the dots so you don’t have to."
  const headline_2 = "We've boiled this down to what you actually need to know."

  const handleMethodologyClick = () => {
    navigate("/what-we-do");
  };

  return (
    <div className="value-prop home-section-container home-section-column-container">
      {/* <div className="value-prop home-section-container"> */}
      {/* <div className="message-2"> */}
      <h2 className="value-prop-title-container">
        {value_prop_headline_1} {deviceType !== "mobile" && <br />} {value_prop_headline_2}
      </h2>
      <div className="value-prop-items-bar">
        {value_prop_items.map(item => {
          return (
            <ValueItem key={item.key} text={item.text} icon={item.img} />
          )
        })}
        {/* {<ValueItem text={value_prop_items[0].text} icon={value_prop_items[0].img}  />} */}

      </div>
      <div className="value-prop-methodology-bar">
        <Link to={"/what-we-do"}>

          <p className="value-prop-methodology-text">
            <InsightsSharpIcon /> {value_prop_cta}
          </p>
        </Link>
      </div>
      {/* </div> */}
    </div>
  );
};
