import React, { useState, useContext } from "react";
// import { IconClimate } from "../IconClimate";
// import { IconHouseArea } from "../IconHouseArea";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl"
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";

import "./style.css";

export const Frame130 = ({
  icon,
  type,
  text,
  project,
  form_category,
  form_user_selection,
  setFormCategory,
}) => {

  // Grab the user navigation context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)

  const url = CreateCloudfrontUrl(icon)


  const [active, setActive] = useState(false);

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
  };

  return (
    <>
      {type === "form" &&
        <div
          className={`frame-130-property-home frame-130-property-home-form ${form_category === form_user_selection ? "box-shadow-hover frame-130-property-home-selected" : "box-shadow-standard"}`}
          style={{
            backgroundImage: `url(${url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '15vh',
          }}
          onClick={() => {
            if (form_category === form_user_selection) {
              setFormCategory(null);
            } else {
              setFormCategory(form_category);
            }
          }}
        >
          {/* <div className="frame-130-icons-container">
            <img className={"frame-130-icons-home"} src={url} />

          </div> */}
          <div className={`frame-130-image-overlay ${(form_user_selection && form_category !== form_user_selection ? "frame-130-image-overlay-text-not-selected" : null)}`}>

            <span className={`span-xl frame-130-image-overlay-text ${(form_user_selection && form_category !== form_user_selection ? "not-selected-text" : null)}`}>{text}</span>
          </div>
        </div >
      }
      {(type === "house" || type == "climate") &&
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          className={`frame-130-property-home ${active ? "box-shadow-hover" : "box-shadow-light"}`}
          onClick={() => {
            if (type === 'house') {
              setCardSelection(project.houseArea);
              setCardType(type);
            }
            if (type === 'climate') {
              setCardSelection(text);
              setCardType(type);
            }
          }}
        >
          <div className="frame-130-icons-container">
            <img className={"frame-130-icons-home"} src={url} />

          </div>
          <span className={"span-m frame-130-text-wrapper"}>{text}</span>
          {(type === "house" && project.total >0) ?
            <span className={"span-s frame-130-element-projects"}>{project.total} {project.total === 1 ? 'Project' : 'Projects'} </span> : null
          }

        </div >
      }
    </>
  );
};
