import React, { useState, useEffect, useContext } from "react";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { AffordabilityChart } from "../../components/AffordabilityChart";
import { FinancialDatapoint } from "../../components/FinancialDatapoint";
import { GradientBar } from "../../components/GradientBar";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const HubAffordability = () => {

    // Get the analysis from context
    const analysis = useContext(AnalysisDetails);

    // Get the address for the section title
    const address = analysis ? analysis.address_details.full_address.split(",")[0] : null;

    // Get the device type
    const deviceType = useContext(windowSize);

    // Get the affordability score
    const affordability_score = analysis.affordability.affordability_score

    // Get the chart data, tipping point, and available savings from the data
    const chart_data = analysis.affordability.chart.chart_data.slice(0, 11)
    const tipping_point = analysis.affordability.savings_wall_year
    const tipping_point_risk_flag = analysis.affordability.savings_wall_year_risk_mismatch
    // const tipping_point = 1
    const household_income = analysis.affordability.household_income_formatted
    const household_income_15_pct = analysis.affordability.household_income_15_pct

    // Get the affordability takeaways from the analysis
    const financial_general_stats = (analysis != null) ? analysis.affordability.stats : null;

    // Get the static text
    const static_text = StaticText()
    const affordability_text = static_text.hub.financial_impact.affordability
    console.log(affordability_text)

    // Get the description paragraph for this view in the hub
    const section_description = affordability_text.intro_paragraph

    // Get the rule of thumb title and text
    const rule_of_thumb_title = affordability_text.rule_of_thumb_title
    const rule_of_thumb_text = affordability_text.rule_of_thumb_text

    // Get the understanding data section title and description
    const understanding_data_section_title = affordability_text.understanding_data_title
    const understanding_data_section_description = affordability_text.understanding_data_description

    // Get the projected / modeled expenses section title and description
    const projected_expenses_title = affordability_text.projected_expenses_title
    const projected_expenses_description = affordability_text.projected_expenses_description
    const projected_expenses_conclusion = affordability_text.projected_expenses_conclusion
    
    // Get the assessment section title
    const assessment_section_title = affordability_text.assessment_title

    // Get the assessment tipping point sentences
    const tipping_point_greater_than_10_risk_flag = affordability_text.tipping_point_greater_than_10_risk_flag
    const tipping_point_greater_than_10 = affordability_text.tipping_point_greater_than_10
    const tipping_point_greater_than_5 = affordability_text.tipping_point_greater_than_5
    const tipping_point_less_than_5 = affordability_text.tipping_point_less_than_5


    // Did You Know Text
    const did_you_know_text = "Filing an insurance claim for Hurricane damage will increase your average insurance cost by an estimated $1000 per year."

    // Get the images and create the cloudfront urls
    const maintenance_image_filename = affordability_text.maintenance_image
    const insurance_image_filename = affordability_text.insurance_image
    const utilities_image_filename = affordability_text.utilities_image
    const taxes_image_filename = affordability_text.taxes_image
    const maintenance_image = CreateCloudfrontUrl(maintenance_image_filename)
    const insurance_image = CreateCloudfrontUrl(insurance_image_filename)
    const utilities_image = CreateCloudfrontUrl(utilities_image_filename)
    const taxes_image = CreateCloudfrontUrl(taxes_image_filename)

    // Get the gradient padding amount
    const gradient_padding = `${(25.1 - tipping_point) / 25 * 100}%`;
    // console.log(gradient_padding);

    let affordability_takeaway;

    if (tipping_point > 10 && tipping_point_risk_flag) {
        affordability_takeaway = tipping_point_greater_than_10_risk_flag
    } else if (tipping_point > 10 && !tipping_point_risk_flag) {
        affordability_takeaway = tipping_point_greater_than_10
    } else if (tipping_point >= 5) {
        affordability_takeaway = tipping_point_greater_than_5
    } else if (tipping_point < 5) {
        affordability_takeaway = tipping_point_less_than_5
    } else {
        affordability_takeaway = tipping_point_greater_than_5
    }

    const [didYouKnowToggle, setDidYouKnowToggle] = useState(false)

    const handleToggle = () => {
        setDidYouKnowToggle((current) => !current);
    };

    const anchorTarget = document.getElementById('assessment-section');

    const handleScrollToAssesmentClick = event => {
        event.preventDefault();
        // anchorTarget.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center", offset: "-10px" })
        anchorTarget.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
    };

    return (

        <div className={`hub-finaicial-section-frame box-shadow-light affordability-hub-frame-${deviceType}`}>

            <h3 className={'hub-financial-subsection-header'}>
                Could Climate Risk Make {address} Unaffordable?
            </h3>
            <div className="value-hub-intro-container">
                <div className="value-hub-condition-bills-container">
                    <div className="value-hub-condition-bills-images-container">
                        <div className="value-hub-condition-bills-image">
                            <div className="value-hub-condition-image-container">
                                <img className={"value-hub-condition-image"} src={maintenance_image} />
                            </div>
                            <span className={"span-s italic-text hub-finance-icons-text"}>Maintenance + Sudden Fixes</span>
                        </div>
                        <div className="value-hub-condition-bills-image">
                            <div className="value-hub-condition-image-container">
                                <img className={"value-hub-condition-image"} src={insurance_image} />
                            </div>
                            <span className={"span-s italic-text hub-finance-icons-text"}>Insurance</span>
                        </div>
                        <div className="value-hub-condition-bills-image">
                            <div className="value-hub-condition-image-container">
                                <img className={"value-hub-condition-image"} src={utilities_image} />
                            </div>
                            <span className={"span-s italic-text hub-finance-icons-text"}>Utilities</span>
                        </div>
                        <div className="value-hub-condition-bills-image">
                            <div className="value-hub-condition-image-container">
                                <img className={"value-hub-condition-image"} src={taxes_image} />
                            </div>
                            <span className={"span-s italic-text hub-finance-icons-text"}>Property Taxes</span>
                        </div>

                    </div>
                    <p className={"financial-section-text"}>{section_description}</p>
                </div>
                <div className={'affordability-hub-title-container box-shadow-standard'}>
                    <h4 className={'affordability-hub-title-text'}>
                        {rule_of_thumb_title}
                    </h4>
                    <span className={'hub-financial-section-keep-in-mind-text'}>
                        {rule_of_thumb_text}
                    </span>
                </div>
            </div>

            <div className="page-content-horizontal-divider-full"></div>
            <div className={"affordability-hub-risk-container"}>
                <h3 className={"hub-financial-subsection-header"}>
                    {understanding_data_section_title}
                </h3>
                <p className={'affordability-hub-title-paragraph'}>{understanding_data_section_description}</p>
                <div className="hub-value-category-stats-grid">
                    {financial_general_stats &&
                        financial_general_stats.map(statistic => {
                            return (
                                <FinancialDatapoint key={statistic.headline} datapoint={statistic} />
                            )
                        })
                    }
                </div>
            </div>
            <div className="page-content-horizontal-divider-full"></div>

            <div id="assessment-section" className={"affordability-hub-risk-container"}>
                <h3 className={"hub-financial-subsection-header"}>
                    {assessment_section_title}
                </h3>
                <p className={'affordability-hub-title-paragraph'}>
                    {affordability_takeaway}
                </p>
                <GradientBar tipping_point={tipping_point} text={affordability_score} tipping_point_flag={tipping_point_risk_flag} />

                <div className="assumed-income-container">
                    <span className={"span-m affordability-hub-risk-label-context-text"}>Based on an assumed household income of: {household_income}</span>
                </div>
            </div>
            <div className="page-content-horizontal-divider-full"></div>
            <div className={"affordability-hub-risk-container"}>
                <h3 className={"hub-financial-subsection-header"}>
                    {projected_expenses_title}
                </h3>
                <p className={"affordability-hub-title-paragraph"}>{projected_expenses_description}</p>
                <div className={'affordability-hub-chart-container'}>
                    <AffordabilityChart
                        chart_data={chart_data}
                        tipping_point={tipping_point}
                        income_15_pct={household_income_15_pct}
                    />
                </div>
                <p className={"affordability-hub-title-paragraph break-spaces"}>{projected_expenses_conclusion}</p>
            </div>
        </div>


    );

};
