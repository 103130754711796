// function formatDiscounts(discountTypes, companyDiscounts, companies) {
//     const combinedDiscounts = discountTypes.map((discountType) => {
//       const associatedIds = discountType.associated_entity_discounts;
//       const filteredDiscounts = companyDiscounts
//         .filter((companyDiscount) => associatedIds.includes(companyDiscount.id))
//         .map((filteredDiscount) => {
//           const matchingCompany = companies.find(
//             (company) => company.id === filteredDiscount.entity_id
//           );

//           // Merge the matching company information onto the filtered discount
//           return {
//             ...filteredDiscount,
//             ...matchingCompany,
//           };
//         });

//       return {
//         ...discountType,
//         associated_entity_discounts: filteredDiscounts,
//       };
//     });
//     return combinedDiscounts;
//   }

//   export default formatDiscounts;

function formatDiscounts(discountTypes, companyDiscounts, companies) {
    let totalCount = 0; // Initialize the global count variable

    const combinedDiscounts = discountTypes.map((discountType) => {
        const associatedIds = discountType.associated_entity_discounts;
        const filteredDiscounts = companyDiscounts
            .filter((companyDiscount) => associatedIds.includes(companyDiscount.id))
            .map((filteredDiscount) => {
                totalCount++; // Increment the global count for each filtered discount

                const matchingCompany = companies.find(
                    (company) => company.id === filteredDiscount.entity_id
                );

                // Merge the matching company information onto the filtered discount
                return {
                    ...filteredDiscount,
                    ...matchingCompany,
                };
            });

        return {
            ...discountType,
            associated_entity_discounts: filteredDiscounts,
        };
    });

    return {
        count: totalCount,
        discounts: combinedDiscounts,
    };
}

export default formatDiscounts;
