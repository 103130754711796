import React, { useContext } from "react";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";

import "./style.css";

export const ProjectsListFrame80 = ({

  risk,

}) => {

  const analysis = useContext(AnalysisDetails);
  const climate_risks = analysis.climate.climate_scores;
  const risk_level_filtered = climate_risks.filter(potential => potential.name === risk);
  const risk_level = risk_level_filtered.length > 0 ? risk_level_filtered[0].value : "Medium" ;

  const risk_level_lowercase = risk_level.toLowerCase();
  // console.log(risk_level_lowercase);

  return (
    <div className={"risk-level-indicator-frame box-shadow-light"}>
    
      <div className={`risk-level-indicator-ellipse background-color-${risk_level_lowercase}-risk`}/>
      
      <span className={"span-xs risk-level-indicator-rating-text"}>{risk}</span>
    
    </div>
  );
};
