import React, { useState } from "react";
import { ProjectCardAccordianHeader } from "../../components/ProjectCardAccordianHeader/ProjectCardAccordianHeader";
import { ProjectCardAccordianTable } from "../../components/ProjectCardAccordianTable";
import { ProjectCardAccordianLabor } from "../../components/ProjectCardAccordianLabor";
import { ProjectCardResources } from "../ProjectCardResources/ProjectCardResources";
import { ProjectCardMeter } from "../../components/ProjectCardMeter/ProjectCardMeter";
import { ProjectAccordianValue } from "../../components/ProjectAccordianValue";
import { ProjectCardDiscounts } from "../../components/ProjectCardDiscounts/ProjectCardDiscounts";
import { RiskStat } from "../../components/RiskStat";
import { Frame130 } from "../../components/Frame130";
import { HubProjectsCard } from "../HubProjectsCard";
import "./style.css";

export const ProjectCardAccordian = ({
  style,
  accordian_header,
  table_header,
  skus,
  type,
  project,
  resources,
  discounts,
  default_toggle_status = false,
  facts,
  related,
  related_type
}) => {

  const [isActive, setIsActive] = useState(default_toggle_status);
  // console.log(project);

  const accordian_view = () => {

    if (type === 'skus') {

      return (
        <ProjectCardAccordianTable
          table_header={table_header}
          sku_list={skus}
        />
      )
    } else if (type === 'labor') {
      return (
        <ProjectCardAccordianLabor
          // table_header={table_header}
          project={project}
        />
      )
    } else if (type === 'resources') {
      return (
        <div className={"project-card-discount-frame"}>
          <ProjectCardResources
            style="light"
            resources={resources}
          />
        </div>
      )
    } else if (type === 'complexity') {
      return (
        <div className={'meter-bar-frame'}>
          {project.meters.map(meter => (
            <ProjectCardMeter
              headline={meter.headline}
              value={meter.value}
            />
          ))}

        </div>
      )
    } else if (type === 'value') {
      return (
        <ProjectAccordianValue project={project} />
      )
    } else if (type === 'facts') {
      return (
        <div className={"modal-climate-stats-frame"}>
          {facts.map(stat => (
            <RiskStat key={stat.stat} stat={stat} />
          ))}
        </div>
      )
    } else if (type === 'related_house') {
      return (
        <div className={"modal-climate-stats-frame"}>
          <div className={"modal-selector-boxes-grid"}>
            {related.map(project => (
              <Frame130
                key={project.houseArea}
                project={project}
                type={related_type}
                icon={project.icon}
                text={project.houseArea}
                value={project.value}
              />
            ))}
          </div>
        </div>
      )
    } else if (type === 'related_climate') {
      return (
        <div className={"modal-climate-stats-frame"}>
          <div className={"modal-selector-boxes-grid"}>
            {related.map(risk => (
              <Frame130
                key={risk.houseArea}
                type={related_type}
                icon={risk.icon}
                text={risk.houseArea}
              // project={project}
              // value={project.value}
              />
            ))}
          </div>
        </div>
      )
    } else if (type === 'discounts') {
      return (
        <ProjectCardDiscounts
          discounts={discounts}
        />
      )
    } else if (type === 'projects') {
      return (
        <div className={"modal-climate-stats-frame"}>
          <div className="project-cards-frame">
            {project.map(individual_project => (
              <HubProjectsCard key={individual_project.id} project={individual_project} />
            ))}
          </div>
        </div>
      )
    }
  };

  return (
    <div className={'project-card-accordian-container box-shadow-light'}>
      <ProjectCardAccordianHeader
        isActive={isActive}
        setIsActive={setIsActive}
        headline={accordian_header.headline}
        description={accordian_header.description}
        value={accordian_header.value}
      />

      {isActive && accordian_view()}


    </div>

  );
};
