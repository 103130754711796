import React, { useState, useEffect, useContext } from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StartButton } from "../../components/StartButton/StartButton";
import { RecordPageView } from "../../services/HandleGoogleAnalytics";
import { UserStatus } from "../../services/UserMgt";
import { StartButton } from "../../components/StartButton/StartButton";
import { SendToStakeholder } from "../../forms/SendToStakeholder";
import { windowSize } from "../../App";
import "./style.css";


export const ContactUs = () => {

  // Get the window size / device type
  const deviceType = useContext(windowSize)

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Contact";
    setPageTitle("Contact");
  }, []);

  return (
    <div className={'page-frame-static'}>

      <div className={'page-container-static'}>
        <div className={"static-page-header-frame"}>
          <h1 className={'header-center-text'}>Contact Us</h1>
        <div className="careers-resume-drop-form box-shadow-standard">
          <SendToStakeholder purpose="contact_us" />
        </div>
        </div>
      </div>

    </div>
  );
};
