import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StartButton } from "../../components/StartButton/StartButton";
import { RecordPageView } from "../../services/HandleGoogleAnalytics";
import { UserStatus } from "../../services/UserMgt";
import "./style.css";


export const Methodology = () => {

  // Set up navigate for sending the user to the Contact Us page
  const navigate = useNavigate();

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);
  
  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "What We Do";
    setPageTitle("What We Do");
  }, []);

    // Set up the handler for going to Contact Us 
    const handleContactUsClick = (event) => {
      navigate("/contact");
    };


  const value_intro_paragraph = "Climate risk is an incredibly complex topic, and that's before modeling out the impact! Our goal is to take all of that complexity and turn it into actionable intelligence, specific to you and your home. Rather than bore you with the details, let's explain at a high level what we're doing."

  const context_intro_paragraph = "In order to determine your climate risks and the impact of those risks, we need to think about the problem as layers. Only once all of those elements are stacked up, weighed against each other, do we have the complete picture."

  const context_base_layer_image = "sudden-costs-q-video.jpg"
  const context_base_layer_text = "We start with your specific house, literally down to the latitude and longitude. What are the risks? What is going to break? What does that mean for your bills? What is actually going to cost you money?"

  const context_neighborhood_layer_image = "snow-risk.jpg"
  const context_neighborhood_layer_text = "Then we layer in the homes around you. Don't forget, their prepardness impacts you financially, both your bills as well as home value!"

  const context_economy_layer_image = "lightning-risk.jpg"
  const context_economy_layer_text = "Next we layer in what climate is doing to the local economy. If jobs disappear or your government has to raise taxes, you better believe that is going to hit your wallet."

  const context_take_action_layer_image = "install-roof-vents-video.png"
  const context_take_action_layer_text = "Finally, we ask, so what can we do about this? We model out all of the different projects and other tools you have at your disposal."

  const context_conclusion_sentence = "Each analysis takes all of these layers into account to paint you a complete picture of your risks, the impact of those risks, and your options. Remember, there is always something you can do to protect yourself!"

  const data_section_conclusion = "Our datasets are constantly evolving, both as we add more types of data to our analysis, but also as our analysis and learnings improve. This ensures we provide the best insights possible!"

  const still_have_questions = "That's ok! Millions of datapoints and 30K+ lines of code and data models are a lot to summarize. Just drop us a line and we can schedule time to tell you more."

  const what_we_do_image = CreateCloudfrontUrl("what-we-do-wide-captions-bigger-font.png")

  return (
    <div className={'page-frame-static'}>

      <div className={'page-container-static'}>
        <div className={"static-page-header-frame"}>
          {/* <h1 className={"resources-text-styles"}>Resources</h1> */}
          <h1 className={'header-center-text'}>Datapoints Are Good, Answers Are Better</h1>
        </div>
        <div className="page-content-container-static">
          <p className={"resources-text-styles"}>{value_intro_paragraph}</p>
        </div>
        {/* <div className="page-content-horizontal-divider"></div> */}
        <div className="page-content-container-static">
          <h2 className={'header-center-text'}>We Start With Lots of Data and Data Science</h2>
          <div className="what-we-do-icons-container">
            <img className={"what-we-do-icons-image "} alt="Image" src={what_we_do_image} />
          </div>
          <p className={"resources-text-styles"}>{data_section_conclusion}</p>
        </div>
        <div className="page-content-horizontal-divider" />
        <div className="page-content-container-static">
          <h2 className={'header-center-text'}>How the Analysis Works</h2>
          <p className={"resources-text-styles"}>{context_intro_paragraph}</p>
          <div className="methodology-flexbox-secton">
            {/* <div className="page-content-container-flexbox image-container-flexbox"> */}
            <img className={"methodology-layer-image box-shadow-standard"} src={CreateCloudfrontUrl(context_base_layer_image)} />
            {/* </div> */}
            <div className="methodology-flexbox-text">
              <h3 className={'header-center-text'}>Layer 1: Your House</h3>
              <p className={"methodology-layer-text"}>{context_base_layer_text}</p>
            </div>
          </div>
          <div className="methodology-flexbox-secton  methodology-flexbox-secton-reverse">
            <div className="methodology-flexbox-text">
              <h3 className={'header-center-text'}>Layer 2: The Neighborhood</h3>
              <p className={"methodology-layer-text"}>{context_neighborhood_layer_text}</p>
            </div>
            {/* <div className="page-content-container-flexbox image-container-flexbox"> */}
            <img className={"methodology-layer-image box-shadow-standard"} src={CreateCloudfrontUrl(context_neighborhood_layer_image)} />
            {/* </div> */}
          </div>
          <div className="methodology-flexbox-secton">
            {/* <div className="page-content-container-flexbox image-container-flexbox"> */}
            <img className={"methodology-layer-image box-shadow-standard"} src={CreateCloudfrontUrl(context_economy_layer_image)} />
            {/* </div> */}
            <div className="methodology-flexbox-text">
              <h3 className={'header-center-text'}>Layer 3: The Local Area</h3>
              <p className={"methodology-layer-text"}>{context_economy_layer_text}</p>
            </div>
          </div>
          <div className="methodology-flexbox-secton methodology-flexbox-secton-reverse">
            <div className="methodology-flexbox-text">
              <h3 className={'header-center-text'}>Layer 4: Your Options</h3>
              <p className={"methodology-layer-text"}>{context_take_action_layer_text}</p>
            </div>
            {/* <div className="page-content-container-flexbox image-container-flexbox"> */}
            <img className={"methodology-layer-image box-shadow-standard"} src={CreateCloudfrontUrl(context_take_action_layer_image)} />
            {/* </div> */}
          </div>
          <p className={"resources-text-styles"}>{context_conclusion_sentence}</p>
        </div>
        {/* <div className="page-content-horizontal-divider"></div> */}
        <div className="page-content-container-static">
          <h2 className={'header-center-text'}>Still Have Questions?</h2>

          <p className={"resources-text-styles"}>{still_have_questions}</p>
          <div className={"clickable"} onClick={handleContactUsClick}>
            <StartButton text="Contact Us" shadow_flag={true} />
          </div>
        </div>

      </div>

    </div>
  );
};
