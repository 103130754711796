import React, { useState, useEffect, useContext } from "react";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { SkusResources } from "../../screens/TabletDesktop/TabletDesktop";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
// import { UserFormCategories } from "../../components/UserFormCategories";
import { combineArrays } from "../../services/CompileDatapoints";
import { UserFormDatapoints } from "../../components/UserFormDatapoints";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { windowSize } from "../../App";
import { UserStatus } from "../../services/UserMgt";
import { Frame130 } from "../../components/Frame130";
import { updateAddressDetails } from "../../services/UpdateAnalysis";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { StaticText } from "../../services/StaticText";

import "./style.css";

export const ModalUserForm = () => {

  // Set the various state handlers
  const navigate = useNavigate();
  const location = useLocation();

  // Get the device
  const deviceType = useContext(windowSize)

  // Get the userId / sessionId
  const { sessionId, userId, setUserId, setUserIdStatus } = useContext(UserStatus)

  // Get the navigation context and ability to change around analysis
  const { setCardToggleStatus, setAnalysis, setAnalysisStatus } = useContext(UserExperienceNavigator)

  // Create the state variables we need to know which category the user wants and specificity level
  const [categoryView, setCategoryView] = useState(null)
  const [houseCategoryView, setHouseCategoryView] = useState(null)
  // const [dataFilter, setDataFilter] = useState("specific")

  // Create the state variable for user inputs / changes to the form
  const [inputs, setInputs] = useState({});
  const inputs_length = Object.keys(inputs).length

  // Get the static text for the page
  const static_text = StaticText()
  const modal_user_form_text = static_text.hub.play_with_data
  
  // Create the card title and description
  const card_title = modal_user_form_text.title
  const card_description = modal_user_form_text.description
  const house_details_image_filename = modal_user_form_text.house_details_image_filename
  const house_details_image_text = modal_user_form_text.house_details_image_text
  const house_details_cta = modal_user_form_text.house_details_cta
  const experienced_image_filename = modal_user_form_text.experienced_image_filename
  const experienced_image_text = modal_user_form_text.experienced_image_text
  const costs_and_finances_image_filename = modal_user_form_text.costs_and_finances_image_filename
  const costs_and_finances_image_text = modal_user_form_text.costs_and_finances_image_text
  const image_cta = modal_user_form_text.image_cta
  const update_analysis_button_text = modal_user_form_text.update_analysis_button_text


  // Get the analysis, datapoints, and address id
  const analysis = useContext(AnalysisDetails)
  const address_id = analysis.address_id
  const datapoints_from_analysis = analysis.user_input_form

  // Get the datapoints master
  const sku_resources = useContext(SkusResources)
  const datapoints_master = sku_resources.datapoints

  // Combine the datapoints master with the form datapoints
  const compiled_datapoints = combineArrays(datapoints_from_analysis, datapoints_master)

  // Filter the datapoints based on user selection of data category
  const filtered_datapoints = categoryView ? compiled_datapoints[categoryView] : compiled_datapoints

  // Filter the house datapoints based on the user selection of house category
  const filtered_house_datapoints = (houseCategoryView && categoryView) ? filtered_datapoints.filter(category => category.name === houseCategoryView)[0]?.datapoints || [] : [];
  // console.log(filtered_datapoints);
  // console.log(houseCategoryView)
  const handleHouseCategorySelection = (category) => {
    if (houseCategoryView === category) {
      setHouseCategoryView(null)
    } else {
      setHouseCategoryView(category)
    }
  };

  const checkChange = e => {
    setDataFilter(e.target.value)
  }

  // 
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value);
    setInputs(values => ({ ...values, [name]: value }));
  };

  const handleChange2 = (event) => {
    const name = event.name;
    const value = event.value;
    console.log(name, value);
    setInputs(values => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    RecordEvent("Button", "Updated Data Submit", address_id)
    setCardToggleStatus(false);
    setAnalysisStatus(false);

    const user_identification = userId ? userId : sessionId;
    params = {
      address_id: address_id,
      user_id: user_identification,
      session_id: sessionId,
      removed_project: null,
      completed_project: null,
      data: inputs
    }
    console.log("Hi there!");
    updateAddressDetails(params)
      .then(response => {
        if (response.outcome === "PASS") {

          const output = response.output
          setAnalysis(output);
          setAnalysisStatus(true);
        } else {
          if (response.failure_reason === "FAIL_BUSINESS") {
            navigate("/sorry/business");
          } else if (response.failure_reason === "FAIL_RESIDENCE_TYPE") {
            navigate("/sorry/residence-type");
          } else {
            navigate("/sorry/processing");
          }
        }
      })

  };


  return (
    <>
      <div className={"modal-content-frame"}>
        <span className={"span-xl modal-title"}>
          {card_title}
        </span>
        {/* <p className={'modal-user-form-description-text'}>{card_description}</p> */}
        <div className="modal-selector-boxes-frame">

          <div className={"modal-selector-boxes-grid"}>

            <Frame130
              key="house"
              type="form"
              form_category="house"
              form_user_selection={categoryView}
              setFormCategory={setCategoryView}
              icon={house_details_image_filename}
              text={house_details_image_text}
            />

            <Frame130
              key="experience"
              type="form"
              form_category="experience"
              form_user_selection={categoryView}
              setFormCategory={setCategoryView}
              icon={experienced_image_filename}
              text={experienced_image_text}
            />
            <Frame130
              key="financial"
              type="form"
              form_category="financial"
              form_user_selection={categoryView}
              setFormCategory={setCategoryView}
              icon={costs_and_finances_image_filename}
              text={costs_and_finances_image_text}
            />

          </div>
          {!categoryView &&
            <div className={"modal-user-form-selection-reminder-text-container"}>
              <span className={"span-l modal-user-form-selection-reminder-text"}>{image_cta}</span>
              <br />
            </div>
          }
        </div>

        {/* </div> */}
        {(categoryView === "house" && !houseCategoryView) &&
          <>
            <span className={"span-l modal-user-form-selection-reminder-text"}>{house_details_cta}</span>
            <br />
          </>
        }
        {categoryView === "house" &&

          <div className="modal-user-form-house-category-cards-grid">
            {filtered_datapoints.map(category => {
              const category_icon_url = CreateCloudfrontUrl(category.icon)
              let category_status
              if (houseCategoryView) {
                if (houseCategoryView === category.name) {
                  category_selection_status = "default"
                } else {
                  category_selection_status = "not-selected"
                }
              } else {
                category_selection_status = "default"
              }

              return (

                <div className={`modal-user-form-house-category-card-frame modal-user-form-house-category-card-frame-${category_selection_status}  box-shadow-light`} key={category.name} value={category.name} onClick={() => handleHouseCategorySelection(category.name)}>
                  <div className="modal-user-form-house-category-card-image-container">
                    <img className={"modal-user-form-house-category-card-image"} src={category_icon_url} />
                  </div>
                  <span className={"span-s modal-user-form-house-category-card-category-text"}>{category.name}</span>
                  <span className={"span-xs modal-user-form-house-category-card-category-context-text"}>{category.datapoints.length} {category.datapoints.length !== 1 ? "Items" : "Item"}</span>
                </div>
              )
            })}
          </div>

        }


        {(categoryView && categoryView === "house" && houseCategoryView) &&
          <>
            <div className="div-line-seperator"></div>
            <div className={`modal-user-input-form-datapoints-frame modal-user-input-form-datapoints-frame-${deviceType}`}>

              {filtered_house_datapoints.map(data => (
                <UserFormDatapoints
                  key={data.quoll_datapoint}
                  handleChange={handleChange}
                  handleChange2={handleChange2}
                  inputs={inputs}
                  headline={data.headline}
                  options={data.options}
                  type={data.data_type}
                  datapoint_name={data.datapoint}
                  stat={data.stat}
                />
              ))
              }
            </div>
            <div className={"modal-user-form-submit-button-container"}>
              <button
                className={"span-m modal-user-form-submit-button"}
                type="submit"
                onClick={handleSubmit}
              >
                Update Analysis
              </button>
              {inputs_length > 0 &&
                <span className={"modal-user-form-user-inputs-count-text"}>Datapoints Changed: {inputs_length} </span>
              }
            </div>
          </>
        }

        {(categoryView && categoryView !== "house") &&
          <>
            <div className={`modal-user-input-form-datapoints-frame modal-user-input-form-datapoints-frame-${deviceType}`}>

              {filtered_datapoints.map(data => (
                <UserFormDatapoints
                  key={data.quoll_datapoint}
                  handleChange={handleChange}
                  handleChange2={handleChange2}
                  inputs={inputs}
                  headline={data.headline}
                  options={data.options}
                  type={data.data_type}
                  datapoint_name={data.datapoint}
                  stat={data.stat}
                />
              ))
              }
            </div>
            <div className={"modal-user-form-submit-button-container"}>
              <button
                className={"span-m modal-user-form-submit-button"}
                type="submit"
                onClick={handleSubmit}
              >
                {update_analysis_button_text}
              </button>
              {inputs_length > 0 &&
                <span className={"modal-user-form-user-inputs-count-text"}>Datapoints Changed: {inputs_length} </span>
              }
            </div>
          </>
        }
        <span className={'span-s modal-user-form-description-text'}>{card_description}</span>
      </div>
    </>
  );


};
