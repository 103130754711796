export function CacheUserHomesProjects(input) {

    localStorage.setItem("saved_homes", JSON.stringify(input));

    return null
}


export function GetUserHomesProjectsFromCache() {

    const saved_homes = JSON.parse(localStorage.getItem("saved_homes")) || null;

    return saved_homes
}
