import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SignUp } from "../SignUp";
import { SignIn } from "../SignIn";
import { windowSize } from "../../App";
import WestIcon from '@mui/icons-material/West';
import "./style.css";


export const UserAuthentication = ({
  report_flow=false,
  return_user=false,
  setCardType,
  previousCardType,
  setCardSelection,
  previousCardSelection,
  setCardToggleStatus,
  setUserAuthReportFlow,
  setUserAuthReturnUser,
}) => {

  console.log(report_flow)
  const deviceType = useContext(windowSize)

  const [authView, setAuthView] = useState("join")

  const reasons_to_join = ["Save Homes and Projects to Your Dashboard", "Get Maintenance Notifications", "Access Additional Discounts", "On-Demand Consultations"]

  function toggleView(option) {
    if (authView === "login") {
      setAuthView("join")
    } else {
      setAuthView("login")
    }
  }

  const handleReturnToPrevious = () => {
    setCardType(previousCardType)
    setCardSelection(previousCardSelection)
    setUserAuthReportFlow(false)
    setUserAuthReturnUser(false)
  }

  const handleCloseModal = () => {
    setCardToggleStatus(false)
    setUserAuthReportFlow(false)
    setUserAuthReturnUser(false)
  }

  const special_handling_status = report_flow ? true : false;
  const special_handling_methodology = return_user ? handleReturnToPrevious : handleCloseModal;

  return (
    <>
      {report_flow &&
        <>
          {return_user &&
            <div className="auth-modal-return-to-previous-container box-shadow-standard" onClick={handleReturnToPrevious}>
              <WestIcon />
              <span className={"span-s auth-modal-return-to-previous-container-text"}> Return to Project</span>
            </div>
          }
          <div className={"modal-content-frame"}>


            <div className={`auth-user-choices-container auth-user-choices-container-modal`}>
              {authView === "join" &&
                <>
                  <SignUp deviceType={deviceType} toggleView={toggleView} specialHandlingStatus={special_handling_status} specialHandlingMethod={special_handling_methodology} />
                </>
              }
              {authView === "login" &&
                <>
                  <SignIn deviceType={deviceType} toggleView={toggleView} specialHandlingStatus={special_handling_status} specialHandlingMethod={special_handling_methodology} />
                </>
              }

            </div>
          </div>
        </>
      }
      {!report_flow &&
        <div className={'page-frame'}>
          <div className="page-container">
            <div className={`auth-user-choices-container auth-user-choices-container-${deviceType}`}>

              {authView === "join" &&
                <>
                  <SignUp deviceType={deviceType} toggleView={toggleView} />
                </>
              }
              {authView === "login" &&
                <>
                  <SignIn deviceType={deviceType} toggleView={toggleView} />
                </>
              }

            </div>
          </div>


        </div >
      }
    </>
  );
};
