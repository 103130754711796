import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { MenuItem } from "../../components/MenuItem";
import { SearchBar } from "../../components/SearchBar";
import { MenuButton } from "../../components/MenuButton";
import { windowSize } from "../../App";
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import { GetQuollLinks } from "../../services/GetQuollLinks";
import { UserStatus } from "../../services/UserMgt";
import {ClickAwayListener} from '@mui/base/ClickAwayListener';

import "./style.css";

export const Header = () => {

  const location = useLocation();

  const { userId } = useContext(UserStatus);

  // Get the links and button info

  const quoll_header_footer_links = GetQuollLinks();

  const quoll_links = quoll_header_footer_links.links;
  const quoll_button = quoll_header_footer_links.button;

  const [buttonUrl, setButtonUrl] = useState(quoll_button.url)

  useEffect(() => {
    if (userId) {
      setButtonUrl(quoll_button.logged_in_url);
    } else {
      setButtonUrl(quoll_button.default_url);
    };
  }), [userId];

  const header_links = quoll_links.filter(link => link.location === "both" || link.location === "header");

  // FOR MOBILE: Set the menu open status
  const [mobileMenuStatus, setMobileMenuStatus] = useState(false);

  const handleClickAway = () => {
    setMobileMenuStatus(false);
  };

  const handleMobileMenuClick = () => {
    setMobileMenuStatus((current) => !current);
  };


  // Create the menu
  const header_menu = () => {
    return (
      <React.Fragment>
        {header_links.map(link => (

          <Link onClick={handleClickAway} key={link.url} to={link.url}>
            <MenuItem key={link.text} text={link.text} />
          </Link>
        )
        )}
        <Link onClick={handleClickAway} key={quoll_button.url} to={buttonUrl}>
          <MenuButton
            text={quoll_button.text}
            key={quoll_button.text}
          />
        </Link>
      </React.Fragment>
    )
  };

  const deviceType = useContext(windowSize);
  console.log(deviceType);

  if (deviceType === "mobile" || deviceType === "tablet") {
    mobile_or_not = 'mobile'
  } else { mobile_or_not = 'desktop' };

  const is_homepage = window.location.pathname === "/"


  return (
    <div className={`header-frame ${is_homepage ? null : "header-frame-border"}`}>

      <div className={"company-mark-container-mobile"}>
        <Link key={"home-link"} className={"link-container"} to="/">
          <img className="hero-bar-image-desktop" alt="Image" src={require("../../assets/Quoll-Wordmark.svg")} />
        </Link>
      </div>

      <div className={`header-search-bar-container header-search-bar-container-${mobile_or_not}`}>
        <SearchBar header_flag={true} />
      </div>

      <div className={`header-buttons-and-links-container`}>

        <React.Fragment>
          {deviceType === "desktop" &&
            header_menu()
          }

          {(deviceType === "mobile" || deviceType === "tablet") && (
            <div className="dropdown">

              <button className="header-mobile-menu" onClick={handleMobileMenuClick}>{<MenuSharpIcon />}</button>
              {mobileMenuStatus &&
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className="dropdown-menu">
                    {header_menu()}
                  </div>
                </ClickAwayListener>
              }
            </div>
          )}
        </React.Fragment>
      </div>

    </div>
  );
};
