import React, { createContext, useState } from 'react';

// Create the context
export const SearchContext = createContext();

// Create the provider component
export const SearchContextProvider = ({ children }) => {
  const [googlePlaceId, setGooglePlaceId] = useState(null);

  return (
    <SearchContext.Provider value={{ googlePlaceId, setGooglePlaceId }}>
      {children}
    </SearchContext.Provider>
  );
};
