import React from "react";
import GaugeChart from 'react-gauge-chart'
import "./style.css";

export const ProjectCardMeter = ({

  headline,
  value,
  projectComparisonFlag = false,

}) => {

  const red = '#e55534'
  const yellow = '#efd978'
  const green = '#81c56a'

  const value_analysis = getColor(value);

  // Get width based on whether this is in saved projects comparison vs regular card view
  const chart_width = projectComparisonFlag ? '10vw' : '20vw'
  
  // Determine the needle color
  let needle_color;

  switch (value_analysis.text) {
    case 'High':
      needle_color = red;
      break;
    case 'Medium':
      needle_color = yellow;
      break;
    case 'Low':
      needle_color = green;
      break;
    default:
      needle_color = 'grey'; // default color if get color doesn't provide text
  }

  const complexity_sentence = "Higher complexity can be a headache and sometimes mean increased costs or time required"
  const disruption_sentence = "Greater diruption to your day to day life often requires more planning"
  return (
    <div className={`meter-container ${projectComparisonFlag && "border-none"} ${!projectComparisonFlag && "box-shadow-light"}`}>

      {headline &&
        <span className={'span-l meter-headline'}>
          {headline}
        </span>
      }

      <GaugeChart id="gauge-chart5"
        nrOfLevels={420}
        arcsLength={[0.3, 0.4, 0.3]}
        colors={[green, yellow, red]}
        hideText={true}
        percent={value}
        needleColor={needle_color}
        // needleColor={value_analysis.color}
        animateDuration={4000}
        arcPadding={0.03}
        style={{
          width: chart_width,
        }}
      />

      <span className={'span-m meter-value'}>
        {value_analysis.text}
      </span>
      {headline &&
        <p className={"meter-bar-text"}> {headline === "Complexity" ? complexity_sentence : disruption_sentence}</p>
      }
    </div>
  );
};

function getColor(value) {
  let color, val;

  if (value >= 0 && value < 0.3) {
    val = 'Low';
  } else if (value >= 0.3 && value < 0.7) {
    val = 'Medium';
  } else if (value >= 0.7 && value <= 1) {
    val = 'High';
  }
  return { text: val };
}