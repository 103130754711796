// export function FormatSavedHomesForTable(inputArray, renderCells) {
//     if (!inputArray || inputArray.length === 0) {
//       return [];
//     }
  
//     const formattedData = inputArray.map((inputObj, index) => ({
//       id: index.toString(),
//       dataPoints: [
//         { id: 'image', label: 'Image', value: inputObj.image, renderCell: renderCells['image']  },
//         { id: 'name', label: 'Project', value: inputObj.name },
//         { id: 'description', label: 'About', value: inputObj.description },
//         { id: 'house_category', label: 'Part of Home', value: inputObj.house_category },
//         { id: 'total_value', label: 'Est. Financial Benefit', value: inputObj.total_value, renderCell: renderCells['money'] },
//         { id: 'affordability_impact_areas', label: 'Where This Impacts Your Bills', value: inputObj.affordability_impact_areas },
//         { id: 'ltv_impact_areas', label: 'How This Impacts Long-Term Value', value: inputObj.ltv_impact_areas },
//         { id: 'discounts', label: 'Offers Found', value: inputObj.discounts },
//         { id: 'total_cost', label: 'Est. Project Cost', value: inputObj.total_cost, renderCell: renderCells['money'] },
//         { id: 'meters[0]', label: 'Project Complexity', value: inputObj.meters[0].value },
//         { id: 'meters[1]', label: 'Project Disruption', value: inputObj.meters[1].value },
//         { id: 'who_does_the_work', label: 'Who to Hire', value: inputObj.who_does_the_work }
//       ]
//     }));
  
//     return formattedData;
//   }
  
export function FormatSavedHomesForTable(inputArray, renderCells) {
    if (!inputArray || inputArray.length === 0) {
      return [];
    }
  
    const formattedData = inputArray.map((inputObj, index) => ({
      id: index.toString(),
      dataPoints: [
        { id: 'image', label: 'Image', value: {image: inputObj.image, name: inputObj.name, id: inputObj.id}, renderCell: renderCells['image'] },
        // { id: 'name', label: 'Project', value: inputObj.name },
        { id: 'description', label: 'About', value: inputObj.description, renderCell: renderCells['description'] },
        { id: 'house_category', label: 'Part of Home', value: inputObj.house_category, renderCell: renderCells['houseCategory'] },
        { id: 'blank1', label: 'Valuing This Project', value: "" },
        { id: 'total_value', label: 'Est. Financial Benefit', value: inputObj.total_value, renderCell: renderCells['money'] },
        { id: 'affordability_impact_areas', label: 'Where This Impacts Your Bills', value: inputObj.affordability_impact_areas, renderCell: renderCells['checkMarks'] },
        { id: 'ltv_impact_areas', label: 'How This Impacts Long-Term Value', value: inputObj.ltv_impact_areas, renderCell: renderCells['checkMarks'] },
        { id: 'discounts', label: 'Offers From', value: inputObj.discounts, renderCell: renderCells['discounts'] },
        { id: 'blank2', label: 'Cost and Effort of Project', value: "" },
        { id: 'total_cost', label: 'Est. Project Cost', value: inputObj.total_cost, renderCell: renderCells['money'] },
        { id: 'meters[0]', label: 'Project Complexity', value: inputObj.meters[0].value, renderCell: renderCells['meter'] },
        { id: 'meters[1]', label: 'Project Disruption', value: inputObj.meters[1].value, renderCell: renderCells['meter'] },
        { id: 'diy_friendly', label: 'Is This DIY Friendly?', value: inputObj.diy_friendly, renderCell: renderCells['diyFriendly'] },
        { id: 'who_does_the_work', label: 'If You Decide to Hire Somebody', value: {name: inputObj.who_does_the_work, icon: inputObj.who_does_the_work_icon}, renderCell: renderCells['whoYouHire'] }
      ]
    }));
  
    return formattedData;
  }
  