import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Scrollytelling } from "../../components/ScrollyTelling/Scrollytelling";
import { SearchBar } from "../../components/SearchBar";
import { UserStatus } from "../../services/UserMgt";
import stories from "./problem_stories.json";

import "./style.css";


export const TheProblem = () => {

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);
  
  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Your Challenge";
    setPageTitle("Your Challenge");
  }, []);

  // HERO SECTION
  const hero_background = "flooded-neighborhood.jpg";
  const hero_background_url = CreateCloudfrontUrl(hero_background);
  const hero_headline = "We've been talking about what climate change means for our homes all wrong..."

  // OWNING A HOME BOILS DOWN TO... SECTION 
  const boils_down_section_header = "Owning a home really boils down to..."
  const boils_down_section_subheader_one = "The Bills You're Paying"
  const boils_down_section_subcontext_one = "Insurance, taxes, utilities, maintenance and other sudden costs, etc."
  const boils_down_section_subheader_two = "The Value of Your Home"
  const boils_down_section_subcontext_two = "This is your nest-egg and likely largest investment!"
  const boils_down_section_subcontext_conclusion = "That may feel like an oversimplification, but think about just how much passes through the lens of those two questions! They define where you buy, updates you make to the home, your ability to keep the home, planning for your future, and much more, including informing how you are able to spend in the other areas of your life."

  // HOW WE TALK ABOUT THIS SECTION
  const how_we_talk_about_this_image = "family-yard.jpg"
  const how_we_talk_about_this_image_url = CreateCloudfrontUrl(how_we_talk_about_this_image)
  const how_we_talk_about_this_text = "You need to keep a few things in mind when we talk about the risk climate change poses to your home and wallet."

  // RISK VS IMPACT SECTION
  const risk_v_impact_section_header = "Think About Each Climate Risk More Like a Potential Financial Cost"
  const risk_v_impact_section_description = `We tend to talk about climate risk as a chance of this or that occuring over some long timeframe, but what does that really mean and what are you supposed to do with the information? Do you need to worry? The answer to all of that really comes down to your specific home and how prepared you are.`
  const risk_v_impact_scrollytelling_data = [
    {
      index: 1,
      description: "Let's say you live in an area that is getting more frequent and damaging hail storms. That sounds really bad, right?",
      img: "/img/quoll-tour-risk.png",
    },
    {
      index: 2,
      description: "It certainly could be! But if you have a hail resistant roof then the impact of that risk, the actual cost to you, may in fact be pretty low.",
      img: "/img/roof-vent.jpeg"
    },
    {
      index: 3,
      description: "But this can also work in reverse. Let's say you live in an area getting a lot more rain. That may not sound like much, but that basement window may now put you at risk of a costly flood.",
      img: "/img/quoll-hero-image.png"
    },
  ]

  // WHATS HAPPENING AROUND YOU SECTION
  const happening_around_you_section_header = "Don't Forget to Account for Things Happening Around You"
  const happening_around_you_section_description = `You may not realize it, but your bills and the value of your home are also interconnected with the climate risk of the area around you and the impact it has on your neighbords and local economy. You need to understand those factors as well.`
  const happening_around_you_scrollytelling_data = [
    {
      index: 1,
      description: "In the past we have thought of risk as almost entirely individual, but climate risk is different. Think about this like dropping a pebble in water. The ripple effects of risk is your area are real and you need to understand them.",
      img: "/img/quoll-tour-risk.png",
    },
    {
      index: 2,
      description: "It certainly could be! But if you have a hail resistant roof then the impact of that risk, the actual cost to you, may in fact be pretty low.",
      img: "/img/roof-vent.jpeg"
    },
    {
      index: 3,
      description: "But this can also work in reverse. Let's say you live in an area getting a lot more rain. That may not sound like much, but that basement window may now put you at risk of a costly flood.",
      img: "/img/quoll-hero-image.png"
    },
  ]

  // DON'T WORRY, CHANGES HELP SECTION
  const changed_help_section_description = `Your future rests on your awareness and what you do to protect your home. Don't worry, even small changes can make a major difference!`;

  // QUOLL HERE TO HELP HELP SECTION
  const quoll_here_to_help_header = `Got more questions?`;
  const quoll_here_to_help_description = `That's great too! We've put together an entire resources collection full of guides, blogs, videos, and much more. That should be enough to get you started, but feel free to reach out if you'd like more help!`;

  // Handle scroll button click
  const handleScrollClick = () => {
    targetRef.current?.scrollIntoView({ behavior: "smooth" })
  };

  const targetRef = useRef(null);

  return (
    <div className={'page-frame'}>

      <div className="problem-hero-container">
        <img className={"problem-hero-image"} src={hero_background_url} />
        <div className="problem-hero-text-container">
          <h1 className={"problem-hero-headline"}>{hero_headline}</h1>
          <div className="problem-hero-scroll-notice-container" onClick={handleScrollClick}>
            <span className={"problem-hero-scroll-notice-text"}>Learn More</span>
            <KeyboardDoubleArrowDownIcon style={{ fontSize: '3rem' }} />
          </div>
        </div>
      </div>
      <div ref={targetRef} className="page-container-static">
        <div className="problem-section-container">
          <h2>{boils_down_section_header}</h2>
          <div className="problem-section-inner-row">
            <div className="problem-section-inner-column">
              <h3 className={"problem-section-sub-header-text"}>{boils_down_section_subheader_one}</h3>
              <span className={"problem-section-sub-header-context-text"}>{boils_down_section_subcontext_one}</span>
            </div>
            <div className="problem-section-inner-column">
              <h3 className={"problem-section-sub-header-text"}>{boils_down_section_subheader_two}</h3>
              <span className={"problem-section-sub-header-context-text"}>{boils_down_section_subcontext_two}</span>
            </div>
          </div>
          <p className={"problem-section-conclusion-text"}>{boils_down_section_subcontext_conclusion}</p>
        </div>
        <div className={"how-we-talk-about-it-container"}>
          <div className="how-we-talk-about-it-container-overlay">
            <img className={"problem-hero-image"} src={how_we_talk_about_this_image_url} />
            <h2 className={"how-we-talk-about-it-text"}>{how_we_talk_about_this_text}</h2>
          </div>
        </div>
        {stories.map(story => {

          return (

            <div key={story.id} className="problem-section-container">
              <h2>{story.headline}</h2>
              <p className={"problem-section-conclusion-text"}>{story.description}</p>
              <Scrollytelling inputData={story.story} />
            </div>
          )
        })}
        <div className={"how-we-talk-about-it-container"}>
          <div className="how-we-talk-about-it-container-overlay">
            <img className={"problem-hero-image"} src={how_we_talk_about_this_image_url} />
            <h2 className={"how-we-talk-about-it-text"}>{changed_help_section_description}</h2>
          </div>
        </div>
        <div className="problem-section-container">
          <h2>{quoll_here_to_help_header}</h2>
          <p className={"problem-section-conclusion-text"}>{quoll_here_to_help_description}</p>
        </div>
      </div>
    </div>


  );
};
