import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import Cookies from 'js-cookie';
import { GetUserSavedItems } from './GetUserSavedItems';
import { CacheUserHomesProjects, GetUserHomesProjectsFromCache } from './CacheUserHomesProjects';
import { CacheUserResources, GetUserResourcesFromCache } from './CacheUserResources';
import { RecordPageView } from './HandleGoogleAnalytics';
import Hotjar from '@hotjar/browser'
// import { v4 as uuidv4 } from 'uuid';

// Function to log changes to document title
// function observeDocumentTitleChanges() {
//     // Select the target node (the document)
//     const targetNode = document;

//     // Configuration of the observer:
//     const config = { subtree: true, characterData: true, childList: true };

//     // Callback function to execute when a change is observed
//     const callback = function (mutationsList, observer) {
//       for (const mutation of mutationsList) {
//         if (mutation.type === 'childList' && mutation.target === document.head) {
//           // Document title has changed
//           console.log('OBSERVE DOC FUNCTION New Document Title:', document.title);
//         }
//       }
//     };

//     // Create a new observer with the callback and configuration
//     const observer = new MutationObserver(callback);

//     // Start observing the document for changes
//     observer.observe(targetNode, config);
//   }

// Create the context
export const UserStatus = createContext();

// Create the provider component
export const UserStatusProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userIdStatus, setUserIdStatus] = useState(false);
    const [userSavedHomes, setUserSavedHomes] = useState([]);
    const [userSavedResources, setUserSavedResources] = useState([]);
    const [cacheCheckStatus, setCacheCheckStatus] = useState(false);

    const location = useLocation();


    useEffect(() => {
    }, [document.title]);

    useEffect(() => {

        if (pageTitle) {
            Hotjar.stateChange(location.pathname);
            RecordPageView(location.pathname, pageTitle)
        }
    }, [pageTitle]);


    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    // Initial, on load useEffect that checks for cookies / session id
    useEffect(() => {
        const session_ident = Cookies.get('q_sid');
        const user_ident = Cookies.get('q_uid');

        if (typeof session_ident === "undefined") {

            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4(); // Generate a UUID
            const timestamp = Date.now(); // Get the current timestamp in milliseconds
            const sessionID = `${uuid}-${timestamp}`; // Combine UUID and timestamp

            Cookies.set("q_sid", sessionID, {
                expires: 7,
            });

            setSessionId(sessionID);

        } else {
            setSessionId(session_ident)
        }

        if (typeof user_ident !== "undefined") {

            setUserId(user_ident);
            const cached_homes = GetUserHomesProjectsFromCache();
            const cached_resources = GetUserResourcesFromCache();

            if (cached_homes && cached_resources) {
                setUserSavedHomes(cached_homes);
                setUserSavedResources(cached_resources);
            } else {
                setUserIdStatus(true);
            }

            setCacheCheckStatus(true);
        } // NEED TO ADD THAT IF IT IS UNDEFINED WE GO AND REMOVE EVERYTHING FROM LOCAL STORAGE

    }, []);

    // THIS IS HOW I DID IT BEFORE AND I DON'T REMEMBER IF THERE WAS A REASON!
    // useEffect(() => {
    //     if (userIdStatus) {
    //         // console.log("Made it into the useEffect!")

    //         Cookies.set("q_uid", userId, {
    //             expires: 7,
    //         });
    //     }
    //     setUserIdStatus(false)
    // }, [userIdStatus]);

    // When there is a user go and get their saved homes and resources
    useEffect(() => {
        if (userIdStatus) {

            // Set the cookie
            Cookies.set("q_uid", userId, {
                expires: 7,
            });
            console.log("We called the API.....")
            // Get whatever this user has saved
            GetUserSavedItems(userId)
                .then(response => {

                    if (response.outcome === "SUCCESS") {
                        const saved_homes_projects = response.saved_homes_projects
                        setUserSavedHomes(saved_homes_projects)
                        const saved_resources = response.saved_resources
                        setUserSavedResources(saved_resources)
                        setCacheCheckStatus(true);
                        setUserIdStatus(false);
                    } else {
                        navigate("/sorry/processing");
                    }
                })
                .catch(error => {
                    navigate("/sorry/processing");
                })
        }
    }, [userIdStatus]);

    // Whenever user saved homes changes we want to cache it locally
    useEffect(() => {

        if (cacheCheckStatus) {
            CacheUserHomesProjects(userSavedHomes);
        }
    }, [userSavedHomes]);

    useEffect(() => {

        if (cacheCheckStatus) {
            CacheUserResources(userSavedResources);
        }
    }, [userSavedResources]);


    return (
        <UserStatus.Provider value={{ setPageTitle, sessionId, userId, setUserId, setUserIdStatus, userSavedHomes, setUserSavedHomes, userSavedResources, setUserSavedResources, setCacheCheckStatus }}>
            {children}
        </UserStatus.Provider>
    );
};
