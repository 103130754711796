
// export function getRisksAndHousePartsFromProjects(arr) {
//   console.log(arr);
  
//   const risks = [...new Set(arr.map(obj => obj.risk))];
//   const houseAreas = [...new Set(arr.map(obj => obj.house_area))];
  
//   // const risks = [...new Set(arr.map(obj => obj.associated_climate_risks))];
//   // const houseAreas = [...new Set(arr.map(obj => obj.house_category))];

//   return { risks, house_areas: houseAreas };
// }

export function getRisksAndHousePartsFromProjects(arr2) {
    // console.log(arr2);
    
    // const risks = [...new Set(arr.map(obj => obj.risk))];
    // const houseAreas = [...new Set(arr.map(obj => obj.house_area))];

    const risks = [...new Set(arr2.map(obj => obj.associated_climate_risks))].sort();
    const houseAreas = [...new Set(arr2.map(obj => obj.house_category))].sort();
    // console.log(risks)
    // console.log(houseAreas)

    return { risks, house_areas: houseAreas };
  }

  