import React, { useState, useEffect, useContext } from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StartButton } from "../../components/StartButton/StartButton";
import { RecordPageView } from "../../services/HandleGoogleAnalytics";
import { UserStatus } from "../../services/UserMgt";
import { StartButton } from "../../components/StartButton/StartButton";
import { SendToStakeholder } from "../../forms/SendToStakeholder";
import { windowSize } from "../../App";
import { FAQAccordian } from "../../components/FAQAccordian/FAQAccordian";
import faq from "./faq.json"
import "./style.css";


export const FAQ = () => {

  // Get the window size / device type
  const deviceType = useContext(windowSize)

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "FAQ";
    setPageTitle("FAQ");
  }, []);

  return (
    <div className={'page-frame-static'}>

      <div className={'page-container-static'}>
        <div className={"static-page-header-frame"}>
          <h1 className={'header-center-text'}>Quoll FAQs</h1>
        </div>
        <div className="faqs-container">
          {faq.map( (faq, index) => {

            return (
              <FAQAccordian key={index} index={index} faq={faq} />
            )
          })}
        </div>
      </div>

    </div>
  );
};
