// import { useReducer } from "react";
import React, { useState, setState, useReducer } from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import iconX from "../../assets/icons-x.png";
import "./style.css";

export const ClimateIcon = ({

  addHover,
  removeHover,
  selection,
  risk,
  property1 = "Default",

}) => {

  // Determine the risk level for the given climate risk and choose appropriate icon
  const selected_icon_file = risk.value === "High" ? risk.icon_white : risk.icon_black;

  // Get the full CloudFront url including the selected icon file info
  const selected_icon = CreateCloudfrontUrl(selected_icon_file)

  // Get lowercase risk level for global formatting
  const lower_risk = risk.value.toLowerCase()

  // Create reducer state
  // const [state, dispatch] = useReducer(reducer, {
  //   property1: property1 || "Default",
  // });

  return (
      <div key={risk.name}>
        <div
          className={`climate-icon-frame-default ${selection === risk.name && "climate-icon-frame-selected"}`}
          onMouseEnter={() => addHover(risk.name)}
          onMouseLeave={() => removeHover()}
        >
          {/* <div className={`climate-icon-color-background ${risk.value === "High" ? "climate-icon-color-high" : "climate-icon-color-medium"}`}> */}
          <div className={`${selection !== risk.name && "box-shadow-standard"} climate-icon-color-background background-color-${lower_risk}-risk`}>
            <img
              // className={`climate-icon-image ${property1 === "Clear" ? "icon-climate-v5-Clear" : "icon-climate-v5-class"}`}
              className={`climate-icon-image`}
              src={selection === risk.name ? iconX : selected_icon}
            />
          </div>
        </div>
      </div>
  );
};

// function reducer(state, action) {
//   switch (action) {
//     case "mouse-enter":
//       console.log("mouse-enter")
//       setHover(true)
//       return {
//         ...state,
//         property1: "Hover",
//       };

//     case "mouse-leave":
//       return {
//         ...state,
//         property1: "Clear",
//       };
//     // case "mouse-leave":
//     //   return {
//     //     ...state,
//     //     property1: "Variant2",
//     //   };
//   }


//   return state;
// };
