import React, { useState, useEffect } from "react";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import NotInterestedSharpIcon from '@mui/icons-material/NotInterestedSharp';
import "./style.css";

export const ProjectAccordianValue = ({ project }) => {

  // Get the affordability and ltv impact values
  const affordability_impact = (project.affordability_impact * 100).toString() + "%"
  const ltv_impact = (project.ltv_impact * 100).toString() + "%"

  // Create the bills variable and data for display
  const [affectsInsurance, setAffectsInsurance] = useState(false);
  const [affectsUtilities, setAffectsUtilities] = useState(false);
  const [affectsMaintenance, setAffectsMaintenance] = useState(false);
  const [affectsSuddenCosts, setAffectsSuddenCosts] = useState(false);

  // Determine which bill variables are affected
  const affordability_impact_areas = project.affordability_impact_areas
  useEffect(() => {
    if (affordability_impact_areas.includes('utility')) {
      setAffectsUtilities(true);
    }

    if (affordability_impact_areas.includes('insurance')) {
      setAffectsInsurance(true);
    }

    if (affordability_impact_areas.includes('general')) {
      setAffectsMaintenance(true);
    }

    if (affordability_impact_areas.includes('disaster')) {
      setAffectsSuddenCosts(true);
    }

  }, []);


  // const bill_impact_value = "25%";

  const bills_impact_data = [
    { headline: "Insurance Premiums", data: affectsInsurance },
    { headline: "Utility Costs", data: affectsUtilities },
    { headline: "General Maintenace", data: affectsMaintenance },
    { headline: "Sudden Disaster Costs", data: affectsSuddenCosts },
  ]

  // Create the ltv variables and data for display
  // const ltv_impact_value = "75%";

  const [curbAppeal, setCurbAppeal] = useState(false);
  const [lowerOperatingCosts, setLowerOperatingCosts] = useState(false);
  const [insurability, setInsurability] = useState(false);
  const [resiliencyLevel, setResiliencyLevel] = useState(false);

  // Determine which bill variables are affected
  const ltv_impact_areas = project.ltv_impact_areas
  useEffect(() => {
    if (ltv_impact_areas.includes('curb')) {
      setCurbAppeal(true);
    }

    if (ltv_impact_areas.includes('insurability')) {
      setInsurability(true);
    }

    if (ltv_impact_areas.includes('resilience')) {
      setResiliencyLevel(true);
    }

    if (ltv_impact_areas.includes('operating')) {
      setLowerOperatingCosts(true);
    }

  }, []);


  const ltv_data = [
    { headline: "Curb Appeal", data: curbAppeal },
    { headline: "Lower Operating Costs", data: lowerOperatingCosts },
    { headline: "Insurability", data: insurability },
    { headline: "Resiliency Level", data: resiliencyLevel },
  ]

  return (
    <div className="project-impact-section-frame">
      <p className={"project-impact-solving-for-text"}>{project.solving_for}</p>
      <div className="project-impact-frame">


        <div className="project-impact-bar-container">
          <h4 className={"project-impact-bar-title"}>Impact on Bills</h4>
          <div className="project-impact-bar box-shadow-light">
            <div className="project-impact-value" style={{ width: affordability_impact }}></div>
          </div>
          <div className="project-impact-bar-scale-container">
            <span className={"span-xs project-impact-bar-scale-text"}>Minor</span>
            <span className={"span-xs project-impact-bar-scale-text"}>Major</span>
          </div>
          <div className="project-impact-checkmarks-container">
            {bills_impact_data.map(bill => {
              return (
                <div className="project-impact-checkmark-item">
                  {/* <CheckCircleSharpIcon className={`project-impact-checkmark-${bill.data}`} /> */}
                  {bill.data ?
                    <CheckCircleSharpIcon className={`project-impact-checkmark-${bill.data}`} />
                    :
                    <NotInterestedSharpIcon className={`project-impact-checkmark-${bill.data}`} />
                  }
                  <span className={`span-s project-impact-item-text-${bill.data}`}>{bill.headline}</span>
                </div>
              )
            })}
          </div>
        </div>


        <div className="project-impact-bar-container">
          <h4 className={"project-impact-bar-title"}>Impact on Home Value</h4>
          <div className="project-impact-bar box-shadow-light">
            <div className="project-impact-value" style={{ width: ltv_impact }}></div>
          </div>
          <div className="project-impact-bar-scale-container">
            <span className={"span-xs project-impact-bar-scale-text"}>Minor</span>
            <span className={"span-xs project-impact-bar-scale-text"}>Major</span>
          </div>
          <div className="project-impact-checkmarks-container">
            {ltv_data.map(bill => {
              return (
                <div className="project-impact-checkmark-item">
                  {bill.data ?
                    <CheckCircleSharpIcon className={`project-impact-checkmark-${bill.data}`} />
                    :
                    <NotInterestedSharpIcon className={`project-impact-checkmark-${bill.data}`} />
                  }
                  <span className={`span-s project-impact-item-text-${bill.data}`}>{bill.headline}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
