import React, { useEffect, useRef, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { NowWhatGuidanceQuollAssistance } from "../NowWhatGuidanceQuollAssistance";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { ChecklistItem } from "../../components/ChecklistItem";
import { PlayWithDataButton } from "../../components/PlayWithDataButton";
import { SendToStakeholder } from "../../forms/SendToStakeholder";
import { UserStatus } from "../../services/UserMgt";
import { StartButton } from "../../components/StartButton/StartButton";
import { WhatNowNavigator } from "../NowWhatContainer/NowWhatContainer";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";

export const NowWhatGuidanceBuyer = ({ solving_for, checklist, persona, signed_up_text, not_signed_up_text, first_category }) => {

  // Capitalize the guidance path for the recording of events
  const guidance_path_capitalized = persona.charAt(0).toUpperCase() + persona.slice(1)
  
  // Get device type 
  const deviceType = useContext(windowSize)

  // Get the handle clickaway from the WhatNowNavigator
  const handleClickaway = useContext(WhatNowNavigator)

  // Get the card toggle capability to open the data card if the user clicks on data and assumptions
  const { setCardToggleStatus, setCardType } = useContext(UserExperienceNavigator)

  // Set up navigate for if user is not signed in and tries to save project
  const navigate = useNavigate();

  // Grab the user status
  const { userId } = useContext(UserStatus);
  const user_status = userId ? "Signed In User" : "Not Signed In User"

  // Get the navigation link for any "Ask Quoll" clicks so it goes to sign up if not signed up and Dashboard if they are
  const ask_quoll_link = userId ? "/my-dashboard" : "/sign-in"

  // Handle ask quoll button click
  const handleAskQuollClick = () => {
    RecordEvent("What Next Guidance", `${guidance_path_capitalized} | ${toggledItemData.category} | ${toggledItemData.cta} Button Clicked`,  user_status)
    handleClickaway();
    navigate(ask_quoll_link);
  };

  // Create the resources link url
  const resources_link = "/resources"

  // Handle resources click
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  // Handle data and assumptions button click
  const openDataAndAssumptions = () => {
    handleClickaway();
    setCardType("user_form")
    setCardToggleStatus("true")
  };

  // Get the link for the save home to dashboard gif
  const save_home_to_dashboard_filename = "save-home-to-dashboard.gif"
  const save_home_to_dashboard_url = CreateCloudfrontUrl(save_home_to_dashboard_filename)

  // Grab the analysis
  const analysis = useContext(AnalysisDetails)

  // Get the address
  // const address = analysis.address_details.full_address.split(",")[0]
  const address = analysis.address_details.full_address
  // Get the climate risks 
  const climate_risks = analysis.climate.climate_scores
  const climate_risk_names_sorted = climate_risks.map(obj => obj.name).sort().join(", ");

  // Get the toggle state
  const [toggledItem, setToggledItem] = useState(first_category)

  // Get the data for that toggled state
  const toggledItemData = checklist.filter(item => item.category === toggledItem)[0];
  const toggledItemCTA = toggledItemData.cta
  const toggledItemCTAText = toggledItemData.cta_text || null;

   // Log the change in what checklist item is toggled path
   useEffect(() => {
    if (toggledItem) {
      // const guidance_path_capitalized = persona.charAt(0).toUpperCase() + persona.slice(1)
      console.log(guidance_path_capitalized)
      console.log(toggledItemData)
      RecordEvent("What Next Guidance", `${guidance_path_capitalized} | ${toggledItemData.category} | ${toggledItemData.cta} Opened`,  user_status)
    }
}, [toggledItem]);

  const take_action_cta = (toggledCTA, toggledCTAText) => {

    // The CTAs are:
    // play_with_data_button DONE | form_data_collection DONE | form_email_contactor DONE | compare_homes_gif | ask_quoll DONE | review_resources DONE |  

    if (toggledCTA === "play_with_data_button") {
      return (
        <React.Fragment>
          <div className={"clickable"} onClick={() => openDataAndAssumptions()}>
            <PlayWithDataButton />
          </div>
        </React.Fragment>
      )
    } else if (toggledItemCTA === "form_data_collection") {
      return (
        <React.Fragment>
          <SendToStakeholder climate_risks={climate_risk_names_sorted} address={address} purpose={"data_collection"} />
        </React.Fragment>
      )
    } else if (toggledItemCTA === "form_email_contactor") {
      return (
        <React.Fragment>
          <SendToStakeholder climate_risks={climate_risk_names_sorted} address={address} purpose={"project_consultation_" + persona} />
        </React.Fragment>
      )
    } else if (toggledItemCTA === "ask_quoll") {
      return (
        <React.Fragment>
          <div className={"clickable"} onClick={() => handleAskQuollClick()}>
            <StartButton shadow_flag={true} text={toggledCTAText} />
          </div>
        </React.Fragment>
      )
    } else if (toggledItemCTA === "review_resources") {
      return (
        <React.Fragment>
          <div className={"clickable"} onClick={() => openInNewTab(resources_link)}>
            <StartButton shadow_flag={true} text={toggledCTAText} />
          </div>
        </React.Fragment>
      )
    } else if (toggledItemCTA === "compare_homes_gif") {
      return (
        <React.Fragment>
          <div
            style={{
              backgroundImage: `url(${save_home_to_dashboard_url})`,
            }}
          >
            <img src={save_home_to_dashboard_url} alt="Save Home to Dashboard" />
          </div>
        </React.Fragment>
      )
    } else {
      return (
        null
      )
    }
  };

  return (
    <>
      <span className={"span-m text-align-center"}>{solving_for}</span>
      <div className="now-what-guidance-take-action-frame">

        <div className={`now-what-guidance-checklist-container ${deviceType !== "desktop" && "now-what-guidance-checklist-container-mobile"}`}>
          {checklist.map(item => {
            return (
              <ChecklistItem deviceType={deviceType} toggledItemData={toggledItemData} cta={take_action_cta(toggledItemCTA,toggledItemCTAText)} id={item.headline} headline={item.headline} selection={toggledItem} text={item.text} category={item.category} handleClick={setToggledItem} />
            )
          })}
        </div>
        {deviceType === "desktop" &&
          <div className={"now-what-guidance-cta-container"}>
            {/* <span className={"span-l text-align-center text-wrap-balance now-what-guidance-checklist-item-header"}>{toggledItemData.body_header}</span> */}
            <span className={"span-xl text-align-center text-wrap-balance now-what-guidance-checklist-item-header"}>{toggledItemData.body_header}</span>
            <p className={"text-align-center text-wrap-balance now-what-guidance-checklist-item-text"} >{toggledItemData.text}</p>
            {/* <span className={"span-s text-align-center text-wrap-balance now-what-guidance-checklist-item-text"} >{toggledItemData.text}</span> */}
            {take_action_cta(toggledItemCTA, toggledItemCTAText)}
          </div>
        }
      </div>
    </>
  );
};
