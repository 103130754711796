import React, { useState } from "react";
import "./style.css";


export const UserFormDatapoints = ({
    handleChange2,
    inputs,
    type = "int",
    datapoint_name,
    stat,
    headline,
    options,

}) => {

    const bool_options = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];

    // const [value, setValue] = useState('fruit');

    const newhandleChange = (event) => {

        const value = event.target.value;

        const object = { "name": datapoint_name, "value": value };
        handleChange2(object)

    };

    return (
        <div className={'form-entry-container box-shadow-light'}>
            
            {/* <div className={'form-entry-frame'}> */}
                
                <span className={'span-xs form-entry-headline'}>
                
                    {headline}
                
                </span>
                
                <div className={'form-entry-input'}>
                    
                    {type == 'int' &&
                        <input
                            className={'form-entry-input-box'}
                            type={type}
                            name={datapoint_name}
                            placeholder={stat}
                            value={inputs[datapoint_name] || ""}
                            onChange={newhandleChange}
                        />
                    }
                    {type === 'list' &&
                        <select className={'form-entry-input-box'} value={inputs[datapoint_name]} option={datapoint_name} onChange={newhandleChange}>
                            
                            <option className={'form-entry-input-box'} value="" disabled selected>
                                {stat ? stat : "Select"}
                            </option>

                            {options.map((option, index) => (
                                <option className={'form-entry-input-box'} key={index} value={option}>{option}</option>
                            ))}

                        </select>
                    }
                    {type === 'bool' &&
                        <select className={'form-entry-input-box'} value={inputs[datapoint_name]} option={datapoint_name} onChange={newhandleChange}>
                            <option className={'form-entry-input-box'} value="" disabled selected>{stat ? stat : "Select"}</option>
                            {bool_options.map((option, index) => (
                                <option className={'form-entry-input-box'} key={index} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    }
                </div>
            {/* </div> */}
        </div>
    );
};