import React, { useState, useEffect, useContext } from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StartButton } from "../../components/StartButton/StartButton";
import { RecordPageView } from "../../services/HandleGoogleAnalytics";
import { UserStatus } from "../../services/UserMgt";
import { StartButton } from "../../components/StartButton/StartButton";
import { SendToStakeholder } from "../../forms/SendToStakeholder";
import { windowSize } from "../../App";
import "./style.css";


export const Careers = () => {

  // Get the window size / device type
  const deviceType = useContext(windowSize)
  console.log(deviceType)

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Set the image state
  const [images, setImages] = useState([])

  // Function for handling image upload 
  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Careers";
    setPageTitle("Careers");
  }, []);

  const what_were_looking_for = [
    {
      image: "climate-driven.png",
      headline: "Climate Driven",
      text: "This is existential stuff. Work on what matters!"
    },
    {
      image: "human-focused.png",
      headline: "Human Focused",
      text: "Spend each day thinking about how to help families!"
    },
    {
      image: "visionary.png",
      headline: "Big Thinkers",
      text: "The sky is the limit, help us define where we go!"
    },
    {
      image: "problem_solver.png",
      headline: "Problem Solvers",
      text: "Turn problem statements into solutions!"
    },
    {
      image: "outcome-oriented.png",
      headline: "Outcome Oriented",
      text: "We need people to drive initiatives all the way!"
    },
    {
      image: "collaborative.png",
      headline: "Collaborative Minds",
      text: "Teamwork makes the dream work!"
    },
  ]

  const careers_intro_paragraph = "Climate change is the defining issue of our time and we wake up everyday striving to make a difference.  The work is tough, the challenge is big, but the feeling you'll have seeing your efforts have real impact... that makes it all worth it. Are you ready to get in the game?"
  const roles_paragraph = "We're currently looking for developers in React/HTML/CSS, data scientists, product managers, content / marketing managers, and more. We love people with diverse experiences and backgrounds so send in your resume and we'd be excited to chat!"

  return (
    <div className={'page-frame-static'}>

      <div className={'page-container-static'}>
        <div className={"static-page-header-frame"}>
          <h1 className={'header-center-text'}>Interested in Working With Us?</h1>
        </div>
        <div className="page-content-container-static">
          <p className={"resources-text-styles"}>{careers_intro_paragraph}</p>
        </div>
        <div className="page-content-container-static">
          <h2 className={'header-center-text'}>Who We're Looking For</h2>
          <div className="careers-icons-grid">
            {what_were_looking_for.map(item => {
              return (
                <div className="careers-icon-frame">
                  <div className="careers-icon-container">
                    <img className={"careers-icon-image"} src={CreateCloudfrontUrl(item.image)} />
                  </div>
                  <span className={'span-l careers-icon-headline'}>{item.headline}</span>
                  <span className={'span-m careers-icon-text'}>{item.text}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div className="page-content-horizontal-divider-full" />
        <div className="page-content-container-static">
          <h2 className={'header-center-text'}>Roles</h2>
          <p className={"resources-text-styles"}>{roles_paragraph}</p>
        </div>
        <div className="careers-resume-drop-form box-shadow-standard">
          <h2 className={'header-center-text'}>Drop Us A Line!</h2>
          <SendToStakeholder purpose="careers_submission" />
        </div>
      </div>

    </div>
  );
};
