import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetProjectSkus } from "../../services/GetProjectSkus.jsx";
import { ProjectCardAccordian, ProjectCardMaterials } from "../../modules/ProjectCardAccordian";
import { AnalysisDetails, UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop.jsx";
import { SkusResources } from "../../screens/TabletDesktop/TabletDesktop.jsx";
import { formatDollarAmount } from "../../services/FormatDollarAmounts.jsx";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl.jsx";
import { UserStatus } from "../../services/UserMgt.jsx";
import { UpdateSavedProjects } from "../../services/UpdateSavedProjects.jsx";
import { FormatHouseForSave } from "../../services/FormatHouseForSave.jsx";
import { saveHouseProjectResource } from "../../services/SaveHouseProjectResource.jsx";
import {useSnackbar} from "@mui/base/useSnackbar";
import { SnackBarAlert } from "../../components/SnackBarAlert";
import formatDiscounts from "../../services/formatDiscounts";
import { updateAddressDetails } from "../../services/UpdateAnalysis.jsx";
import "./style.css";

export const ModalProject = ({
  selection,

}) => {

  // Set up navigate for if user is not signed in and tries to save project
  const navigate = useNavigate();

  // Redirect navigation if user is not designed
  const signInRedirect = "/sign-in";

  // Set the state of whether the project is currently saved
  const [isProjectSaved, setIsProjectSaved] = useState(false);

  // Set the snackbox messages
  const [snackboxMessage, setSnackboxMessage] = useState(null);

  // Get the user id
  const { userId, sessionId, userSavedHomes, setUserSavedHomes } = useContext(UserStatus);

  // Grab the navigation context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, setAnalysis, setAnalysisStatus, cardSelection, setCardSelection, previousCardType, setPreviousCardType, previousCardSelection, setPreviousCardSelection, setUserAuthReportFlow, setUserAuthReturnUser } = useContext(UserExperienceNavigator)

  setPreviousCardType("project")
  setPreviousCardSelection(selection)


  // Grab the analysis details
  const analysis = useContext(AnalysisDetails);

  // Get the address id
  const address_id = analysis.address_id

  // Get the projects and find the selected project
  const projects_list = analysis.projects
  const selected_project = projects_list.filter(project => project.id === selection)[0]

  // Get the selected project id
  const selected_project_id = selected_project.id

  // Get the selected project completion status
  const selected_project_completion_status = selected_project.project_completion_status
  // console.log("Completion Status: ", selected_project_completion_status)

  // Create the project complete params
  const project_complete_api_params = {
    address_id: address_id,
    user_id: userId ? userId : sessionId,
    session_id: sessionId,
    completed_project: null,
  }

  if (selected_project_completion_status) {
    project_complete_api_params.removed_project = selected_project_id
    project_complete_api_params.completed_project = null
  } else {
    project_complete_api_params.removed_project = null
    project_complete_api_params.completed_project = selected_project_id
  }

  // console.log(project_complete_api_params)
  // Get the value of the project
  const project_value = formatDollarAmount(selected_project.total_value)

  // Format the costs for dollar amounts
  const cost_materials = formatDollarAmount(selected_project.total_cost_materials)
  const cost_labor = formatDollarAmount(selected_project.total_cost_labor)

  // Create the image url for getting from Cloudfront
  image_url = CreateCloudfrontUrl(selected_project.image)

  // Grab the discounts list for this project
  const project_discounts_list = selected_project.discounts

  // console.log(project_discounts_list)

  // Collect the SKUs and resources from the saved sku resources
  const skus_resources = useContext(SkusResources);

  // Get the discounts, company discounts, and companies from the sku resources
  const companies = skus_resources.companies
  const discounts = skus_resources.discounts
  const company_discounts = skus_resources.company_discounts

  
  // Filter the master discounts to just the ones for this project
  const project_discounts = discounts.filter(discount => project_discounts_list.includes(discount.id))
  
  // Combine and format the discounts with company discounts and company info
  const processed_discounts = formatDiscounts(project_discounts, company_discounts, companies);
  console.log(processed_discounts)

  // Pull the discounts and count from the formatted discounts

  const formatted_discounts = processed_discounts.discounts
  const discount_count = processed_discounts.count

  // Get the associated resources
  const associated_resources = skus_resources.resources.filter(resource => selected_project.associated_resources.includes(resource.id))

  // Get the project sku ids 
  const selected_project_sku_ids_set = new Set(selected_project.skus.map(item => item.id))
  const selected_project_sku_ids = Array.from(selected_project_sku_ids_set)

  // Get the filtered skus 
  const filtered_skus = skus_resources.skus.filter(sku => selected_project_sku_ids.includes(sku.id))
  const combined_skus = GetProjectSkus(selected_project.skus, filtered_skus, associated_resources, companies);

  // Set the different accordian sections + headers
  const materials_table_header = { colA: "Vendor", colB: "Product", colC: "Est. Total Cost", colD: "Link" }
  const materials_accordian_header = { headline: "Materials You'll Need", description: "Est. Materials Cost", value: cost_materials }
  const services_accordian_header = { headline: "Professionals Who Can Do the Work", description: "Est. Services Cost", value: cost_labor }
  const resources_accordian_header = { headline: "DIY Guides and Other Resources", description: null, value: null }
  const complexity_accordian_header = { headline: "Complexity of Project and Disruption to You", description: null, value: null }
  const value_accordian_header = { headline: "How to Value Doing This Project", description: "Our Est.", value: project_value }
  const discounts_accordian_header = { headline: "Discounts and Offers We've Identified", description: "Total Available", value: discount_count }

  // Determine if this project is already saved
  useEffect(() => {
    const projectSaveStatusCheck = userSavedHomes.some((house) => (house.address_id === address_id && house.saved_projects.includes(selected_project_id)));
    setIsProjectSaved(projectSaveStatusCheck);

    if (projectSaveStatusCheck) {
      setSnackboxMessage("Added to My Projects!")
    } else {
      setSnackboxMessage("Removed from My Projects")
    }
  }, [userSavedHomes]);

  // console.log("Is this project saved?: " + isProjectSaved);
  // console.log("User ID: ", userId)

  // Handle the project to user saved homes / projects
  const handleProjectSaveClick = () => {

    console.log("User ID: ", userId)
    if (userId) {
      // Create the params for the API call
      const api_params = {
        type: "project",
        address_id: address_id,
        user_id: userId,
        project_id: selected_project_id
      }
      console.log(api_params);

      // Trigger the API call
      saveHouseProjectResource(api_params)
        .then(output => output)
        .catch(error => {
          console.log(error);
        });

      // See if this home is already in the saved homes state
      const homeSavedCheck = userSavedHomes.some((house) => house.address_id === address_id);

      // If there is no home then we need to create one and add the project
      if (!homeSavedCheck) {
        const completed_projects = null;
        const formattedHouseForSave = FormatHouseForSave(analysis);
        formattedHouseForSave.saved_projects.concat([selected_project_id]);
        const new_user_saved_homes = userSavedHomes.concat(formattedHouseForSave)
        setUserSavedHomes(new_user_saved_homes);

        // If it is then we need to either add or remove it from the array
      } else {
        const updated_saved_homes = UpdateSavedProjects(address_id, selected_project_id, userSavedHomes)
        setUserSavedHomes(updated_saved_homes);
      }
    } else {
      // console.log("NAVIGATION TO SIGN IN FROM PROJECT CARD!!")
      // navigate(signInRedirect)
      setCardType("sign_in")
      setUserAuthReportFlow(true)
      setUserAuthReturnUser(true)
    };
  }

  // Handle a project being marked as completed
  const handleCompletedClick = (event) => {
    setCardToggleStatus(false);
    setAnalysisStatus(false);
    updateAddressDetails(project_complete_api_params)
      .then(response => {
        if (response.outcome === "PASS") {

          const output = response.output
          setAnalysis(output);
          setAnalysisStatus(true);
        } else {
          if (response.failure_reason === "FAIL_BUSINESS") {
            navigate("/sorry/business");
          } else if (response.failure_reason === "FAIL_RESIDENCE_TYPE") {
            navigate("/sorry/residence-type");
          } else {
            navigate("/sorry/processing");
          }
        }
      })

  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 2000
  });

  const handleOpen = () => {
    setOpen(true);
  };


  return (
    // <div className={"proto-viewport-overview-home-area-frame-66"}>
    <div className={"modal-content-frame"}>

      <div className="modal-project-header-bar">

        <div className={"modal-project-breadcrumb-bar"}>
          <span className={"span-s modal-project-breadcrumb-item modal-project-breadcrumb-item-clickable text-underline"} onClick={() => {
            // setGlobalState("cardSelection", selected_project.house_category)
            setCardSelection(selected_project.house_category)
            // setGlobalState("cardType", "house")
            setCardType("house")
            // console.log('inside')
          }}>
            {selected_project.house_category} &#32;
          </span>
          <span className={"span-s modal-project-breadcrumb-item"}>
            &gt; Project Guide
          </span>
        </div>
        <div className={"modal-project-user-project-mgt-buttons-bar"}>
          <div className={`modal-project-user-project-mgt-button-container modal-project-user-project-mgt-button-container-saved-${selected_project_completion_status} box-shadow-light`}
            onClick={() => {
              handleCompletedClick();
            }}
          >
            <span className={"span-m modal-project-user-project-mgt-button-text"}>
              {selected_project_completion_status ? "Completed" : "Mark as Done"}
            </span>
          </div>
          <div
            className={`modal-project-user-project-mgt-button-container modal-project-user-project-mgt-button-container-saved-${isProjectSaved} box-shadow-light`}
            onClick={() => {
              handleProjectSaveClick();
              handleOpen();
            }}
          >
            <span className={"span-m modal-project-user-project-mgt-button-text"}>
              {isProjectSaved ? "Saved to My Projects" : "Add to My Projects"}
            </span>
          </div>
          {open &&
            <SnackBarAlert message={snackboxMessage} />
          }
        </div>
      </div>
      <div className="modal-project-image-container">
        <img className={"modal-project-image box-shadow-standard"} src={image_url} />
      </div>
      <div className="modal-project-title-bar">
        <span className={"span-xl modal-title"}>
          {selected_project.name}
        </span>

        <span className={"span-m modal-project-professional-v-diy-text"}>
          {selected_project.diy_friendly === true ? "DIY FRIENDLY" : "PROFESSIONAL RECOMMENDED"}
        </span>
      </div>
      <p className={"modal-project-description"}>

        {selected_project.description}

      </p>

      <div className="modal-project-accordians-container">

        <ProjectCardAccordian
          accordian_header={value_accordian_header}
          // table_header={materials_table_header}
          project={selected_project}
          type="value"
          default_toggle_status={true}
        />
        {discount_count > 0 &&
          <ProjectCardAccordian
            accordian_header={discounts_accordian_header}
            // table_header={materials_table_header}
            discounts={formatted_discounts}
            type="discounts"
            default_toggle_status={false}
          />
        }

        <ProjectCardAccordian
          accordian_header={complexity_accordian_header}
          // table_header={materials_table_header}
          project={selected_project}
          type="complexity"
          default_toggle_status={false}
        />

        <ProjectCardAccordian
          accordian_header={materials_accordian_header}
          table_header={materials_table_header}
          skus={combined_skus}
          type="skus"
          default_toggle_status={false}
        />

        <ProjectCardAccordian
          accordian_header={services_accordian_header}
          table_header={materials_table_header}
          project={selected_project}
          type="labor"
          default_toggle_status={false}
        />

        {associated_resources.length > 0 &&
          <ProjectCardAccordian
            accordian_header={resources_accordian_header}
            table_header={materials_table_header}
            resources={associated_resources}
            project={selected_project}
            type="resources"
            default_toggle_status={false}
          />
        }
      </div>



    </div>
  );


};