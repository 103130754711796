import React, { useContext } from "react";
import { PlayWithDataButton } from "../../components/PlayWithDataButton";
import { ToggleButton } from "../../components/ToggleButton";
import { setGlobalState, useGlobalState } from "../../services/globalstate";
import { AnalysisDetails, UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const HubHeader = ({

  selection,
  setSelection,
  hubNav,

}) => {

  // Get analysis and navigation details from context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)
  const analysis = useContext(AnalysisDetails);

  // Get screen size from context
  const deviceType = useContext(windowSize);

  // Determine the total number of projects for the "All Projects" tab
  const total_projects = (analysis != null) ? analysis.projects.length : " ";

  // Get selected tab
  const selected_section = hubNav.filter(item => item.type === selection)[0];

  // Get the hub navigation text
  const overview_text_long = hubNav.filter(item => item.type === "overview")[0].hub_header;
  const financial_text_long = hubNav.filter(item => item.type === "financial")[0].hub_header;
  const projects_text_long = hubNav.filter(item => item.type === "projects")[0].hub_header;
  const saved_projects_text_long = hubNav.filter(item => item.type === "saved_projects")[0].hub_header;
  const take_action_text_long = hubNav.filter(item => item.type === "take_action")[0].hub_header;
  const overview_text_short = hubNav.filter(item => item.type === "overview")[0].hub_header_short;
  const financial_text_short = hubNav.filter(item => item.type === "financial")[0].hub_header_short;
  const projects_text_short = hubNav.filter(item => item.type === "projects")[0].hub_header_short;
  const saved_projects_text_short = hubNav.filter(item => item.type === "saved_projects")[0].hub_header_short;
  const take_action_text_short = hubNav.filter(item => item.type === "take_action")[0].hub_header_short;

  const overview_text = deviceType === "mobile" ? overview_text_short : overview_text_long; 
  const financial_text =  deviceType === "mobile" ? financial_text_short : financial_text_long;
  const projects_text =  deviceType === "mobile" ? projects_text_short : projects_text_long;
  const saved_projects_text =  deviceType === "mobile" ? saved_projects_text_short : saved_projects_text_long;
  const take_action_text =  deviceType === "mobile" ? take_action_text_short : take_action_text_long;

  return (
    <>

      <div className={"hub-header-frame"}>

        {/* <div className={"play-with-data-bar"} onClick={() => {
          setCardType("user_form");
          setCardToggleStatus(true);
        }}>

          <PlayWithDataButton
            text="&nbsp;&nbsp;Play With Our Data &amp; Assumptions"
          />

        </div> */}

        <div className={"hub-header-toggle-bar-frame box-shadow-standard"}>

          <ToggleButton onClick={() => setSelection("overview")}
            // text="Overview of Risks"
            text={overview_text}
            type="overview"
            selection={selection}
          />

          <ToggleButton onClick={() => setSelection("financial")}
            // text="Financial Impact"
            text={financial_text}
            type="financial"
            selection={selection}
          />

          <ToggleButton onClick={() => setSelection("projects")}
            text={projects_text + `(${total_projects})`}
            type="projects"
            selection={selection}
          />

          <ToggleButton onClick={() => setSelection("saved_projects")}
            text={saved_projects_text}
            type="saved_projects"
            selection={selection}
          />

          <ToggleButton onClick={() => setSelection("take_action")}
            text={take_action_text}
            type="take_action"
            selection={selection}
          />

        </div>

      </div>

    </>
  );

};