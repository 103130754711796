// import { useEffect, useState } from "react";
// import { SaveShareBar } from "../../modules/SaveShareBar";
// import { Link } from 'react-router-dom';
import React, { useEffect, useState, useContext } from "react";
import { OverviewHubV5 } from "../../modules/OverviewHubV5";
import { Title } from "../../modules/Title";
import { MapsScore } from "../../modules/MapsScore";
import { SearchContext } from "../../services/SearchContext";
import { UserStatus } from "../../services/UserMgt.jsx";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { LoadingGif } from "../../components/LoadingGif";
import { NowWhatContainer } from "../../nowwhat/NowWhatContainer/NowWhatContainer";
import RecordPageView from "../../services/HandleGoogleAnalytics";
import "./style.css";

// During testing
// import sample_analysis_data from './sample_report.json'
// import sku_resource_data from './sample_skus_resources.json'

// When using live data / API calls
import { useParams, useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
import { fetchAddressDetails } from "../../services/GetAnalysis";
import { getResourcesandSkus } from "../../services/GetSkusResources";


export const AnalysisDetails = React.createContext()
export const SkusResources = React.createContext()
export const UserExperienceNavigator = React.createContext()

export const TabletDesktop = () => {

  // Get the user details
  const { setPageTitle, userId, sessionId, userSavedHomes, setUserSavedHomes } = useContext(UserStatus);

  // Determine whether there is a userId or we need to use the sessionId for the search
  const [searchUserIdent, setSearchUserIdent] = useState(sessionId);

  const getUserIdent = () => {
    if (userId) {
      return userId;
    } else {
      return sessionId;
    };
  }

  // MAY NOT NEED THIS ANYMORE AS WE ARE DOING THE USER IDENTIFICATION LOGIC WITHIN THE ACTUAL ANALYSIS FETCHING STEP
  useEffect(() => {
    if (userId) {
      setSearchUserIdent(userId);
    } else {
      setSearchUserIdent(sessionId);
    }
  }, [userId, sessionId]);

  // Set the page navigation variables so we know how a user is moving around the page
  const [cardToggleStatus, setCardToggleStatus] = useState(false);
  const [cardType, setCardType] = useState(null);
  const [cardSelection, setCardSelection] = useState(null);

  // THIS IS FOR HOW WE HANDLE THE SIGNUP FLOW WITHIN THE REPORT VIEW
  // Set the "previous" card type and selection for the back button during sign on while looking at report
  const [previousCardType, setPreviousCardType] = useState(null);
  const [previousCardSelection, setPreviousCardSelection] = useState(null);
  const [userAuthReportFlow, setUserAuthReportFlow] = useState(false);
  const [ userAuthReturnUser, setUserAuthReturnUser ] = useState(false);

  // This is for stopping the background scroll while the card is open
  useEffect(() => {
    if (cardToggleStatus) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    // document.getElementById("master-frame").style.overflow = "hidden";
    // document.body.style.overflow = "hidden";
  }, [cardToggleStatus]);


  // Set the iniital page title
  // const [pageTitle, setPageTitle] = useState("Quoll | Loading");
  const [newPageTitle, setNewPageTitle] = useState(null);

  // Set the various state handlers
  const navigate = useNavigate();
  const location = useLocation();

  const { googlePlaceId } = useContext(SearchContext);
  const { setGooglePlaceId } = useContext(SearchContext);
  const { address } = useParams();
  const { house_id } = useParams();
  const [sourceOfTruth, setSourceOfTruth] = useState(null);
  const [previousHouseId, setPreviousHouseId] = useState(null);
  const [isSearchOperation, setIsSearchOperation] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  const [processingStatus, setProcessingStatus] = useState(false);

  // Set the analsis state handler
  const [data, setData] = useState(null);
  const [analysis, setAnalysis] = useState(null)
  const [analysisStatus, setAnalysisStatus] = useState(false)

  const pathParts = location.pathname.split('/'); // split the URL path into its constituent parts
  const house_ident = pathParts[pathParts.length - 1]; // get the last part of the path, which should be the house_id


  // useEffect to set source of truth based on changes in house_id or googlePlaceId
  useEffect(() => {
    if (googlePlaceId) {
      setSourceOfTruth('search');
      setProcessingStatus(true);
    }
    else if (house_id !== previousHouseId && house_id !== '_qid') {
      setSourceOfTruth('url');
      setProcessingStatus(true);
    }
  }, [house_id, googlePlaceId]);

  // useEffect to call our microservice API to get the house analysis
  useEffect(() => {


    // First, get the user identification that we are going to use for this call, either the session id or the user id
    const userIdentification = getUserIdent();

    // This is for if the API call is based off of the details from the URL
    if (sourceOfTruth === 'url' && house_id && !isSearchOperation && processingStatus) {

      // Set analysis status to false so the loading screen comes on
      setAnalysisStatus(false);

      // Get the address id from the url
      const strippedHouseId = house_id.replace('_qid', '');

      // Create the API call body
      const request_param = { address_id: strippedHouseId, user_id: userIdentification, "lookup_method": "url" };

      fetchAddressDetails(request_param)
        .then(response => {

          // Assuming that the analysis has passed
          if (response.outcome === "PASS") {

            // Pull out the analysis
            const output = response.output

            // Set the various states 
            setAnalysis(output);
            setAnalysisStatus(true);
            setProcessingStatus(false);

            // Update the URL
            const formatted_address_from_output = output.address_details.full_address
            setNewPageTitle(`Quoll | ${formatted_address_from_output}`)

          } else {
            // If the analysis did not pass then determine the right sorry page
            if (response.failure_reason === "FAIL_BUSINESS") {
              navigate("/sorry/business");
            } else if (response.failure_reason === "FAIL_RESIDENCE_TYPE") {
              navigate("/sorry/residence-type");
            } else {
              navigate("/sorry/processing");
            }
          }
        })
        .catch(error => {

          // If there is an error then send to the generic processing error sorry page
          navigate("/sorry/processing");
        })
        .finally(() => {
          setIsSearchOperation(false); // reset isSearchOperation flag
          setIsNavigating(false); // reset isNavigating here
        });
    }
    else if (sourceOfTruth === 'search' && googlePlaceId && processingStatus) {

      // Set analysis status to false so the loading screen comes on
      setAnalysisStatus(false);

      setIsSearchOperation(true);

      const request_param = { address_id: googlePlaceId, user_id: userIdentification, "lookup_method": "search" };

      fetchAddressDetails(request_param)
        .then(response => {

          if (response.outcome === "PASS") {

            const output = response.output
            setAnalysis(output);
            setAnalysisStatus(true);
            const formatted_address_from_output = output.address_details.full_address
            setNewPageTitle(`Quoll | ${formatted_address_from_output}`)
            setIsSearchOperation(false); // reset isSearchOperation flag
            setProcessingStatus(false);
            setGooglePlaceId(null);

            if (output && output.address_id) {
              const newUrl = `/house/${address}/${output.address_id}_qid`;
              if (location.pathname !== newUrl) {
                setIsNavigating(true);
                navigate(newUrl, { replace: true });
                setIsNavigating(false); // reset isNavigating here
                setTimeout(() => setIsNavigating(false), 0);
              }
              setSourceOfTruth('url'); // switch the source of truth back to 'url'
              setPreviousHouseId(output.address_id + "_qid"); // set the previousHouseId to the new id
            }
          } else {
            if (response.failure_reason === "FAIL_BUSINESS") {
              navigate("/sorry/business");
            } else if (response.failure_reason === "FAIL_RESIDENCE_TYPE") {
              navigate("/sorry/residence-type");
            } else {
              navigate("/sorry/processing");
            }
          }
        })
        .catch(error => {
          setIsSearchOperation(false); // reset isSearchOperation flag
          console.log(error);
          navigate("/sorry/processing");
        })
      // .finally(() => {
      //   // setIsSearchOperation(false); // reset isSearchOperation flag
      //   // setIsNavigating(false); // reset isNavigating here
      // });
      // fetchDataByPlaceId(googlePlaceId).then(responseData => {
      // setData(responseData);
      // setPreviousHouseId(responseData.internalHouseId);
      // });
    }
  }, [sourceOfTruth, house_id, googlePlaceId, processingStatus]); // SHOULDN'T THIS JUST BE OFF OF PROCESSING STATUS?!!?

  useEffect(() => {
    const handlePopstate = () => setIsNavigating(true);

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (sourceOfTruth === 'url') {
      setIsSearchOperation(false);
    }
  }, [sourceOfTruth]);

  const [resourceSkus, setResourceSkus] = useState(null)
  const [resourceSkusStatus, setResourceSkusStatus] = useState(null)


  useEffect(() => {
    if (analysisStatus) {

      getResourcesandSkus(analysis)
        .then(data => {
          setResourceSkus(data);
          setResourceSkusStatus(true)
        })
        .catch(error => {
          setIsSearchOperation(false); // reset isSearchOperation flag
          console.log(error);
          navigate("/sorry/processing")
        });

    }
  }, [analysisStatus, analysis]); // SHOULD THIS JUST BE ANALYSIS AND NOT ANALYSIS STATUS?


  useEffect(() => {
    if (setResourceSkusStatus) {
    }
  }, []);

  // This will run when the page first loads and whenever the title changes
  useEffect(() => {
    if (newPageTitle) {
      document.title = newPageTitle;
      setPageTitle(newPageTitle);
      // console.log("Changed the page title.")
    }
  }, [newPageTitle]);

  // Create the loading gif url
  const loading_gif_filename = "quoll_loading.gif";
  const loading_gif_url = CreateCloudfrontUrl(loading_gif_filename)

  return (
    // <div className={"desktop-home-report-frame"}>
    <div id="master-frame" className={"page-frame page-frame-border"}>

      <div className={"page-container"}>
        <UserExperienceNavigator.Provider value={{ cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection, setAnalysis, setAnalysisStatus, previousCardType, setPreviousCardType, previousCardSelection, setPreviousCardSelection, userAuthReportFlow, setUserAuthReportFlow, userAuthReturnUser, setUserAuthReturnUser }}>
          <AnalysisDetails.Provider value={analysis}>
            <SkusResources.Provider value={resourceSkus}>

              {!analysisStatus &&
                <LoadingGif />
              }
              {analysis &&
                <NowWhatContainer />
              }
              <Title />
              {analysisStatus &&
                <MapsScore />
              }
              <OverviewHubV5 />
            </SkusResources.Provider>
          </AnalysisDetails.Provider>
        </UserExperienceNavigator.Provider>
      </div>

      {/* THIS IS THE "DO YOU NEED HELP MODAL!!" */}
      {/* <div className={"tablet-desktop-frame"}>
        <div className={"tablet-desktop-help-fab"}>
          <div
            className={"tablet-desktop-frame-173"}
            style={{
              backgroundImage: "url(/img/frame-173.png)",
            }}
          />
          <p className={"tablet-desktop-need-help-schedule-a-call-with-an-expert"}>
            <span className={"tablet-desktop-text-wrapper"}>
              Need Help Understanding This?
              <br />
            </span>
            <span className={"tablet-desktop-span"}>Schedule a call with an expert</span>
          </p>
        </div>
      </div> */}
    </div>
  );
};
