import master_text from "./static_text_master.json";

export function StaticText() {

    // const text = master_text
    const text = {

        home: {
            hero: {
                title_regular: "Climate Change Is Complicated. Protecting Your Home",
                title_italic: "Doesn't Have to Be",
                description: "Enter your address to get started - it's free! See your climate and financial risks, how they translate into specific areas of your house  - and the impacts on your bills and your home's value - all with the tools you need to take action to tackle and lower your financial risks.",
                image: "quoll-hero-image.png",
            },
            deep_breath: {
                headline_1: "Deep breath.",
                headline_2: "You don’t need to do all of this at once.",
                description: "Save a home, play with the data, explore projects, and more! This tool is free. Our goal is to equip you with knowledge and actionable recommendations. \n\n Come back as often as you need. Go at your own pace. Ask us questions.",
            },
            about_us: {
                headline_1: "We’ve been in your shoes, and ",
                headline_2: "we want to help.",
                description: "We started Quoll because we have personally experienced the effects of extreme weather and the unforseen wild financial ramifications. What began with protecting our own families is now about protecting all of yours as well!",
                image: "team.png",
                cta: "About Us",
            },
            value_prop: {
                headline_1: "Homeownership is already overwhelming.",
                headline_2: "We've boiled this down to what you need to know and how act on it.",
                cta: "Explore how our analysis works",
                items: [
                    {
                        key: "understand",
                        text: "See your home’s specific climate threats and which parts of your home are most at risk",
                        img: "quoll-value-prop-house.png"
                    },
                    {
                        key: "learn",
                        text: "Learn how that risk and the risk in your community is impacting your bills and the home’s long-term value",
                        img: "quoll-value-prop-data.png"
                    },
                    {
                        key: "act",
                        text: "Browse recommended projects that protect your home and financial future and use Quoll to start taking action!",
                        // text: "Browse recommended projects and maintenance you can do to protect your home and financial future",
                        img: "quoll-value-prop-tools.png"
                    },
                ]
            },
            tour: {
                headline_1: "Enter your home's address and see a unique",
                headline_2: "home dashboard",
                tour_steps: [
                    {
                        index: 1,
                        context: "Learn Your Risk Exposure",
                        header: "Focus your attention where it’s most critical.",
                        description: "When you enter an address and you’ll immediately see the Quoll Score for your home and pressing issues you’re facing, including which areas of your home are most threatened by each risk. It’s the big picture and the bottom line in one snapshot.",
                        questions1: "What risks do I need to worry about?",
                        question2: "What parts of my home are in need of critical attention?",
                        img: "Quoll-site-screenshot-climate.png",
                        button: "Knowledge is Power"
                    },
                    {
                        index: 2,
                        context: "Understand the Financial Impact",
                        header: "The real-world costs of your risk.",
                        description: "Connecting the dots between risk and financial impact takes a lot of work. Our dashboard analyses and visualizes the financial impact so you can understand your home’s long-term value and your near-term costs.",
                        questions1: "Can I continue to afford my home in the long run?",
                        question2: "How do these risks affect the value of my home in the next 10 years?",
                        img: "Quoll-site-screenshot-finance.png",
                        button: "Insights for What Matters"
                    },
                    {
                        index: 3,
                        context: "Take Action",
                        header: "Solutions and resources for action at your discretion.",
                        description: "We want homeowners to be empowered to take action. Each home analysis includes recommended projects and maintenance you can do to begin protecting your home, with tools to get it done. You are in command. Take a step towards a more secure and resilient future.",
                        questions1: "What projects help me manage the risk?",
                        question2: "How do I take the first step and where do I find the support and resources?",
                        img: "Quoll-site-screenshot-projects.png",
                        button: "Small Changes, Major Impact"
                    },
                ]
            },
        },
        hub: {
            hub_navigation: [
                { hub_header: "Overview of Risks",hub_header_short: "Key Risks", "type": "overview", "toggle": "Overview of Risks", "button": "Understand the Risks You Have" },
                { hub_header: "Financial Impact",hub_header_short: "Financial Impact", "type": "financial", "toggle": "Financial Impact", "button": "See How Those Risks Threaten Your Wallet" },
                { hub_header: "Recommended Projects ",hub_header_short:"Recomm. Projects ", "type": "projects", "toggle": "All Projects ", "button": "Learn Which Projects Can Help You" },
                { hub_header: "My Saved Projects",hub_header_short: "My Saved Projects", "type": "saved_projects", "toggle": "All Projects ", "button": "Compare Projects You've Saved" },
                { hub_header: "Next Steps",hub_header_short: "Next Steps", "type": "take_action", "toggle": "All Projects ", "button": "How to Start Taking Action" },
            ],
            popup_score_context: {
                letter_sentences: {
                    A: "Congratulations, this home has relatively low risk all around. But don't let a good score stop you from saving money. Take some time to look at the ways you can lower your bills even more!",
                    B: "This home has relatively low risk. But don't let a good score fool you. Storms and other major events like fire and flooding in your region can drive up your costs. Take some time to look at the ways you can lower your bills and save money!",
                    C: "The risks for this home could quickly cost you, if you are not prepared. The good news is that there's plenty you can do to lower your risks. Find the right projects for your schedule and budget to get started - and don't forget to let us know when you've completed your projects - every project will help lower your risks and improve your score.",
                    D: "This home is starting to get pretty risky. But don't lose hope, there are plenty of projects that can lower your risk and protect your home. As you learn more about your risks and understand how they're costing you, take some time to look at the projects you can complete to both lower the risk and your costs at the same time. Let us know when you've completed a project to improve your score.",
                    F: "This home is in a very risky area, which is already costing you money. It's critical for you to understand those risks and the financial impacts from them, so you can get started on the best projects to protect the home and lower your costs. Let us know when you've completed a project to improve your score.",
                },
                cta: "After you've had a chance to review all of the intelligence, click on the Next Steps and we'll help you get started on turning this into action!",
            },
            climate: {
                title: "Climate Threats to This Home",
                select_risk_text: "Select a risk to see which parts of the home are threatened",
                about_area_headline: "About Your Area",
            },
            financial_impact: {
                title: "Risk Impacts Your Finances Over Two Timeframes",
                affordability_toggle_header: "Affordability: The Bills You're Paying",
                affordability_toggle_header_context: "Near-Term",
                ltv_toggle_header: "Market Value: Protecting Your Nest Egg",
                ltv_toggle_header_context: "Long-Term",
                affordability: {
                    intro_paragraph: "From property taxes to home insurance to utility bills, climate risk is pushing a lot of expenses higher. Below, you’ll find this home’s affordability risk – that’s the risk that costs will go up faster than you can afford them – along with information about the costs we're seeing in your area and where they’re going to go over the next decade.",
                    rule_of_thumb_title: "Rule of Thumb",
                    rule_of_thumb_text: "Ideally your non-mortgage home expenses never rise above 15% of your pre-tax income. That includes work done directly on your home, like maintenance and unforeseen repairs, as well as regular bills like utilities, property tax, and insurance.",
                    understanding_data_title: "Understanding the Data For This House",
                    understanding_data_description: "Modeling out the expenses for this home requires not only looking at the home itself, but also examining the costs seen by others in your community. Don't forget, they play a major role in the expenses you'll face!",
                    projected_expenses_title: "Projected Expenses For This Home Over Next 10 Years",
                    projected_expenses_description: "Hover over the chart to see projected expenses for this home over the next 10 years. It’s important to understand how your costs will go each year. After you’ve had a chance to see how your costs will change, take a look at the recommended projects, which will help you manage and even lower your costs once you’ve completed them.",
                    projected_expenses_conclusion: "It’s not a question of if costs are going up, it’s about how well you can afford to cover those escalating bills over time. By taking proactive steps to lower your bills now, you can start saving money immediately. \n\n Browse the recommended projects to see which can shrink your monthly bills the fastest. Click the projects tab above or choose the next step on your journey below.",
                    assessment_title: "Your Risk That This Home Becomes Unaffordable",
                    tipping_point_greater_than_10_risk_flag: "While it appears expenses for this house are relatively stable, that could change quickly given the heightend climate risks you face.",
                    tipping_point_greater_than_10: "While it looks like you aren't at risk of unaffordability in the next 10 years, even one bad event can quickly change the equation overnight!",
                    tipping_point_greater_than_5: "We do see these bills becoming unaffordable down the road. Any disaster event could bring that time forward, so don't want to address this!",
                    tipping_point_less_than_5: "It looks like this home's expenses are already pushing unaffordability. Figuring out ways to address this is something that should be front and center for you today.",
                    maintenance_image: "home_maintenance.png",
                    insurance_image: "home_insurance.png",
                    utilities_image: "home_utilities.png",
                    taxes_image: "home_taxes.png",
                },
                ltv: {
                    ltv_hub_intro_paragraph: "The impact of climate risk on your home and bills is just one part of the story. To figure out the cumulative impact climate has on this home's value we also need to examine what climate risk means for your neighbors, local economy, local government and everything else around you.",
                    rule_of_thumb_title: "Keep in Mind",
                    rule_of_thumb_text: "Things like Zestimates and other metrics on what your home may be worth are interesting, but try not to put too much stock in them. Remember, the value of your home only becomes real at the moment it comes time to sell, when there is another person willing to pay that amount. Things can change quickly!",
                    understanding_the_data_title: "Understanding the Data For This House",
                    assessment_title: "Our Assessment of the Risk to This Home's Value",
                    ltv_takeaway_general: "We've looked at this house and it's risks, and contextualized it within the local market, including the climate risk impacting the market.",
                    ltv_takeaway_conclusion: "See which project recommendations can help protect and build your home's value. Click the projects tab above or choose the next step on your journey below.",
                    ltv_takeaway_less_than_5: "We believe we are already seeing signs of climate affecting the value of homes like this in the area.",
                    ltv_takeaway_less_than_10: "We believe are early signals of climate beginning to affect the value of homes like this in the area.",
                    ltv_takeaway_more_than_10_no_flag: "It looks like values in the area are pretty stable, meaning this home's value is less likely to be affected by climate risk!",
                    ltv_takeaway_more_than_10_flag: "Home values in the area seem stable, but given the climate risks this home faces, be aware that the situation may change rapidly, especially if a major event occurs.",
                    broken_home_image_filename: "broken_home.png",
                    home_bills_image_filename: "home_bills.png",
                    neighborhood_image_filename: "neighborhood.png",
                }
            },
            projects: {
                title: "Project Recommendations Based on This Home's Risks",
                rec_section_title: "Our Top Recommendations for This Home",
                browse_section_title: "Browse All of the Recommended Projects",
                intro_text: "Based on your top risks, we've recommended a few projects to help you get started. Browse the projects by topic or look at the full list for this home. We suggest picking a few and saving them to 'My Dashboard', so you can manage them more easily and get credit for them in your Quoll score when you've completed them!",
                not_signed_in_cta: "Sign in to save projects to your account and get personalized recommendations for your home.",
                signed_in_no_saved_projects_cta: "You haven't saved any projects for this home yet. Click the bookmark icon on any project to save it to your account.",
            },
            saved_projects: {
                title: "Save and Compare Projects That Protect This Home",
                description: "See the your saved projects below and compare them to determine the right ones to tackle first!",
                not_signed_in_cta: "Sign in to save and compare the various recommended projects.",
                signed_in_no_saved_projects_cta: "You haven't saved any projects for this home yet. Click the bookmark icon on any project to save it to your account.",
                saved_homes_comparison_preview_image: "saved-homes-comparison-preview-image.png",
            },
            next_steps: {
                title: "How to Start Acting On This Intelligence",
                intro_paragraph: "Buying a house and managing one you already own require different ways of thinking about the climate and financial risks you're facing. Depending on where you are in the ownership cycle, click on a button below to see the checklist that'll help you get started.",
                buyer: {
                    guidance_type: "buyer",
                    title: "Action Plan for Buyers",
                    buyer_signed_up_quoll_assistance: "Did you know that your Quoll account gives access to a number of additional tools plus our team of experts? You can schedule a call with us and together we'll review each home you're considering, determine what matters most to you, and develop a personalized plan. We'll even help guide you as you work through it, including communicating with other stakeholders like your real estate agent, the home's owner, insurance companies, and more, all for free!",
                    buyer_not_signed_up_quoll_assistance: "Sign up for a Quoll account and you'll get access to a number of additional tools plus our team of experts. You'll be able to schedule a call with us and together we'll review each home you're considering, determine what matters most to you, and develop a personalized plan. We'll even help guide you as you work through it, including communicating with other stakeholders like your real estate agent, the home's owner, insurance companies, and more, all for free!",
                    icon_image: "house-for-sale.png",
                    icon_text: "I'm Considering Buying This Home",
                    guidance_description: "Buying a home is a huge financial decision and can be a bit scary if you're not sure what's most important  to focus on. We've put together the checklist below to help guide you through the process of getting the right information to ask the right questions so you can be confident in the home you choose.",
                    checklist: [
                        {
                            headline: "Have you updated all of the data possible for this home?",
                            category: "update_data",
                            body_header: "Updated the Data Where Possible",
                            text: "Using the latest data is critical to ensuring we give you the best intelligence possible!",
                            cta: "play_with_data_button"
                        },
                        {
                            headline: "Is there any data missing that you need help collecting?",
                            category: "missing_data",
                            body_header: "Get Help Filling in the Gaps",
                            text: "As a buyer it can be really hard to know all of the details for your next potential home. Just enter the email for the home's seller and/or your real estate agent and we'll ask them to update the house details. \n\n Updates will factor into the home’s overall score and financial risk analysis and help you make more informed decisions. We’ll alert you when they’ve made any updates.",
                            cta: "form_data_collection"
                        },
                        {
                            headline: "Have you determined which projects you want to focus on?",
                            category: "email_contactor",
                            body_header: "Pick the Right Projects",
                            text: "The right projects can make this home more resilient and lower your future bills. Having a list of these projects often become negotiation points with the owner, but you need to know how much they’ll cost and how disruptive they’ll be, before you can effectively negotiate. \n\n Click “Add to My Projects” in the top right corner of each project you want save so you can run them by your preferred contractor to get the best information to make your offer.",
                            cta: "form_email_contactor"
                        },
                        {
                            headline: "Have you compared this home against others you are considering?",
                            category: "compare_homes",
                            body_header: "Use Quoll to Compare the Homes",
                            text: "Every home analysis we produce is unique so don't assume that even homes nearby have the same risk profile or future outlook! \n\n Make a list of all the homes you’re considering and be sure to save each one to your dashboard so you can go back and compare their Quoll scores, risks and add them to your project list.",
                            cta: "compare_homes_gif"
                        },
                        {
                            headline: "Have you confirmed this home's insurance rate / insurability?",
                            category: "check_insurability",
                            body_header: "Confirm Area Insurability",
                            text: "In some areas at risk of wildfires, flooding and hurricanes, it can be hard to find an insurance provider who will fully cover you. Some, but not all states, offer FAIR plans as insurance of last resort, so you can’t assume it’s easy to find insurance that meets your needs or the requirements of your lender.",
                            cta: null,
                        },
                        {
                            headline: "Have you confirmed your insurance rate?",
                            category: "confirm_insurance_rate",
                            body_header: "Ensure You're Shopping for Best Rates",
                            text: "Completing certain projects can actually lower your base insurance rate and make you eligible for additional discounts on your insurance policy. Sometimes it can even come down to the materials used for things like the roof. \n\n Still have questions? Schedule time to chat with us!",
                            cta: "ask_quoll",
                            cta_text: "Talk to Quoll About Insurance"
                        },
                        {
                            headline: "Have you set a maintenance budget?",
                            category: "create_budget",
                            body_header: "Plan for Maintenance and Repairs",
                            text: "Completing certain projects can actually lower your base insurance rate and make you eligible for additional discounts on your insurance policy. Sometimes it can even come down to the materials used for things like the roof. \n\n Still have questions? Schedule time to chat with us, and we can guide you!",
                            cta: "ask_quoll",
                            cta_text: "Get Budgeting Help"
                        },
                    ]
                },
                owner: {
                    guidance_type: "owner",
                    title: "Action Plan for Owners",
                    owner_signed_up_quoll_assistance: "Did you know that your Quoll account gives access to a number of additional tools plus our team of experts? You can schedule a call with us and together we'll design a personalized plan and guide you as you as you work through it, all for free! You'll have access to live video chats where we'll look through your home's data and analysis together, determine what things matter most to you, talk through exactly how you can take advantage of discounts and other savings, and even help you engage the different stakeholders like your contractor or service technician, the insurance companies and more, plus other helpful tools!",
                    owner_not_signed_up_quoll_assistance: "Sign up for a Quoll account and our team of experts will design a personalized plan and guide you as you as you work through it, all for free! You'll have access to live video chats where we'll look through your home's data and analysis together, determine what things matter most to you, talk through exactly how you can take advantage of discounts and other savings, and even help you engage the different stakeholders like your contractor or service technician, the insurance companies and more, plus other helpful tools!",
                    icon_image: "family-at-home.png",
                    icon_text: "I Own This Home",
                    guidance_description: "Most owners aren't prepared for the hidden costs of homeownership, and what you don't know can have serious effects on your finances and ability to afford your house over the long term. Follow the checklist below to discover areas where you can lower your monthly bills, save hundreds or even thousands of dollars on your insurance and learn about how you can protect your biggest asset, your home.",
                    checklist: [
                        {
                            headline: "Have you checked that your home’s information is complete and up to date?",
                            category: "update_data",
                            body_header: "Make Sure this Home's Data is Current",
                            text: "Ensuring that our analysis is utilizing the latest data is critical to ensuring we give you the best intelligence possible! Take a few minutes to look at the assumptions and update anything that might have changed since you bought your house.",
                            cta: "play_with_data_button"
                        },
                        {
                            headline: "Have you made a list of the projects that you want to tackle first?",
                            category: "missing_data",
                            body_header: "Pick the Right Projects",
                            text: "The right projects can make this home more resilient and lower your insurance premium and maintenance costs and help you manage your monthly bills, like the one from the utility. \n\n Pick a direction you want to take, like lowering bills, or your home’s resilience, then save the projects you want to complete and run them by your preferred contractor to get an idea of the cost and complexity based on your unique situation.",
                            cta: "form_email_contactor"
                        },
                        {
                            headline: "Have you spent time looking at the how-to guides and articles related to your home?",
                            category: "review_resources",
                            body_header: "Get the Information You Need",
                            text: "It can be scary facing the unknown of projects, especially if you’re thinking about doing it yourself. Taking a few minutes to look at the how-to guides, maintenance plans and articles will give you a better idea of how big the project is and whether you want to tackle it yourself or hire a contractor. \n\n Relevant resources are sprinkled around your home analysis but to browse the hundreds of resources we have, head to our resources page.",
                            cta: "review_resources",
                            cta_text: "Go to Quoll Resources"
                        },
                        {
                            headline: "Have you reviewed all of the discounts you’re eligible for?",
                            category: "compile_discounts",
                            body_header: "Get Rewarded",
                            text: "Projects definitely take time, and usually mean spending some money. Luckily, depending on the project, you could save money on materials, lower your insurance costs and receive discounts on certain upgrades to your home!",
                            cta: "ask_quoll",
                            cta_text: "Review Discounts With Quoll"
                        },
                        {
                            headline: "Have you put together an action plan?",
                            category: "make_action_plan",
                            body_header: "Create Your Action Plan",
                            text: "Getting started on a project is just the beginning, but it can easily be the end if you don’t have a plan that aligns with your priorities, budget and goals. It’s important to think about the outcomes you want and plan a timeline and budget that will reasonably get you there. \n\n Not sure where to start? We can help!",
                            cta: "ask_quoll",
                            cta_text: "Get Help Creating Action Plan",
                        },
                        {
                            headline: "Have you gotten credit for the improvements you've already made?",
                            category: "get_credit",
                            body_header: "Ensure You're Not Leaving Money on the Table",
                            text: "Completing certain projects can actually lower your base insurance rate and make you eligible for additional discounts on your insurance policy. Sometimes it can even come down to the materials used for things like the roof. \n\n Mark completed projects for this home and take note of rewards you can get for those improvements! \n\n Want help with that process? Schedule time to chat with us!",
                            cta: "ask_quoll",
                            cta_text: "Review Discounts You May Be Eligable For"
                        },
                    ]
                }
            },
            journey: {
                title: "Where You Are on the Journey"
            },
            play_with_data: {
                button_text: "Play with the Data",
                title: "Data and Assumptions",
                description: "Updating data allows us to refine your analysis to give you the best insights possible. Data you provide is NEVER sold, shared, or in any other way made public, including to other users.",
                house_details_image_filename: "House-Plan.jpg",
                house_details_image_text: "House Details",
                house_details_cta: "Select a Part of the Home to View Data",
                experienced_image_filename: "House-Fire-Damage.jpg",
                experienced_image_text: "What You've Experienced",
                costs_and_finances_image_filename: "Houses-on-Coins.jpg",
                costs_and_finances_image_text: "Your Costs and Finances",
                image_cta: "Select a Category to View Data",
                update_analysis_button_text: "Update Analysis",
            }
        }
    };

    return text;
}