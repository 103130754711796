import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Sorry } from '../screens/Sorry/Sorry';

export class ErrorBoundary extends React.Component {
    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        console.error(error, info)
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback
        }
        return this.props.children
    }
}

export default ErrorBoundary;
