import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { SearchContext } from "../../services/SearchContext";
import { FormatSearchAddress } from "../../services/FormatSearchAddress";
import { windowSize } from "../../App";
import iconSearch from "../../assets/icons-search.png";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import "./style.css";

const placesLibrary = ["places"];

export const SearchBar = ({ header_flag = null, hero_flag = null }) => {

  const device = useContext(windowSize)

  const search_location = header_flag ? "Header" : "Non-Header";

  const { setGooglePlaceId } = useContext(SearchContext);
  const navigate = useNavigate();
  const { googlePlaceId } = useContext(SearchContext);

  const [searchResult, setSearchResult] = useState("Result: none");
  const [inputValue, setInputValue] = useState("");

  // Set the Google search boundaries
  const restrictions = {
    country: 'us',
}

  const options = {
    strictBounds: true,
  };

  // This listens to the search box and ensures that when a user clicks on an address the input field returns to blank
  useEffect(() => {
    // Set input value to empty string when searchResult changes
    if (searchResult === null) {
      setInputValue("");
    }
  }, [searchResult]);

  // This listens to the search box and ensures that when a user clicks away the input field returns to blank
  const [searchFocused, setSearchFocused] = useState(false);

  useEffect(() => {
    if (!searchFocused) {
      setInputValue("");
    }
  }, [searchFocused]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC8JrxYNCI1AY01RU4qqKUSjNor8Dj1s_E",
    libraries: placesLibrary
  });

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    const place = searchResult.getPlace();

    // if (searchResult != null) {
    if ("place_id" in place) {

      // Pull the place_id and the formatted address
      const ident = place.place_id;
      const new_formatted_address = FormatSearchAddress(place.formatted_address)

      // Navigate to the address page
      setGooglePlaceId(ident);
      navigate(`/house/${new_formatted_address}/_qid`);

      // Log the event with Google
      RecordEvent("Search", `Address Search - ${search_location}`, new_formatted_address)

    } else {
      alert("Please select an address from the menu to view the home!");
    }

    // Reset the input value after a place is selected
    setInputValue("");
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const placeholder = "Enter an address"

  return (
    <>
      <div className={"header-search-bar-item-container"}>
        <Autocomplete className={"google-auto-complete"} onPlaceChanged={onPlaceChanged} onLoad={onLoad} restrictions={restrictions} options={options}>
          <input
            className={"header-search-bar"}
            type="text"
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setSearchFocused(false)}

          />
        </Autocomplete>
        {!header_flag &&
          <div className={"header-search-bar-icon-container"}>
            <img className={"header-search-bar-icon"} src={iconSearch} />
          </div>
        }
      </div>
    </>
  );
};
