import React, { useContext } from "react";
import { RiskLabel } from "../RiskLabel";
import { ModalClimate } from "../../cards/ModalClimate/ModalClimate";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import "./style.css";


export const ClimateBlurb = ({

  toggle,
  setToggle,
  selected_risk,
  // cardType,
  // setCardType,
  riskLabelProperty1 = "High",

}) => {

  // Grab the navigation context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)

  // Grab the device size
  const deviceType = useContext(windowSize)

  const url = CreateCloudfrontUrl(selected_risk.icon_black)

  return (
    <>
      {(deviceType === "mobile" || deviceType === "tablet") &&
        <div className={"climate-blurb-frame-mobile"}>



          <div className={"climate-blurb-headline-container-mobile"}>

            <div className={"climate-blurb-image-container-mobile"}>
              <img className={"climate-blurb-image-mobile"} src={url} />
            </div>

            <p className={"climate-blurb-risk-name"}>{selected_risk.name}</p>

            <RiskLabel
              text={selected_risk.value}
            />

          </div>

          <p className={"climate-blurb-paragraph climate-blurb-paragraph-mobile"}>

            {selected_risk.blurb}

          </p>

          <span className={"span-m climate-blurb-expand-details"} onClick={() => {

            setToggle(true);
            setCardType("climate");
            setCardToggleStatus(true);
            setCardType("climate");
            setCardSelection(selected_risk.name);

          }}>
            Expand Details
          </span>
        </div>

      }
      {deviceType === "desktop" &&

        <div className={"climate-blurb-frame"}>

          <div className={"climate-blurb-image-container"}>

            <img className={"climate-blurb-image"} src={url} />

          </div>

          <div className={"climate-blurb-text-frame"}>

            <div className={"climate-blurb-headline-container"}>

              <p className={"climate-blurb-risk-name"}>{selected_risk.name}</p>

              <RiskLabel
                text={selected_risk.value}
              />

            </div>

            <p className={"climate-blurb-paragraph"}>

              {selected_risk.blurb}

            </p>

            <span className={"span-m climate-blurb-expand-details"} onClick={() => {

              setToggle(true);
              setCardType("climate");
              setCardToggleStatus(true);
              setCardType("climate");
              setCardSelection(selected_risk.name);

            }}>
              Expand Details
            </span>
          </div>
        </div>
      }
    </>
  );
};
