import React, { useEffect, useRef, useContext, useState } from "react";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
// import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { windowSize } from "../../App";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { NowWhatFirstView } from "../NowWhatFirstView";
import { NowWhatButton } from "../NowWhatButton";
import { NowWhatGuidance } from "../NowWhatGuidance";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import "./style.css";

export const WhatNowNavigator = React.createContext()

export const NowWhatContainer = () => {

  // Grab the navigation context
  const { cardToggleStatus, setCardToggleStatus, cardType, setCardType, cardSelection, setCardSelection } = useContext(UserExperienceNavigator)

  // Grab the user device
  const deviceType = useContext(windowSize)

  // Set the "Now What Do I Do" variables 
  const [nowWhatViewStatus, setNowWhatViewStatus] = useState(true);
  const [nowWhatFirstViewStatus, setNowWhatFirstViewStatus] = useState(true);

  // Grab the analysis
  const analysis = useContext(AnalysisDetails)

  // Get the address
  const address = analysis.address_details.full_address.split(",")[0]

  // Set up listeners for if the user hits escape or clicks outside the box
  const popupRef = useRef(null);

  const handleNowWhatClickaway = (event) => {

    // TURN THESE OFF WHEN YOU WANT TO DO TESTING ON THE INITIAL POPUP!!
    setNowWhatViewStatus(false);
    if (nowWhatFirstViewStatus) {
      // TURN THESE OFF WHEN YOU WANT TO DO TESTING ON THE INITIAL POPUP!!
      setNowWhatFirstViewStatus(false);
      // }
    }
    // Unsets Background Scrolling to use when SideDrawer/Modal is closed
    document.body.style.overflow = 'unset';
  };

  const handleNowWhatButtonClick = () => {
    setNowWhatViewStatus(true);
    // Disables Background Scrolling whilst the SideDrawer/Modal is open
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };

  // Set the document title
  useEffect(() => {
    if (cardToggleStatus) {
      // RecordEvent("Modal", "Card View", cardType)
    }
  }, [cardToggleStatus, cardType]);

  useEffect(() => {
    if (nowWhatViewStatus) {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setCardToggleStatus(false)
        }
      };

      const handleEscapeKey = (event) => {
        if (event.key === "Escape") {
          setCardToggleStatus(false)
        }
      };

      document.addEventListener("click", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);

      return () => {
        document.removeEventListener("click", handleClickOutside);
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }

  }, [nowWhatViewStatus]);

  return (
    <>
      <WhatNowNavigator.Provider value={handleNowWhatClickaway}>

        {nowWhatViewStatus &&
          <>
            <div className="now-what-background-overlay" ></div>
            <ClickAwayListener onClickAway={handleNowWhatClickaway}>

              <div className={`what-now-frame what-now-frame-first-view-${nowWhatFirstViewStatus} what-now-frame-${deviceType}`}>
                {/* <div className={`modal-exit-icon-container`} onClick={() => { setCardToggleStatus(false) }}> */}
                <div className={`modal-exit-icon-container`} onClick={handleNowWhatClickaway}>
                  <CancelSharpIcon style={{ fontSize: "2rem", position: "absolute", top: "-21px", left: "-28px", zIndex: 1500 }} className={"card-container-x-icon"} />
                </div>
                <div className={"modal-content-frame"}>
                  {nowWhatFirstViewStatus &&
                    <NowWhatFirstView />
                  }
                  {!nowWhatFirstViewStatus &&
                    <NowWhatGuidance />
                  }
                </div>
              </div>
            </ClickAwayListener>
          </>
        }
        {/* {!nowWhatViewStatus && */}
        <NowWhatButton handleClick={handleNowWhatButtonClick} onClick={handleNowWhatButtonClick} />
        {/* } */}
      </WhatNowNavigator.Provider>
    </>
  );
};
