import PropTypes from "prop-types";
import React, {useContext} from "react";
import { windowSize } from "../../App";
import { StartButton } from "../StartButton/StartButton";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";

export const TourStep = ({
  image=null,
  userDevice,
  context = "Understanding Risk Exposure",
  header = "See your home’s most pressing risks",
  description = "The Quoll dashboard gives you the big picture and the bottom line in one snapshot so you have all the information you need to understand your home’s current risks.",
  questions1 = "What risks will impact me the most financially?",
  question2 = "What parts of my home are in need of critical attention?",
  button
}) => {

  const mobile_image = CreateCloudfrontUrl(image)

  return (
    <div className={`tour-step-container ${userDevice == 'mobile' && 'tour-step-container-mobile'}`}>
      {image && 
      <div className="tour-step-image-container">
        <img className={'drop-shadow-standard tour-step-image'} src={mobile_image} />
      </div>
      }
      <div className="tour-step-header">
        <h3 className="tour-step-context accent-text">{context}</h3>
        <h2 className="tour-step-headline">{header}</h2>
      </div>
      <p className="tour-step-description">{description}</p>
      <div className="tour-step-questions-container">
          <p className="tour-step-question accent-text">{questions1}</p>
          <p className={`tour-step-question accent-text`}>{question2}</p>
      </div>
      {/* <StartButton text={button}/> */}
      <div className="tour-step-takeaways-button-frame">
        <h4 className="tour-step-takeaways-button-text">{button}</h4>
      </div>
    </div>
  );
};

TourStep.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
};
