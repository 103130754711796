import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import "./style.css";


export const Error = () => {

  const error_image = CreateCloudfrontUrl("flat-tire.png");


  return (
    <div className={'page-frame'}>

      <div className="page-container">

        <img className="hero-bar-image-mobile" alt="Image" src={error_image} />
        <h1>Sorry About That!</h1>
        <p className={"error-text"}>Looks like we had an error. Our team is working on it and should have it fixed shortly.</p>
      
      </div>


    </div>
  );
};
