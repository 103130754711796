import React, { useContext, useState, useEffect } from "react";
import { CostLabel } from "../CostLabel";
// import { formatDollarAmount } from "../../services/FormatDollarAmounts";
import { formatDollarAmount } from "../../services/FormatDollarAmounts";
// import { formatDollarAmount } from "../../services/FormatDollarAmounts";
import CountCompanyDiscounts from "../../services/CountCompanyDiscounts";
import { SkusResources } from "../../screens/TabletDesktop/TabletDesktop";
import lineDivider from "../../assets/line-8.png";
import "./style.css";

export const  CostList = ({
  project,
  style = {
    left: "unset",
    top: "unset",
  },
}) => {

  // Grab the total cost and total value to user
  const cost_text = "EST. COST";
  const cost_formatted = formatDollarAmount(project.total_cost)

  const value_text = "EST. VALUE";
  const value_formatted = formatDollarAmount(project.total_value)

  // Collect discounts master from the SKUs and resources
  const skus_resources = useContext(SkusResources);

  // Set the total discounts state that will get updated
  const [totalDiscounts, setTotalDiscounts] = useState(0)

  // Use Effect so that when we get the actual SKUs/resources we can pull out the discounts number
  useEffect(() => {
    if (skus_resources) {
      // Pull out the discounts master
      const discounts_master = skus_resources.discounts

      // Capture the discounts array from the project
      const project_discounts = project.discounts

      // Filter the master discounts to just what we need
      const filtered_discounts = discounts_master.filter(discount => (project_discounts.includes(discount.id)));

      // Get the count of total company discounts in that filtered discounts list
      const total_discounts = CountCompanyDiscounts(filtered_discounts);

      // Set the total discounts state with this value
      setTotalDiscounts(total_discounts)
    }
  }, [skus_resources]);


  const discounts_text = "DISCOUNTS";


  return (
    <div className={"cost-list-cost-list"} style={style}>
      <CostLabel
        elementStyle={{color: "#368347"}}
        header={value_text}
        value={value_formatted}
      />
      <img className={"cost-list-line"} src={lineDivider} />
      <CostLabel
        elementStyle={{color: "#fbb500"}}
        header={cost_text}
        value={cost_formatted}
      />
      {totalDiscounts > 0 &&
        <>
          <img className={"cost-list-line"} src={lineDivider} />
          <CostLabel
            elementStyle={{color: "#368347"}}
            header={discounts_text}
            value={totalDiscounts}
          />
        </>
      }
    </div>
  );
};
