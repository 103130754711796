import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import { FilterProjects } from "../../services/FilterProjectsBySelection"
import { HubProjectsFilterBar } from "../HubProjectsFilterBar";
import { HubProjectsCollection } from "../HubProjectsCollection";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { UserStatus } from "../../services/UserMgt";
import { StaticText } from "../../services/StaticText";

export const HubProjects = () => {

  // Get the static text
  const static_text = StaticText()
  const hub_projects_static_text = static_text.hub.projects
  const intro_text = hub_projects_static_text.intro_text
  const not_signed_in_cta = hub_projects_static_text.not_signed_in_cta
  const signed_in_no_saved_projects_cta = hub_projects_static_text.signed_in_no_saved_projects_cta
  const main_title = hub_projects_static_text.title
  const rec_section_title = hub_projects_static_text.rec_section_title
  const browse_section_title = hub_projects_static_text.browse_section_title
  
  // Get the device type
  const deviceType = useContext(windowSize);

  // Collect the analysis and house ID
  const analysis = useContext(AnalysisDetails);
  const address_id = analysis ? analysis.address_id : null;

  // Collect the projects from the analsysis
  const projects = analysis ? analysis.projects : null;

  // Get the user id
  const { userId, userSavedHomes, setUserSavedHomes } = useContext(UserStatus);

  // If the user has saved this home then filter it out from the saved homes
  const this_home_saved = userSavedHomes ? userSavedHomes.filter(home => home.address_id === address_id) : [];
  const this_home_saved_projects = this_home_saved.length > 0 ? this_home_saved[0].saved_projects : [];

  // If the user has saved this home and if there are saved projects then pull those out of the master projects array
  const user_saved_projects_for_this_home = this_home_saved_projects.length > 0 ? projects.filter(project => this_home_saved_projects.includes(project.id)) : [];

  // Create the filters
  const [riskFilter, setRiskFilter] = useState(null)
  const [homeAreaFilter, setHomeAreaFilter] = useState(null)

  // Set the initial selection between card and list  
  const [projectView, setProjectView] = useState("card")

  // Set the toggle state between the different project views
  // View options are: rec, payoff, diy, inexpensive, roi, disruption, sustainable, health
  const [projectsCategoryView, setProjectsCategoryView] = useState("payoff")

  // Get the number of projects
  const number_of_projects = projects ? projects.length : 0;

  // Get the recommended projects
  const recommended_projects = projects ? projects.filter(project => project.hub_category.includes("rec")) : [];

  // Get the recommended projects
  const high_payoff_projects = projects ? projects.filter(project => project.hub_category.includes("payoff")) : [];

  // Get the recommended projects
  const diy_projects = projects ? projects.filter(project => project.hub_category.includes("diy")) : [];

  // Get the recommended projects
  const inexpensive_payoff_projects = projects ? projects.filter(project => project.hub_category.includes("inexpensive")) : [];

  // Get the recommended projects
  const low_disruption_projects = projects ? projects.filter(project => project.hub_category.includes("disruption")) : [];

  // Get the recommended projects
  const sustainability_projects = projects ? projects.filter(project => project.hub_category.includes("sustainable")) : [];

  // Get the recommended projects
  const health_projects = projects ? projects.filter(project => project.hub_category.includes("health")) : [];

  // Filter the projects when showing all
  const filtered_projects = FilterProjects(projects, riskFilter, homeAreaFilter)

  // Icons
  const high_payoff_icon = "high-financial-benefit-icon.png"
  const high_payoff_icon_url = CreateCloudfrontUrl(high_payoff_icon)
  const inexpensive_icon = "inexpensive-projects-icon.png"
  const inexpensive_icon_url = CreateCloudfrontUrl(inexpensive_icon)
  const diy_icon = "diy-projects-icon.png"
  const diy_icon_url = CreateCloudfrontUrl(diy_icon)
  const disruption_icon = "lounging-icon.png"
  const disruption_icon_url = CreateCloudfrontUrl(disruption_icon)
  const sustainable_icon = "sustainable-projects-icon.png"
  const sustainable_icon_url = CreateCloudfrontUrl(sustainable_icon)
  const health_icon = "protect-health-icon.png"
  const health_icon_url = CreateCloudfrontUrl(health_icon)
  const all_icon = "see-all-icon.png"
  const all_icon_url = CreateCloudfrontUrl(all_icon)
  const saved_icon = "saved-projects-icon.png"
  const saved_icon_url = CreateCloudfrontUrl(saved_icon)

  // The different toggle options and the projects that go with them
  const projects_view_toggle_options = [
    { "category": "payoff", "label": "Largest $ Benefits", "projects": high_payoff_projects, icon: high_payoff_icon_url },
    { "category": "inexpensive", "label": "Low Cost, High Impact", "projects": inexpensive_payoff_projects, icon: inexpensive_icon_url },
    { "category": "diy", "label": "DIY Friendly", "projects": diy_projects, icon: diy_icon_url },
    { "category": "disruption", "label": "Least Disruptive", "projects": low_disruption_projects, icon: disruption_icon_url },
    { "category": "sustainable", "label": "Sustainability Minded", "projects": sustainability_projects, icon: sustainable_icon_url },
    { "category": "health", "label": "Health Minded", "projects": health_projects, icon: health_icon_url },
    { "category": "all", "label": "All Projects", "projects": filtered_projects, icon: all_icon_url },
  ]

  // Handle toggling between the different views
  const handleProjectsViewToggle = (category) => {
    setProjectsCategoryView(category)
  }

  // Set the state for what projects to show the user
  const [projectsToShow, setProjectsToShow] = useState([])

  // Handle toggling between the different views
  useEffect(() => {

    const projects_based_on_toggle_choice = projects ? projects_view_toggle_options.filter(category => category.category === projectsCategoryView)[0].projects : [];
    console.log(projects_based_on_toggle_choice)
    setProjectsToShow(projects_based_on_toggle_choice)

  }, [projects, projectsCategoryView]);

  // The text for the different sections
  // const intro_text = "Based on our analysis of this specific home and it's risk profile, we have identified a number of projects that we believe can help to lower your risk and save money, today and into the future."
  // const where_to_start_text = "You might be wondering where to start. Well, if this was our house, these are the projects we would suggest you look at first."

  // const not_signed_in_cta = "Sign in to save projects to your account and get personalized recommendations for your home."
  // const signed_in_no_saved_projects_cta = "You haven't saved any projects for this home yet. Click the bookmark icon on any project to save it to your account."


  return (
    <>
      <div className="hub-selection-frame">

        <h2 className={"hub-selection-title-text"}>
          {main_title}
        </h2>
        <p className={"hub-selection-title-context"}>
          {intro_text}
        </p>
        <h3 className={"hub-financial-subsection-header"}>{rec_section_title}</h3>
        <HubProjectsCollection
          projectView="recs"
          projects={recommended_projects}
        />
        <div className="page-content-horizontal-divider-full"></div>
        <h3 className={"hub-financial-subsection-header"}>{browse_section_title}</h3>
        <div className="hub-projects-category-toggle-bar">
          {projects_view_toggle_options.map(option => {
            return (
              <>
                <React.Fragment>
                  <div
                    // className={`hub-projects-category-toggle-button ${projectsCategoryView === option.category ? "box-shadow-standard" : null}`}
                    className={`hub-projects-category-toggle-button`}
                    onClick={() => handleProjectsViewToggle(option.category)}
                  >
                    <img src={option.icon} className={"hub-projects-category-toggle-button-icon"} />
                    <span className={"span-s hub-projects-category-toggle-button-text"}>{option.label}</span>
                    <div className={`page-content-horizontal-divider-full-tall ${projectsCategoryView !== option.category && 'no-show'}`}></div>

                  </div>
                </React.Fragment>
              </>
            )
          })}
        </div>
        {projectsCategoryView == "all" &&
          <HubProjectsFilterBar
            deviceType={deviceType}
            riskFilter={riskFilter}
            setRiskFilter={setRiskFilter}
            homeAreaFilter={homeAreaFilter}
            setHomeAreaFilter={setHomeAreaFilter}
            projects={projects}
            filtered_projects={filtered_projects}
            setProjectView={setProjectView}
            projectView={projectView}
          />
        }
        {projectsToShow.length > 0 &&
          <HubProjectsCollection
            projectView="regular"
            projects={projectsToShow}
          />
        }
        {(projectsCategoryView !== "saved" && projectsToShow.length === 0) &&
          <p className={"text-align-center"}>Looks like this house doesn't have any projects for this category! That said, we're always adding more things so be sure to check back.</p>
        }

        {(projectsCategoryView === "saved" && !userId) &&
          <p className={"text-align-center"}>{not_signed_in_cta}</p>
        }

        {(projectsCategoryView === "saved" && userId && user_saved_projects_for_this_home.length === 0 ) &&
          <p className={"text-align-center"}>{signed_in_no_saved_projects_cta}</p>
        }

      </div>
    </>
  );

};
