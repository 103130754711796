import React, { useContext } from "react";
import { HubProjectsCard } from "../HubProjectsCard";
import { HubProjectBar } from "../../components/HubProjectBar/HubProjectBar";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import "./style.css";

export const HubProjectsCollection = ({
  projects,
  projectView,

}) => {

  const text_for_why_this_project = {
    payoff:"Large Financial Benefit",
    diy: "DIY Friendly",
    inexpensive: "Inexpensive to Do",
    disruption: "Low Disruption to Daily Life",
    sustainable: "Be More Sustainable",
    health: "Protects Health",
    roi: "High Payoff to Cost Ratio"
  }

  return (
    <>

      <div className={'project-cards-frame'}>
        {projectView == "regular" &&
          projects.map(project => {
            return (
              <HubProjectsCard
                key={project.id}
                project={project}
              />
            )
          })
        }
        {projectView == "recs" &&
          projects.map(project => {
            return (
              <div className={"recommended-project-container"}>

                <HubProjectsCard
                  key={project.id}
                  project={project}
                />
                <span className={'span-s recommended-project-why-this-project-text'}>Why This Project?</span>
                <div className="recommended-project-checklist-container">
                  {project.hub_category.map(category => {

                    const category_to_show = category !== "rec" ? category : null;

                    return (
                      <>
                        {category_to_show &&
                          <div className="recommended-project-checklist-item">
                            <CheckCircleSharpIcon className={"recommended-project-checklist-icon"} />
                            <span className={'span-xs'}>{text_for_why_this_project[category_to_show]}</span>
                          </div>
                        }
                      </>
                    )
                  })}
                </div>
              </div>
            )
          })
        }
      </div >

    </>
  );

};
