import axios from 'axios';

export const getResourcesandSkus = async (data) => {

    // console.log(data.discounts_list);
    const request_param = {
        skus: data.skus,
        resources: data.resources_list,
        discounts: data.discounts_list
    };

    try {
        // const response = await axios.post('https://1nl7eg7oui.execute-api.us-east-2.amazonaws.com/test/resoures-skus', request_param);
        const response = await axios.post('/api/resoures-skus', request_param);
        
        // During testing
        // return skus_data.data;
        
        // Production
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;  // handle error appropriately
    }
};
