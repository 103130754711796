import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { YoutubeEmbed } from "../../cards/YoutubeEmbed";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import {ClickAwayListener} from '@mui/base/ClickAwayListener';
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import "./style.css";

export const ProjectCardResources = ({
  style,
  resources,
  page=null,
}) => {

  // Grab navigation details from context
  const setCardToggleStatus = useContext(UserExperienceNavigator)


  // Is the media play open or closed
  const [videoStatus, setVideoStatus] = useState(false);
  const [activeVideoId, setActiveVideoId] = useState(null);

  const handleVideoClick = videoId => {
    setActiveVideoId(videoId);
    setVideoStatus(true);
  };

  const [active, setActive] = useState(false);

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
  };

  const handleClickAway = () => {
    setVideoStatus(false);
  };

  const handleBlogClick = () => {
    setCardToggleStatus(false);
  };

  // A Wrapper component
  const Wrapper = ({ resource, children }) => {
    if (typeof resource.slug !== "undefined") {
      return (
        <Link onClick={handleBlogClick} className="resource-wrapper" to={`/resources/${resource.slug}`}>
          {children}
        </Link>
      );
    }

    if (typeof resource.youtube_id !== "undefined") {
      return (
        <div className="resource-wrapper" onClick={() => handleVideoClick(resource.youtube_id)}>
          {children}
        </div>
      );
    }

    // return children as is if no match
    return children;
  };

  return (

    <div className={`project-card-resources-container`}>
      {/* <div className={"project-card-resources-header"}>
        Learn
      </div> */}
      <div className={`${page ? 'resource-cards-justify-center' : 'resource-cards-justify-left'} project-card-resources-cards-frame`}>
        {resources.map(resource => {

          const category = resource.category;
          const sub_category = resource.sub_category;
          const categories_array = [category, sub_category];
          const associated_risks = resource.associated_climate_risks;
          const hastag_array = categories_array.concat(associated_risks);
          const url = typeof resource.image !== "undefined" ? CreateCloudfrontUrl(resource.image) : null


          return (
            <Wrapper key={resource.id} resource={resource}>
              <article className={`resource-card-article-frame resource-card-background-${style} box-shadow-standard`}>
                {typeof resource.slug !== "undefined" &&
                  <img
                    className={"resource-card-thumbnail"}
                    src={url}
                  />
                }
                {typeof resource.youtube_id !== "undefined" &&
                  // <YoutubeEmbed embedId={resource.youtube_id} status="click-disabled" />
                  <>
                    <img
                      className={"resource-card-thumbnail"}
                      src={url}
                    />
                    <div className="video-play-container">
                      <PlayArrowSharpIcon boxShadow="2" fontSize="inherit" className={"video-play-icon"} />
                    </div>
                  </>

                }
                <h4 className={"resource-card-text"}>{resource.title}</h4>
                <span className={"span-xs resource-card-hashtags"}>
                  {hastag_array.map(risk => {

                    return (
                      "#" + risk + "   "
                    )
                  })}
                </span>
              </article>
            </Wrapper>
          )
        })}
      </div>
      {videoStatus &&
        <>
          <div className="video-player-overlay"></div>
          <ClickAwayListener onClickAway={handleClickAway}>

            <div className="video-player-container">
              <div className="video-player-close-button-container">
                <span className={"video-player-close-button"} onClick={handleClickAway}>X</span>
              </div>
              <YoutubeEmbed embedId={activeVideoId} status="click-enabled" />
            </div>
          </ClickAwayListener>
        </>

      }
    </div>

  );
};
