import React, { useState, useEffect, useContext, Fragment } from "react";
import { HubHouseProjects } from "../../components/HubHouseProjects";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { windowSize } from "../../App";
import "./style.css";

export const HubHouse = ({

  sorted_projects,

}) => {

  const new_hub_house_image_filename = "house-lansdcape.png"
  const new_hub_house_image_url = CreateCloudfrontUrl(new_hub_house_image_filename)

  const deviceType = useContext(windowSize)

  const valueOrder = {
    High: 0,
    Medium: 1,
    Low: 2,
  };

  // const value_sorted_projects = sorted_projects ? sorted_projects.sort((a, b) => valueOrder[a.value] - valueOrder[b.value]) : null;

  // Sort alphabetically first, then by value
  const value_sorted_projects = sorted_projects
    ? sorted_projects.sort((a, b) => {
      // Compare values first
      const valueComparison = valueOrder[a.value] - valueOrder[b.value];
      if (valueComparison !== 0) {
        return valueComparison;
      }

      // If values are the same, compare names alphabetically
      return a.name.localeCompare(b.name);
    })
    : null;


  return (
    <>
      {/* <div className={`climate-factors-home-hub-container`}> */}
      <div className={`${deviceType !== "desktop" ? "climate-factors-home-hub-container-mobile" : "climate-factors-home-hub-container"}`}>

        <img className={"climate-factors-home-hub-image"} src={new_hub_house_image_url} />

        {value_sorted_projects && value_sorted_projects.map(project => (

          <HubHouseProjects
            key={project.id}
            project={project}
            device={deviceType}
            location="overlay"
          />

        ))}
      </div>
      {deviceType !== "desktop" &&
        <div className="climate-factors-mobile-list-container">
          {value_sorted_projects && value_sorted_projects.map(project => (
            <Fragment>

              <HubHouseProjects
                key={project.id}
                project={project}
                device={deviceType}
                location="list"
              />
            </Fragment>

          ))}
        </div>
      }
    </>
  );

};
