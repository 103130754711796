export function GetFilteredResourcesBySearch(resourcesArray,searchTerm) {
    if (searchTerm === '') {
        // If search term is empty, return all resources
        return resourcesArray;
    }

    return resourcesArray.filter((resource) => {
        // Split the search term into words and make it case-insensitive
        const searchWords = searchTerm.toLowerCase().split(' ');
        // Normalize the title and make it case-insensitive
        const resourceTitle = resource.title.toLowerCase();

        // Check if all search words are present in the title
        return searchWords.every((word) => resourceTitle.includes(word));
    });

};