import React from "react";
import "./style.css";

export const RiskLabel = ({
  style,
  text,
  property1,
  text1 = "MEDIUM"
}) => {

  const rating_text = text + " Risk"
  const lower_case_risk_level = text.toLowerCase()

  return (
    <div className={`climate-blurb-risk-level-container background-color-${lower_case_risk_level}-risk`}
      // style={style}
    >
      <span className={"span-l climate-blurb-risk-level-text"}>
        {text}
      </span>
    </div>
  );
};
