import { Button } from "../../components/Button";
import "./style.css";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { useContext } from "react";
import { SaveShareBar } from "../SaveShareBar";

export const Title = () => {

  const analysis = useContext(AnalysisDetails);

  const address = (analysis != null) ? analysis.address_details.full_address : "LOADING...";

  const report_header_style = {
    fontSize: "clamp(2rem, 2.5vw, 4.5rem) !important",
  }

  return (
    <div className={"report-title-title"}>
      <div className={"report-title-text-frame"}>

        <h1 className={"font-global report-address-text"} style={report_header_style}>
          {address}
        </h1>
      </div>
      <div className="report-title-share-save-frame">

        <SaveShareBar />
      </div>

    </div>
  );
};
