import React from "react";
import "./style.css";

export const RiskStat = ({
  stat,
}) => {
  // console.log(stat);
  return (
    <div className={"risk-stat-risk-stat"}>
      <span className={"span-xl risk-stat-element"}>
        {stat.stat}
      </span>
      <span className={"span-m risk-stat-chance-of-experiencing-tornado-level-wind"}>
        {stat.headline}
      </span>
    </div>
  );
};
