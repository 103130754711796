// import { createGlobalState } from 'react-hooks-global-state'


// const { setGlobalState, useGlobalState } = createGlobalState({
//   cardToggle: false,
//   cardType: null,
//   cardSelection: null,
//   address: '11 Dows Lane',
//   score: 'F',
//   projects: [
//     { id: 1, name: "Install Manual Covers on Vents", risk: "Hurricane", house_area: "Roofing", cost: "230", "value": "1350", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 3] },
//     { id: 2, name: "Project 2", risk: "Storm", house_area: "Windows", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 3, name: "Project 3", risk: "Wind", house_area: "Siding", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 4, name: "Project 4", risk: "Hurricane", house_area: "Doors", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 5, name: "Install Roof Underlayment", risk: "Storm", house_area: "Roofing", cost: "650", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 6, name: "Blah blah blah ya ya ya", risk: "Wind", house_area: "Electrical", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 7, name: "Blah blah blah ya ya ya", risk: "Hurricane", house_area: "Windows", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 8, name: "Blah blah blah yproduce_a ya ya", risk: "Storm", house_area: "Siding", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 9, name: "Blah blah blah ya ya ya", risk: "Wind", house_area: "Doors", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 10, name: "Blah blah blah ya ya ya", risk: "Hurricane", house_area: "Foundation", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 11, name: "Roofing 3", risk: "Wind", house_area: "Roofing", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 12, name: "Blah blah blah", risk: "Storm", house_area: "Foundation", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 13, name: "Blah blah blah", risk: "Hurricane", house_area: "Electrical", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 14, name: "Blah blah blah", risk: "Wind", house_area: "Doors", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 15, name: "Blah blah blah", risk: "Storm", house_area: "Windows", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 16, name: "Blah blah blah", risk: "Hurricane", house_area: "Siding", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 17, name: "Roofing 4", risk: "Wind", house_area: "Roofing", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 18, name: "Blah blah blah", risk: "Storm", house_area: "Doors", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 19, name: "Blah blah blah", risk: "Hurricane", house_area: "Windows", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 20, name: "Blah blah blah", risk: "Wind", house_area: "Siding", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 21, name: "Roofing 5", risk: "Storm", house_area: "Roofing", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 22, name: "Blah blah blah", risk: "Hurricane", house_area: "Electrical", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 23, name: "Blah blah blah", risk: "Wind", house_area: "Foundation", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 24, name: "Blah blah blah", risk: "Storm", house_area: "Windows", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 25, name: "Blah blah blah", risk: "Hurricane", house_area: "Doors", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 26, name: "Blah blah blah", risk: "Wind", house_area: "Siding", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 27, name: "Blah blah blah", risk: "Storm", house_area: "Electrical", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 28, name: "Blah blah blah", risk: "Hurricane", house_area: "Windows", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 29, name: "Roofing 6", risk: "Wind", house_area: "Roofing", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 30, name: "Blah blah blah", risk: "Storm", house_area: "Foundation", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },
//     { id: 31, name: "Blah blah blah", risk: "Wind", house_area: "Windows", cost: "550", "value": "1050", "icon": "/img/roof-vent.jpeg", "complexity": .82, "disruption": .22, "impact": .58, "skus": [{ id: 1, cost: 780 }, { id: 2, cost: 750 }, { id: 3, cost: 690 }], associated_resources: [1, 2, 5] },

//   ],
//   total_projects: 30,
//   financial_takeaways: [
//     { headline: "Estimated Yearly Maintenance Costs", value: "$7500", description: "Top concerns: HVAC, Foundation, General Property" },
//     { headline: "Estimated Annual Rate Increase", value: "+7-8%", description: "How much annual rates in your area might increase due to Climate factors—even if your home isn’t impacted" },
//     { headline: "Impact on Long Term Value", value: "Medium", description: "Likelihood of your home no longer being affordable in the long term due to climate factors" },
//   ],
//   hub_navigation: [
//     { hub_header: "Home Overview", "type": "overview", "toggle": "Overview of Risks", "button": "Overview of your risk" },
//     { hub_header: "Financial Impact", "type": "financial", "toggle": "Financial Impact", "button": "See the financial impact of these risks" },
//     { hub_header: "Projects You Can Do", "type": "projects", "toggle": "All Projects ", "button": "See all projects to lower the risk" },
//   ],
//   risks: [
//     { name: "Hurricane", value: "HIGH", level: "High", blurb: "Though CT is not traditionally thought of as threatened by hurricanes (typically called Nor'easters when they make it this far north), they are becoming more and more frequent. When combined with a general increase in thunderstorms, these types of destructive events are a major continuous threat. Remember, this doesn't include convection storms, tornados, or other wind events.", stats: [{ stat: "5005", description: "Feet from the coast" }, { stat: "14%", description: "Chance of direct-hit by Hurricane with wind gusts of at least 58 mph in the next 10 years" }] },
//     { name: "Wind", value: "MEDIUM", level: "Medium", blurb: "Wind and wind damage are often lumped into other risks, but increasingly it is a factor all on its own. Below are the parts of the house most affected.", stats: [{ stat: "45", description: "Days with gusts above 45mph" }, { stat: "2%", description: "Chance of tornado level damage" }] },
//     { name: "Storm", value: "MEDIUM", level: "Medium", blurb: "Thunderstorms are becoming more powerful and can bring high levels of destruction. Below are the parts of the house most affected.", stats: [{ stat: "12", description: "Major storms in a given year" }, { stat: "19%", description: "Chance of lightning hitting the house" }] },
//   ],
//   icons: {
//     Hurricane: {
//       black: "/img/icons-climate-33.png",
//       white: "/img/icons-climate-34.png"
//     },
//     Wind: {
//       black: "/img/icons-climate-50.png",
//       white: "/img/icons-climate-51.png"
//     },
//     Storm: {
//       black: "/img/icons-climate-54.png",
//       white: "/img/icons-climate-55.png"
//     },
//     Windows: "/img/icons-home-1.png",
//     Doors: "/img/icons-home-2.png",
//     Foundation: "/img/icons-home-6.png",
//     Roofing: "/img/icons-home-7.png",
//     Electrical: "/img/icons-home-3.png",
//     Siding: "/img/icons-home-4.png",
//     GeneralProperty: "/img/icons-home-8.png"
//   },
//   house_area: {
//     Windows: "HIGH",
//     Doors: "MEDIUM",
//     Foundation: "HIGH",
//     Roofing: "MEDIUM",
//     Electrical: "HIGH",
//     Siding: "MEDIUM",
//     GeneralProperty: "MEDIUM"
//   },
//   vendors: {
//     1: { name: "Home Depot", icon: "/img/lowes.png" },
//     2: { name: "Lowes", icon: "/img/home-depot.png" },
//     3: { name: "Menards", icon: "/img/menards.png" },
//     4: { name: "Ace", icon: "/img/ace.png" },

//   },
//   skus: {
//     1: { vendor: 1, description: "Plastic roof vent", url: "espn.com" },
//     2: { vendor: 2, description: "Metal roof vent", url: "google.com" },
//     3: { vendor: 3, description: "Carbon fiber roof vent", url: "cnn.com" },
//   },

//   resources: [
//     { id: 1, title: "What is an Impact Resistant Shingle and How Does it Prevent Roof Damage?", thumbnail: "/img/frame-117.png", url: "google.com" },
//     { id: 2, title: "GAF Storm | How to identify hail damage to a roof", thumbnail: "/img/frame-117-1.png", url: "espn.com" },
//     { id: 3, title: "Testing resource #3", thumbnail: "/img/frame-117.png", url: "cnn.com" }
//   ],
//   financial_data: [{ name: "Est. Today", Insurance: 8000, Taxes: 2400, Maintenance: 2400 }, { name: "1", Insurance: 3000, Taxes: 3398, Maintenance: 2210 }, { name: "2", Insurance: 2000, Taxes: 6800, Maintenance: 2290 }, { name: "3", Insurance: 2780, Taxes: 3908, Maintenance: 2000 }, { name: "4", Insurance: 1890, Taxes: 8800, Maintenance: 2181 }, { name: "5", Insurance: 2390, Taxes: 3800, Maintenance: 2500 }, { name: "6", Insurance: 3490, Taxes: 4300, Maintenance: 2100 }, { name: "7", Insurance: 2000, Taxes: 4800, Maintenance: 2290 }, { name: "8", Insurance: 2780, Taxes: 3908, Maintenance: 2000 }, { name: "9", Insurance: 1890, Taxes: 4800, Maintenance: 2181 }, { name: "10", Insurance: 2390, Taxes: 3800, Maintenance: 6500 }
//   ],
//   affordability_form_data: [
//     {name: 'insurance', type: 'number', headline: "Home Insurance", value: 235},
//     {name: 'maintenance', type: 'number', headline: "Home Maintenance", value: 1300},
//     {name: 'taxes', type: 'number', headline: "Property Taxes", value: 1800},
// ]

// });