  export function GetProjectSkus(project_skus, skus,resources,companies) {
    
    const combinedData = project_skus.map(project_sku => {
      
      const company = companies.filter(company => company.id === project_sku.sold_by)[0];
      const relevant_sku = skus.filter(sku => sku.id === project_sku.id)[0];
      
      return {
        id: project_sku.id,
        cost: project_sku.cost,
        vendor: project_sku.sold_by,
        vendor_name: company.name,
        vendor_icon: company.icon,
        description: relevant_sku.product_name,
        url: relevant_sku.url
      };
    });
    return combinedData;
  }