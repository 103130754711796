import React, {useState, useEffect, useContext} from "react";
// import { AboutUs } from "../../components/AboutUs";
// import { DeepBreath } from "../../components/DeepBreath";
// import { Footer } from "../../components/Footer";
// import { Frame } from "../../components/Frame";
// import { Header } from "../../components/Header";
// import { Hero } from "../../components/Hero";
// import { PageHeading } from "../../components/PageHeading";
// import { Tour } from "../../components/Tour";
// import { ValueProp } from "../../components/ValueProp";
// import { Icon10 } from "../../icons/Icon10";
// import { Icon9 } from "../../icons/Icon9";
// import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { Hero } from "../../components/Hero";
import { ValueProp } from "../../components/ValueProp";
import { Tour } from "../../components/Tour/Tour";
import { DeepBreath } from "../../components/DeepBreath/DeepBreath";
import { AboutUs } from "../../components/AboutUs/AboutUs";
import { UserStatus } from "../../services/UserMgt";
import "./style.css";

export const userWindowSize = React.createContext()

export const HomePage = () => {

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);
  
  // Set the document title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Quoll: Your Home's Climate Risk, Translated";
    setPageTitle("Home");
  }, []);
  
  const [deviceType, setDeviceType] = useState('desktop');

  function getDeviceType() {
    const width = window.innerWidth;
    if (width < 768) {
      return 'mobile';
    } else if (width >= 768 && width < 1024) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setDeviceType(getDeviceType());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className={"page-frame"}>
      <div className="page-container">
      <userWindowSize.Provider value={deviceType}>
        <Hero />
        <ValueProp />
        <Tour />
        <DeepBreath />
        <AboutUs />
      </userWindowSize.Provider>
      </div>
    </div>
  );
};