import React, { useState, useEffect, useContext } from "react";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { FinancialDatapoint } from "../../components/FinancialDatapoint";
import { GradientBar } from "../../components/GradientBar";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const HubValue = () => {

    // Grab the analysis from context
    const analysis = useContext(AnalysisDetails);

    // Get the address for the section title
    const address = analysis ? analysis.address_details.full_address.split(",")[0] : null;

    // Set the LTV data
    const [ltvData, setLtvData] = useState(null)

    // Set the ltv stats state
    const [ltvStats, setLtvStats] = useState(null)

    // Create the data category state
    const [selectedDataCategory, setSelectedDataCategory] = useState(null);
    const [selectedDataCategoryInfo, setSelectedDataCategoryInfo] = useState(null);
    const [relatedDataCategories, setRelatedDataCategories] = useState([]);

    // When analysis is actually received set the ltv stats
    useEffect(() => {
        if (analysis) {
            setLtvData(analysis.long_term_value)
            setLtvStats(analysis.long_term_value.stats);
            setSelectedDataCategory("3");
        }
    }, [analysis]);

    // Get the sample data (WILL REPLACE ONCE BACKEND ALIGNED)

    // When selected data category changes get the data for that category    
    useEffect(() => {
        if (selectedDataCategory) {

            const data_from_selected_category = ltvStats.find(element => element.id === selectedDataCategory);
            // const data_from_selected_category = longTermValueData.category_stats.find(element => element.id === selectedDataCategory);
            setSelectedDataCategoryInfo(data_from_selected_category);
        } else {
            setSelectedDataCategoryInfo(null);
        }
    }, [selectedDataCategory]);

    
    // Create the value hub header
    const value_hub_header = `Does Climate Risk Hurt ${address}'s Value?`
    
    // Get the static text
    const static_text = StaticText();
    const ltv_text = static_text.hub.financial_impact.ltv;

    // Pull the relevant text from the static text

    const ltv_hub_intro_paragraph = ltv_text.ltv_hub_intro_paragraph
    const rule_of_thumb_title = ltv_text.rule_of_thumb_title
    const rule_of_thumb_text = ltv_text.rule_of_thumb_text
    const understanding_the_data_title = ltv_text.understanding_the_data_title
    const assessment_title = ltv_text.assessment_title
    const ltv_takeaway_general = ltv_text.ltv_takeaway_general
    const ltv_takeaway_less_than_5 = ltv_text.ltv_takeaway_less_than_5
    const ltv_takeaway_less_than_10 = ltv_text.ltv_takeaway_less_than_10
    const ltv_takeaway_more_than_10_no_flag = ltv_text.ltv_takeaway_more_than_10_no_flag
    const ltv_takeaway_more_than_10_flag = ltv_text.ltv_takeaway_more_than_10_flag
    const ltv_takeaway_conclusion = ltv_text.ltv_takeaway_conclusion

    // Images 
    const broken_home_image_filename = ltv_text.broken_home_image_filename
    const home_bills_image_filename = ltv_text.home_bills_image_filename
    const neighborhood_image_filename = ltv_text.neighborhood_image_filename
    const broken_home_image = CreateCloudfrontUrl(broken_home_image_filename)
    const home_bills_image = CreateCloudfrontUrl(home_bills_image_filename)
    const neighborhood_image = CreateCloudfrontUrl(neighborhood_image_filename)

    // Get the tipping point and risk flag
    const tipping_point = ltvData ? ltvData.ltv_tipping_points : null;
    const tipping_point_risk_flag = ltvData ? ltvData.ltv_score_risk_mismatch : null

    // LTV Takeaway

    // Determine the right takeaway
    let ltv_takeaway_sentence;

    if (tipping_point > 10 && tipping_point_risk_flag) {
        ltv_takeaway_sentence = ltv_takeaway_more_than_10_flag
    } else if (tipping_point > 10 && !tipping_point_risk_flag) {
        ltv_takeaway_sentence = ltv_takeaway_more_than_10_no_flag
    } else if (tipping_point >= 5) {
        ltv_takeaway_sentence = ltv_takeaway_less_than_10
    } else if (tipping_point < 5) {
        ltv_takeaway_sentence = ltv_takeaway_less_than_5
    } else {
        ltv_takeaway_sentence = ltv_takeaway_general
    }


    


    return (

        <div className={"hub-finaicial-section-frame box-shadow-light"}>
            <h3 className={'hub-financial-subsection-header'}>
                {value_hub_header}
            </h3>
            <div className="value-hub-intro-container">
                <div className="value-hub-factors-container">
                    <div className="value-hub-condition-bills-container">
                        <div className="value-hub-condition-bills-images-container">

                            <div className="value-hub-condition-bills-two-images-container">
                                <img className={"value-hub-condition-image"} src={broken_home_image} />
                                <img className={"value-hub-condition-image"} src={home_bills_image} />
                            </div>

                        </div>
                        <span className={"italic-text hub-finance-icons-text"}>Home Condition + Expenses</span>
                    </div>

                    <div className="value-hub-condition-bills-container">
                        <div className="value-hub-condition-bills-images-container">
                            <div className="value-hub-condition-image-container">
                                <img className={"value-hub-condition-image"} src={neighborhood_image} />
                            </div>
                        </div>
                        <span className={"italic-text hub-finance-icons-text"}>Everything Around You</span>
                    </div>
                </div>
                <p className={"value-hub-intro-paragraph"}>{ltv_hub_intro_paragraph}</p>
                <div className={'affordability-hub-title-container box-shadow-standard'}>
                    <h4 className={'affordability-hub-title-text'}>
                        {rule_of_thumb_title}
                    </h4>
                    <span className={'hub-financial-section-keep-in-mind-text'}>
                        {rule_of_thumb_text}
                    </span>
                </div>
            </div>
            {/* <div className="page-content-horizontal-divider-full"></div> */}

            <div className="page-content-horizontal-divider-full"></div>
            <h3 className={"hub-financial-subsection-header"}>
                {understanding_the_data_title}
            </h3>
            <div className={"hub-value-data-category-choices-container"}>
                <div className="hub-value-data-choices-grid">
                    {ltvStats && ltvStats.map(category => {
                        const imageURL = CreateCloudfrontUrl(category.image)
                        return (
                            <div
                                id={category.id}
                                className={`hub-value-individual-dataset-container ${category.id === selectedDataCategory
                                    ? "box-shadow-hover hub-value-individual-dataset-container-selected"
                                    : relatedDataCategories.includes(category.id)
                                        ? "box-shadow-related"
                                        : "box-shadow-standard"
                                    }`}
                                style={{ backgroundImage: `url(${imageURL})` }}
                                onClick={() => {
                                    if (category.id === selectedDataCategory) {
                                        setSelectedDataCategory(null);
                                        // setRelatedDataCategories([]);
                                    } else {
                                        setSelectedDataCategory(category.id);
                                        // setRelatedDataCategories(category.connected_to);
                                    }
                                }}
                            >
                                <div className={`hub-value-individual-dataset-container-overlay ${(selectedDataCategory && category.id !== selectedDataCategory ? "hub-value-individual-dataset-container-overlay-not-selected" : null)}`}>

                                    <span className={`hub-value-individual-dataset-container-overlay-text ${(selectedDataCategory && category.id !== selectedDataCategory ? "not-selected-text" : null)}`}>
                                        {category.name}
                                    </span>
                                </div>
                            </div >
                        )
                    })}
                </div>
            </div>
            <div className="hub-value-reminder-text-container">
                {!selectedDataCategory &&
                    <span className={"hub-value-reminder-text"}>Select a category to view key datapoints</span>
                }
            </div>
            {selectedDataCategoryInfo &&
                <>
                    <div className="hub-value-category-context-container">
                        <p className="hub-value-category-context-text">
                            {selectedDataCategoryInfo.context_sentence}
                        </p>
                    </div>
                    <div className="hub-value-category-stats-grid">
                        {selectedDataCategoryInfo.stats.map(stat =>
                            <FinancialDatapoint key={stat.headline} datapoint={stat} />
                        )}
                    </div>
                </>
            }
            <div className="page-content-horizontal-divider-full"></div>
            <div className={"affordability-hub-risk-container"}>
                <h3 className={"hub-financial-subsection-header"}>
                    {assessment_title}
                </h3>
                <p className={'affordability-hub-title-paragraph'}>
                    {ltv_takeaway_sentence}
                </p>
                {ltvData &&
                    <GradientBar tipping_point={ltvData.ltv_tipping_points} text={ltvData.ltv_score} />
                }
                <div className="assumed-income-container">

                    <span className={"affordability-hub-risk-label-context-text"}>Est. Time Till Risk Will Impact Value:</span>
                    {ltvData &&
                        <span className={"assumed-income-text"}>{ltvData.ltv_years}</span>
                    }

                </div>
                <p className={'affordability-hub-title-paragraph'}>
                    {ltv_takeaway_conclusion}
                </p>
            </div>


        </div>
    );

};
