import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const StartButton = ({
  text,
  shadow_flag = false,
  auth_process_flag = false,
  what_to_do_flag = false,
  handle_what_to_do,
  mini_flag = false,
}) => {
  return (
    <div
      onClick={what_to_do_flag ? () => handle_what_to_do(null) : undefined}
      className={`start-button-frame ${what_to_do_flag && "start-button-frame-what-to-do"} ${shadow_flag && "box-shadow-standard"} start-button-frame-extra-padding-${auth_process_flag} start-button-frame-guidance-navigation-${mini_flag}`}
    >
      {mini_flag &&
        <span className={`span-xs start-button-text`}> {text} </span>
      }
      {!mini_flag &&
        <h4 className={`start-button-text`}> {text} </h4>
      }
    </div>
  );
};

StartButton.propTypes = {
  text: PropTypes.string,
};
