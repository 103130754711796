import React, { useContext } from "react";
import { useReducer } from "react";
import { windowSize } from "../../App";
import "./style.css";

export const ToggleButton = ({

  // text = "Financial Impact",
  property1,
  text,
  onClick,
  selection,
  type

}) => {

  const deviceType = useContext(windowSize)

  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "Default",
  });

  return (
      <div
        className={`hub-section-toggle-button-default ${type === selection ? 'hub-section-toggle-button-user-selected' : 'hub-section-toggle-button-user-not-selected'}`}
        onMouseLeave={() => { dispatch("mouse-leave"); }}
        onMouseEnter={() => { dispatch("mouse-enter"); }}
        onClick={() => { onClick(state.property1); }}
      >
        <span className={`${deviceType === "desktop" ? "span-m" : "span-s"} hub-section-toggle-button-text ${type === selection && 'hub-section-toggle-button-selected-text'}`}>
          {text}
        </span>
      </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse-enter":
      return {
        ...state,
        property1: "Variant3",
      };

    case "mouse-leave":
      return {
        ...state,
        property1: "Variant2",
      };
  }

  return state;
}
