import { CreateCloudfrontUrl } from "./CreateCloudfrontUrl";

export function GetGuidanceText() {




    const data = {
        buyer: {
            guidance_type: "buyer",
            icon_image: "house-for-sale.png",
            icon_text: "I'm Considering Buying This Home",
            guidance_description: "Our goal is to ensure that whatever home you decide to purchase is both a good investment as well as affordable today and into the future. With that in mind, these next steps are designed to help make your decision as informed, and data-drive as possible.",
            checklist: [
                {
                    headline: "Have you updated all of the data possible for this home?",
                    category: "update_data",
                    body_header: "Updated the Data Where Possible",
                    text: "Using the latest data is critical to ensuring we give you the best intelligence possible!",
                    cta: "play_with_data_button"
                },
                {
                    headline: "Is there any data missing that you need help collecting?",
                    category: "missing_data",
                    body_header: "Get Help Filling in the Gaps",
                    text: "As a buyer it can be really hard to know all of the details. Just enter the email for the home's seller and/or your real estate agent and we'll ask them to update the house details. We’ll alert you when they’ve made any updates.",
                    cta: "form_data_collection"
                },
                {
                    headline: "Have you determined which projects you want to focus on?",
                    category: "email_contactor",
                    body_header: "Pick the Right Projects",
                    text: "The right projects can make this home more resilient and lower your future bills. Having a list of these projects often become negotiation points with the owner. \n\n Save the projects you want to focus on and run them by your preferred contractor.",
                    cta: "form_email_contactor"
                },
                {
                    headline: "Have you compared this home against others you are considering?",
                    category: "compare_homes",
                    body_header: "Use Quoll to Compare the Homes",
                    text: "Every home analysis we produce is unique so don't assume that even homes nearby have the same risk profile or future outlook! \n\n Make a list of all the homes you’re considering and be sure to save each one to your dashboard so you can go back and compare their Quoll scores, risks and add them to your project list.",
                    cta: "compare_homes_gif"
                },
                {
                    headline: "Have you confirmed this home's insurance rate / insurability?",
                    category: "check_insurability",
                    body_header: "Confirm Area Insurability",
                    text: "In some areas at risk of wildfires, flooding and hurricanes, it can be hard to find an insurance provider who will fully cover you. Some, but not all states, offer FAIR plans as insurance of last resort, so you can’t assume it’s easy to find insurance that meets your needs or the requirements of your lender.",
                    cta: null,
                },
                {
                    headline: "Have you confirmed your insurance rate?",
                    category: "confirm_insurance_rate",
                    body_header: "Ensure You're Shopping for Best Rates",
                    text: "Completing certain projects can actually lower your base insurance rate and make you eligible for additional discounts on your insurance policy. Sometimes it can even come down to the materials used for things like the roof. \n\n Still have questions? Schedule time to chat with us!",
                    cta: "ask_quoll",
                    cta_text: "Talk to Quoll About Insurance"
                },
                {
                    headline: "Have you set a maintenance budget?",
                    category: "create_budget",
                    body_header: "Plan for Maintenance and Repairs",
                    text: "Completing certain projects can actually lower your base insurance rate and make you eligible for additional discounts on your insurance policy. Sometimes it can even come down to the materials used for things like the roof. \n\n Still have questions? Schedule time to chat with us, and we can guide you!",
                    cta: "ask_quoll",
                    cta_text: "Get Budgeting Help"
                },
            ]
        },
        owner: {
            guidance_type: "owner",
            icon_image: "family-at-home.png",
            icon_text: "I Own This Home",
            guidance_description: "There are a number of ways for you to save money and increase your home's value. The question is what order to tackle this in and who needs to be involved. With that in mind, we're going to focus on the pragmatics that go into getting started. The key will be ensuring we help you focus on what matters and look under all the stones where there could be available dollars to help pay for any improvements.",
            checklist: [
                {
                    headline: "Have you checked that your home’s information is complete and up to date?",
                    category: "update_data",
                    body_header: "Make Sure this Home's Data is Current",
                    text: "Ensuring that our analysis is utilizing the latest data is critical to ensuring we give you the best intelligence possible! Take a few minutes to look at the assumptions and update anything that might have changed since you bought your house.",
                    cta: "play_with_data_button"
                },
                {
                    headline: "Have you made a list of the projects that you want to tackle first?",
                    category: "missing_data",
                    body_header: "Pick the Right Projects",
                    text: "The right projects can make this home more resilient and lower your insurance premium and maintenance costs and help you manage your monthly bills, like the one from the utility. \n\n Pick a direction you want to take, like lowering bills, or your home’s resilience, then save the projects you want to complete and run them by your preferred contractor to get an idea of the cost and complexity based on your unique situation.",
                    cta: "form_email_contactor"
                },
                {
                    headline: "Have you spent time looking at the how-to guides and articles related to your home?",
                    category: "review_resources",
                    body_header: "Get the Information You Need",
                    text: "It can be scary facing the unknown of projects, especially if you’re thinking about doing it yourself. Taking a few minutes to look at the how-to guides, maintenance plans and articles will give you a better idea of how big the project is and whether you want to tackle it yourself or hire a contractor. \n\n Relevant resources are sprinkled around your home analysis but to browse the hundreds of resources we have, head to our resources page.",
                    cta: "review_resources",
                    cta_text: "Go to Quoll Resources"
                },
                {
                    headline: "Have you reviewed all of the discounts you’re eligible for?",
                    category: "compile_discounts",
                    body_header: "Get Rewarded",
                    text: "Projects definitely take time, and usually mean spending some money. Luckily, depending on the project, you could save money on materials, lower your insurance costs and receive discounts on certain upgrades to your home!",
                    cta: "ask_quoll",
                    cta_text: "Review Discounts With Quoll"
                },
                {
                    headline: "Have you put together an action plan?",
                    category: "make_action_plan",
                    body_header: "Create Your Action Plan",
                    text: "Getting started on a project is just the beginning, but it can easily be the end if you don’t have a plan that aligns with your priorities, budget and goals. It’s important to think about the outcomes you want and plan a timeline and budget that will reasonably get you there. \n\n Not sure where to start? We can help!",
                    cta: "ask_quoll",
                    cta_text: "Get Help Creating Action Plan",
                },
                {
                    headline: "Have you gotten credit for the improvements you've already made?",
                    category: "get_credit",
                    body_header: "Ensure You're Not Leaving Money on the Table",
                    text: "Completing certain projects can actually lower your base insurance rate and make you eligible for additional discounts on your insurance policy. Sometimes it can even come down to the materials used for things like the roof. \n\n Mark completed projects for this home and take note of rewards you can get for those improvements! \n\n Want help with that process? Schedule time to chat with us!",
                    cta: "ask_quoll",
                    cta_text: "Review Discounts You May Be Eligable For"
                },
            ]
        }
    }

    return data
}