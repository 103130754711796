import React, { useState, useEffect } from "react";
import { getRisksAndHousePartsFromProjects } from "../../services/GetRisksAndHousePartsFromProjects";
import ThunderstormSharpIcon from '@mui/icons-material/ThunderstormSharp';
import HouseSharpIcon from '@mui/icons-material/HouseSharp';
import "./style.css";

export const HubProjectsFilterBar = ({
  filtered_projects,
  riskFilter,
  setRiskFilter,
  homeAreaFilter,
  setHomeAreaFilter,

}) => {

  const filter_options = getRisksAndHousePartsFromProjects(filtered_projects);
  // console.log(filter_options);

  const handleRiskSelect = (e) => {
    setRiskFilter(e.target.value);
  };

  const handleRiskClear = (e) => {
    setRiskFilter("");
  };

  const handleHomeAreaSelect = (e) => {
    setHomeAreaFilter(e.target.value);
  };

  const handleHomeAreaClear = (e) => {
    setHomeAreaFilter("");
  };

  // console.log(deviceType)


  return (
    <>
      <div className={'hub-projects-filter-bar-frame'}>
        {/* <div className="hub-projects-display-toggle-and-showing-text">

          {deviceType !== "mobile" &&
            <div className={'hub-projects-filters-wrapper'}>
              <div
                onClick={() => {
                  setProjectView('list')
                }}
                className={'hub-projects-filter-button'}
              >
                <ReorderSharpIcon />
              </div>
              <div
                onClick={() => {
                  setProjectView('card')
                }}
                className={"hub-projects-filter-button"}
              >
                <DashboardSharpIcon />
              </div>
            </div>
          }

          <p className={`hub-projects-filter-text ${deviceType === "mobile" ? "hub-projects-filter-text-mobile" : ""}`}>
            Showing ({filtered_projects.length}) Projects
          </p>
        </div> */}
        <div className="hub-projects-filter-menus-container">
          <span className={"span-l hub-projects-filter-text"}> Filter By: </span>

          <div className="hub-project-filter-item">
            <ThunderstormSharpIcon className={"hub-project-filter-icon"} />
            <span className={"span-m hub-project-filter-item-text"}>Climate Risk</span>
            <select value={riskFilter} onChange={handleRiskSelect}>

              <option disabled selected value="">
                Select
              </option>

              {filter_options.risks.map(risk => {
                return (
                  <option value={risk}>
                    {risk}
                  </option>
                )
              })}

            </select>

            {riskFilter ? <span className={"span-m resource-filter-item-clear"} onClick={handleRiskClear}> CLEAR </span> : null}
          </div>
          <div className="hub-project-filter-item">
            <HouseSharpIcon className={"hub-project-filter-icon"} />
            <span className={"span-m hub-project-filter-item-text"}>Part of Home</span>
            <select value={homeAreaFilter} onChange={handleHomeAreaSelect}>

              <option disabled selected value="">

                Select

              </option>

              {filter_options.house_areas.map(area => {
                return (
                  <option value={area}>{area}</option>
                )
              })}

            </select>

            {homeAreaFilter ? <span className={"span-m resource-filter-item-clear"} onClick={handleHomeAreaClear}> CLEAR </span> : null}
          </div>

        </div>

      </div>
    </>
  );

};
