import React, { useEffect, useRef, useContext, useState } from "react";
import { UserExperienceNavigator } from "../../screens/TabletDesktop/TabletDesktop";
import { windowSize } from "../../App";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import { RecordEvent } from "../../services/HandleGoogleAnalyticsEvent";
import { RatingBox } from "../../components/RatingBox";
import { StaticText } from "../../services/StaticText";
import "./style.css";

export const NowWhatFirstView = () => {

  // Grab the analysis
  const analysis = useContext(AnalysisDetails)

  // Get the Quoll Score
  const quoll_score = (analysis != null) ? analysis.quoll_score : null;

  // Get the static text options for the different letters
  const static_text = StaticText()
  const popup_text = static_text.hub.popup_score_context
  const letter_based_text = popup_text.letter_sentences
  const cta = popup_text.cta

  // Get the address
  const address = analysis.address_details.full_address
  // const address = analysis.address_details.full_address.split(",")[0]

  let context_text

  switch (quoll_score) {
    case 'A':
      context_text = letter_based_text.A;
      break;
    case 'B':
      context_text = letter_based_text.B;
      break;
    case 'C':
      context_text = letter_based_text.C;
      break;
    case 'D':
      context_text = letter_based_text.D;
      break;
    case 'F':
      context_text = letter_based_text.F;
      break;
    default:
      context_text = letter_based_text.C; // default color if quoll_score doesn't match any case
  }


  return (
    <>
      <span className={"span-xl now-what-header now-what-header-headline-text"}>{address}</span>
      <div className="now-what-score-container">
        <RatingBox initialPopupFlag={true} />
        <div className="now-what-score-container-text-container">
          <span className={"span-l text-wrap-balance now-what-header now-what-score-container-text"}>Quoll Score </span>
          <sup className={"span-xxs text-wrap-balance now-what-header now-what-score-container-text"}>TM</sup>
        </div>
      </div>
      <span className={"span-m text-wrap-balance now-what-score-description-text"}>{context_text}</span>
      <span className={"span-m text-wrap-balance now-what-score-description-text"}>{cta}</span>
    </>
  );
};
