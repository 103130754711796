import { AnalysisDetails } from "../screens/TabletDesktop/TabletDesktop";
import react, { useContext } from 'react';

export function CreateCloudfrontUrl(filename) {
  
    const cloudfront_prefix = 'https://d2dhzsfgxco53w.cloudfront.net/'

    const result = cloudfront_prefix + filename

  return result;
}
