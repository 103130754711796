import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { formatDollarAmount } from "../../services/FormatDollarAmounts";

import "./style.css";

export const DiscountCard = ({
  discount,

}) => {

  // console.log(discount)
  const [active, setActive] = useState(false);

  const discount_type = discount.discount_value_type

  const general_discount_sentence = "Availble Incentives"
  const discount_percentage_value = discount.est_discount_percentage ? (discount.est_discount_percentage * 100).toFixed(1) : 0
  const percentage_discount_sentence = `Up to ${discount_percentage_value}% reduction in your premiums`
  const dollars_sentence = `Up to ${formatDollarAmount(discount.est_incentive_dollars)} in incentives`

  const img_url = CreateCloudfrontUrl(discount.icon)

  const [discountSentence, setDiscountSentence] = useState(general_discount_sentence)

  useEffect(() => {

    if (discount_type === "est_discount_percentage") {
      setDiscountSentence(percentage_discount_sentence)
    } else if (discount_type === "est_incentive_dollars") {
      setDiscountSentence(dollars_sentence)
    }
  }, []);

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
  };


  return (

    // <a className={"discount-link"} href={discount.company_url}>
    <Link to={discount.company_url} style={{textDecoration: 'none'}} target="_blank">
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`discount-card-frame ${active ? "box-shadow-hover" : "box-shadow-standard"}`}
      >
        <div className={"discount-card-image-container"}>
          <img className={"discount-card-image"} src={img_url} />
        </div>
        <span className={"span-m discount-card-text-description"}>{discountSentence}</span>
        <span className={"span-s discount-card-text-link"}>See Offer</span>

      </div>
    {/* </a> */}
    </Link>

  );

};
