import React, { useState } from "react";
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import "./style.css";

export const FAQAccordian = ({ faq, index }) => {

  // Have the first element be open and the rest closed on load
  const initial_toggle_status = (index === 0) ? true : false;
  
  const [toggleStatus, setToggleStatus] = useState(initial_toggle_status);

  // Set up the handler for toggling
  const handleToggleClick = (event) => {
    setToggleStatus(toggleStatus => !toggleStatus);
  };

  return (
    <div className="faq-toggle-accordian-frame">
      <div className="faq-toggle-accordian-header-container" onClick={handleToggleClick}>
        <h3 className={"faq-accordian-header-text"}>{faq.category_name}</h3>
        {toggleStatus ? <ExpandLessSharpIcon className={"faq-accordian-header-icon"} /> : <ExpandMoreSharpIcon className={"faq-accordian-header-icon"} />}
      </div>
      {toggleStatus &&
        <div className="faq-accordian-questions-container">

          {faq.faqs.map(item => {
            return (
              <>
                <h4 key={item.question}>{item.question}</h4>
                <p key={item.answer}>{item.answer}</p>
              </>
            )
          })

          }
        </div>
      }
    </div>
  );
};
