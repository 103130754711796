import React from "react";
import { RiskLabel } from "../RiskLabel";
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import "./style.css";

export const GradientBar = ({ tipping_point, text, tipping_point_flag }) => {

  let gradient_padding;
  // Get the gradient padding amount
  if (tipping_point > 10 ) {
    gradient_padding = `${(25.1 - tipping_point) / 15 *.33 * 100}%`;
  } else if (tipping_point >= 5) {
    gradient_padding = `${((10.1 - tipping_point) / 5 *.33 + .33) * 100}%`;
  } else {
    gradient_padding = `${((5.1 - tipping_point) / 5 *.33 + .66) * 100}%`;
  }

  return (
    <div className={"gradient-bar-container"}>
      <div className={"affordability-gradient-bar"}></div>
      <div className="affordability-hub-risk-label-container" style={{ paddingLeft: gradient_padding }}>
        <ArrowDropUpSharpIcon style={{ fontSize: "3.5rem" }} />
        <RiskLabel text={text} />
      </div>
    </div>
  );
};
