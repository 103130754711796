import axios from "axios";

export function triggerSignup(request_param) {
    // return axios.post('https://1nl7eg7oui.execute-api.us-east-2.amazonaws.com/test/sign-up', request_param)
    return axios.post('/api/sign-up', request_param)
      .then(response => response.data)
    //   .then(response => response.data.address_report.output)
      .catch(error => {
        console.error(error);
        throw error; // throw the error again after catching and logging it
      });
  }