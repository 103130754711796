import React from "react";
import "./style.css";

export const MenuItem = ({ 
  
  text

}) => {
  return (
    <div className={"header-menu-item-container"}>
      <span className={"span-l header-menu-item"}>
        {text}
      </span>
    </div>
  );
};
