import React, { useContext } from "react";
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import "./style.css";

export const RatingBox = ({ initialPopupFlag = false }) => {

  const analysis = useContext(AnalysisDetails);
  const quoll_score = (analysis != null) ? analysis.quoll_score : null;

  let background_color;

  switch (quoll_score) {
    case 'A':
      background_color = 'green';
      risk_level = 'low';
      break;
    case 'B':
    case 'C':
      background_color = '#ffdb33';
      risk_level = 'medium';
      break;
    case 'D':
    case 'F':
      background_color = '#e73b15';
      risk_level = 'high';
      break;
    default:
      background_color = 'grey'; // default color if quoll_score doesn't match any case
  }

  style = {
    backgroundColor: background_color,
  }

  console.log(initialPopupFlag)

  return (
    // <div className={"quoll-score-container"} style={style}>
    <div className={`quoll-score-container ${initialPopupFlag && "quoll-score-container-popup"} background-color-${risk_level}-risk`}>
      {!initialPopupFlag &&
        <span className={"span-s quoll-sore-headline"}>Quoll Score</span>
      }
      {analysis != null &&
        <span className={"span-xxl quoll-score-value"}>
          {quoll_score}
        </span>
      }
    </div>
  );
};
