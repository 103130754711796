import React from "react";
// import ReactDOMClient from "react-dom/client";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import ErrorBoundary from "./services/ErrorBoundary";
import { ScrollToTop } from "./services/ScrollToTop";
import './index.css';
import ReactGA from "react-ga4";
import Hotjar from '@hotjar/browser'

const app = document.getElementById("app");
const root = ReactDOM.createRoot(app);
// const root = ReactDOMClient.createRoot(app);

const TRACKING_ID = "G-T37X7481WM"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID, { debug: true });

const siteId = 3065093
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

root.render(
    // <React.StrictMode>
            <App />
    // </React.StrictMode>
);
