import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { ProjectCardResources } from "../../modules/ProjectCardResources/ProjectCardResources";
import { fetchContent } from "../../services/GetContent";
import Skeleton from '@mui/material/Skeleton';
import { LoadingGif } from "../../components/LoadingGif";
import { ContentFooter } from "../../components/ContentFooter";
import { UserStatus } from "../../services/UserMgt";
import "./style.css";

// During testing
import data from './sample_blog.json'

export const Blog = () => {

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Navigator if error
  const navigate = useNavigate();

  // Get the slug from the URL
  const slug = useParams().slug

  const [newTitle, setNewTitle] = useState(null)

  useEffect(() => {

    if (newTitle) {

      document.title = newTitle;
      setPageTitle(newTitle);
    }
    // This will run when the page first loads and whenever the title changes
  }, [newTitle]);

  // Set up the blog and blog status states
  const [blog, setBlog] = useState(null)
  const [blogStatus, setBlogStatus] = useState(false)

  // console.log(blog)

  // Call the Quoll API to get the content
  useEffect(() => {

    // LIVE API CALL
    const request_param = { slug: slug };

    fetchContent(request_param)
      .then(output => {
        // console.log(output)
        if (output.status === 'success') {
          setBlog(output)
          setNewTitle(output.article.title)
          setBlogStatus(true)
        }
        else {
          navigate('/', { replace: true });
        }
        // setAnalysis(output);
        // setAnalysisStatus(true);
      })
      .catch(error => {
        console.log(error);
        navigate('/', { replace: true });
      });
  }, [slug]);


  const content = (blog !== null) ? blog.article : null
  const related_content = (blog !== null) ? blog.related_resources : []
  // console.log(related_content)

  const blog_image = (content !== null) ? CreateCloudfrontUrl(content.image) : null;
  const blog_content = (content !== null) ? content.body : null;
  const blog_tags = (content !== null) ? content.associated_house_categories.concat(content.associated_climate_risks) : [];
  // console.log(blog_tags)

  const ITALIC = ({ children }) => <p className={"italic"}>{children}</p>;

  const RICHTEXT_OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className={"blog-paragraph"}>{children}</p>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className={"blog-h2"}>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 className={"blog-h3"}>{children}</h3>
      },
      // [BLOCKS.UL_LIST]: (node, children) => {
      //   return <li className={"blog-li"}>{children}</li>
      // },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className={"blog-ol"}>{children}</ul>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <img className={"blog-inline-image"} src={node.data} />
      },
    },
    renderMark: {

      [MARKS.BOLD]: text => <b>{text}</b>,
      [MARKS.ITALIC]: text => <i className={"italic"}>{text}</i>
    }
  }

  // Used for testing that the loading gif is showing correctly
  // const tester = true;

  return (
    <div className={'page-frame'}>

      {(!blog) && <LoadingGif />}
      <div className="blog-header"
        style={{
          backgroundImage: blog ? `url(${blog_image})` : <Skeleton variant="rectangular" />,
          backgroundSize: 'cover',
        }}
      >
        <div className="blog-header-overlay" />
        <div className={"blog-header-text"}>

          <h2 className={"blog-header-category"}>{blog && content.category}</h2>
          <h1 className={"blog-header-headline"}>{blog && content.title}</h1>
          <h2 className={"blog-header-author"}>{blog && "by " + content.author}</h2>
          <div className="blog-tags">
            {blog_tags.map(tag => {
              return (
                <span className={"blog-tags"}> #{tag} </span>
              )
            })}
          </div>
        </div>

      </div>
      <div className="page-container-static">

        <div className="blog-frame">

          {documentToReactComponents(blog_content, RICHTEXT_OPTIONS)}
          <div className="blog-related-resources">

            <h2>Related Resources</h2>
            {related_content ? <ProjectCardResources resources={related_content} style="dark" /> : <Skeleton variant="rectangular" />}
          </div>
        </div>
        <ContentFooter />
      </div>
    </div>
  );
};
