import axios from 'axios';

export const GetUserSavedItems = async (userId) => {

    console.log("User ID: ", userId);
    const request_param = {
        user_id: userId,
    };

    try {
        // const response = await axios.post('https://1nl7eg7oui.execute-api.us-east-2.amazonaws.com/test/user-saved-items', request_param);
        const response = await axios.post('/api/user-saved-items', request_param);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;  // handle error appropriately
    }
};