function CountCompanyDiscounts(discountTypes) {
    let totalCount = 0; // Initialize the count variable
  
    discountTypes.forEach((discountType) => {
      const associatedIdsCount = discountType.associated_entity_discounts.length;
      totalCount += associatedIdsCount; // Add the count to the total count
    });
  
    return totalCount;
  }
  
  export default CountCompanyDiscounts;
  