import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserStatus } from "../../services/UserMgt";
import { DashboardHomeCard } from "../../components/DashboardHomeCard";
import { windowSize } from "../../App";
import Cookies from 'js-cookie';
import { saveHouseProjectResource } from "../../services/SaveHouseProjectResource";
import { ProjectCardResources } from "../../modules/ProjectCardResources/ProjectCardResources";
import { CreateCloudfrontUrl } from "../../services/CreateCloudfrontUrl";
import { ClearUserDataCache } from "../../services/ClearUserDataCache";
// import { HandleUserSignout } from "../../services/HandleUserSignout";
import "./style.css";


export const Dashboard = () => {

  // Get the setPageTitle property from userStatus
  const { setPageTitle } = useContext(UserStatus);

  // Set the page title
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Dashboard";
    setPageTitle("Dashboard");
  }, []);

  // Get the device type
  const deviceType = useContext(windowSize)

  // Set up navigate for if user is not signed in and tries to save project
  const navigate = useNavigate();

  // Get the user saved details
  const { setCacheCheckStatus, setUserId, userSavedHomes, setUserSavedHomes, userSavedResources, setUserSavedResources } = useContext(UserStatus);

  // Handle Sign Out

  const handleSignOut = () => {
    ClearUserDataCache();
    setCacheCheckStatus(false)
    setUserId(null)
    setUserSavedHomes([])
    setUserSavedResources([])
    navigate("/")
    Cookies.remove('q_uid', { path: '/' });
  }

  // Dashboard image
  const dashboard_image_url = CreateCloudfrontUrl("general-hp.jpg")

  const booking_url = "https://calendly.com/d/4gc-k42-dhh/15-minute-resiliency-strategy-session-with-quoll"

  return (
    <div className={'page-frame'}>

      <div className="page-container">
        <div className="dashboard-container">
          <div className="dashboard-buttons-container">
            <a href={booking_url} target="_blank">
              <div className="dashboard-signout-button-container dashboard-signout-button-container-book-time box-shadow-standard">
                <span className={"span-m dashboard-signout-button-text"}>Book Time With a Quoll Expert!</span>
              </div>
            </a>
            <div className="dashboard-signout-button-container dashboard-signout-button-container-signout box-shadow-standard" onClick={handleSignOut}>
              <span className={"span-m dashboard-signout-button-text"}>Sign Out</span>
            </div>
          </div>

          <div className="dashboard-header">

            <img className="dashboard-hero-image" alt="Image" src={dashboard_image_url} />
            <h1 className={"dashboard-title"}>My Dashboard</h1>
          </div>
          <div className="dashboard-my-saved-homes-container">
            <h3> Saved Homes </h3>
            <div className={`dashboard-my-saved-homes-grid dashboard-my-saved-homes-grid-${deviceType}`}>
              {userSavedHomes.length === 0 && <p>Any homes you save will be added to your dashboard here!</p>}
              {userSavedHomes.map(home => {
                return (
                  <DashboardHomeCard key={home.address_id} home={home} />
                )
              })}
            </div>
          </div>
          <div className="dashboard-my-saved-homes-container">
            <h3> Saved Resources </h3>
            <ProjectCardResources resources={userSavedResources} style="dark" />
          </div>
        </div>
      </div>


    </div>
  );
};
