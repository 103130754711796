import React, {useEffect} from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import ReactGA from 'react-ga4';


// export const RecordPageView = (path, title) => {
export function RecordPageView(path, title) {
    const test = "test";
    // const event_type = input.eventType
    ReactGA.send({ hitType: "pageview", page: path, title: title});
    // const location = useLocation();
    // console.log("Added: " + path + " | " + title)

    return null;

};

// export function RecordEvent(path, title) {
//     const test = "test";
//     // const event_type = input.eventType
//     ReactGA.send({ hitType: "pageview", page: path, title: title});
//     // const location = useLocation();
//     console.log("Added: " + path + " | " + title)

//     return null;

// };