export function CombineHouseAreasAndProjects(houseAreas, projects) {
    const houseAreasCopy = [...houseAreas]; // Create a copy to avoid modifying the original array
  
    houseAreasCopy.forEach((area) => {
      const projectCount = projects.filter(
        (project) => project.house_category === area.name
      ).length;
  
      area.totalProjects = projectCount;
    });
  
    return houseAreasCopy;
  }
  