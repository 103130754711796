import React, { useState, useContext } from "react";
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { AnalysisDetails } from "../../screens/TabletDesktop/TabletDesktop";
import "./style.css";

export const FinancialDatapoint = ({ datapoint, type = "general" }) => {

  // Create the hover functionality
  const analysis = useContext(AnalysisDetails)

  let geography;
  let geography_text;

  switch (datapoint.locality) {
    case 'fips':
      geography = analysis.address_details.county;
      geography_text = null;
      break;
    case 'state':
      geography = analysis.address_details.state_long;
      geography_text = null;
      // geography_text = "State";
      break;
    case 'zip':
      geography = analysis.address_details.zip;
      geography_text = "Zip Code";
      break;
    case 'home':
      geography = "This Home";
      geography_text = null;
      break;
    default:
      geography = analysis.address_details.county; // default color if get color doesn't provide text
      geography_text = null;
  }

  console.log(datapoint)

  // Create the hover functionality
  const [hover, setHover] = useState(null)

  const addHover = () => {
    setHover(true);
  };
  const removeHover = () => {
    setHover(null);
  };

  const datapoint_styling = "statistic-datapoint-" + datapoint.score

  return (
    <div id={datapoint.headline} className={`hub-value-category-stat-container ${type === "general" ? "hub-value-category-stat-container-general" : "hub-value-category-stat-container-extra-spacing"}`}>
      {type === "general" &&
        <div className="what-and-why-to-care">
          <span className={'span-xs statistic-geography-text'}>{datapoint.locality === "zip" && geography_text} {geography}</span>
          {hover &&
            <div className="financial-datapoint-hover-learn-more-container box-shadow-light">
              <span className={"span-m financial-learn-more-header"}>What is This?</span>
              <span className={"span-s financial-learn-more-text"}>{datapoint.what_is_this}</span>
              <span className={"span-m financial-learn-more-header"}>Why You Should Care</span>
              <span className={"span-s financial-learn-more-text"}>{datapoint.why_to_care}</span>
            </div>
          }
          <InfoSharpIcon
            className={'datapoint-hover-i'}
            onMouseEnter={() => addHover()}
            onMouseLeave={() => removeHover()}
          />
        </div>
      }
      <div className="statistic-datapoint-type-text-container">
        <span className={"span-m statistic-header"}>{datapoint.headline}</span>
        {type !== "general" &&
          <div className="what-and-why-to-care what-and-why-to-care-climate">
            {hover &&
              <div className="financial-datapoint-hover-learn-more-container box-shadow-light">
                <span className={"span-m financial-learn-more-header"}>What is This?</span>
                <span className={"span-s financial-learn-more-text"}>{datapoint.what_is_this}</span>
                <span className={"span-m financial-learn-more-header"}>Why You Should Care</span>
                <span className={"span-s financial-learn-more-text"}>{datapoint.why_to_care}</span>
              </div>
            }
            <InfoSharpIcon
              className={'datapoint-hover-i'}
              onMouseEnter={() => addHover()}
              onMouseLeave={() => removeHover()}
            />
          </div>
        }
      </div>
      <span className={`span-xl statistic-datapoint ${type === 'general' ? datapoint_styling : null}`}>{datapoint.stat}</span>
    </div>
  );
};
