import React from "react";
import { RiskLabel } from "../RiskLabel";
import "./style.css";

export const ModalClimateHouseTitleSection = ({

  name,
  image,
  icon,
  value,
  type

}) => {
  return (
    <>
      <div className="modal-project-header-bar">

        <div className={"modal-project-breadcrumb-bar"}>
          <span className={"span-s modal-project-breadcrumb-item"}>
            {name + " "} 
          </span>
          <span className={"span-s modal-project-breadcrumb-item"}>
            &gt; {type}
          </span>
        </div>
      </div>
      <div className="modal-climate-house-image-container">
        <img className={"modal-climate-house-image box-shadow-standard"} src={image} />
      </div>
      <div className="modal-climate-title-bar">
        {/* <img className={"modal-climate-house-icon"} src={icon} /> */}
        <span className={"span-xl modal-title"}>
          {name}
        </span>
        <RiskLabel text={value} />
      </div>
    </>
  );
};
